body {
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--button-background-color);
  }
}

.scroll {
  overflow-y: overlay;
  overflow-x: hidden;
  height: 100%;
  max-height: calc(100vh - 305px);
}

@media only screen and (max-width: 1280px) {
  .scroll {
    max-height: calc(100vh - 340px);
  }
}

@media only screen and (max-width: 767.5px) {
  .scroll {
    max-height: calc(100vh - 340px);
  }
}
