@font-face {
  font-family: 'Indigo Blue';
  src: url('/assets/fonts/IndigoBlue.eot');
  src:
    url('/assets/fonts/IndigoBlue.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/IndigoBlue.woff2') format('woff2'),
    url('/assets/fonts/IndigoBlue.woff') format('woff'),
    url('/assets/fonts/IndigoBlue.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Book';
  src: url('/assets/fonts/Gotham-Book.eot');
  src:
    url('/assets/fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Gotham-Book.woff2') format('woff2'),
    url('/assets/fonts/Gotham-Book.woff') format('woff'),
    url('/assets/fonts/Gotham-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Light';
  src: url('/assets/fonts/Gotham-Light.eot');
  src:
    url('/assets/fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Gotham-Light.woff2') format('woff2'),
    url('/assets/fonts/Gotham-Light.woff') format('woff'),
    url('/assets/fonts/Gotham-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Neutraface Text Book';
  src: url('/assets/fonts/NeutrafaceText-Book.eot');
  src:
    url('/assets/fonts/NeutrafaceText-Book.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/NeutrafaceText-Book.woff2') format('woff2'),
    url('/assets/fonts/NeutrafaceText-Book.woff') format('woff'),
    url('/assets/fonts/NeutrafaceText-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'EB Garamond';
  src: url('/assets/fonts/EBGaramond-Regular.eot');
  src:
    url('/assets/fonts/EBGaramond-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/EBGaramond-Regular.woff2') format('woff2'),
    url('/assets/fonts/EBGaramond-Regular.woff') format('woff'),
    url('/assets/fonts/EBGaramond-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Neutraface Text';
  src: url('/assets/fonts/NeutrafaceText-Light.eot');
  src:
    url('/assets/fonts/NeutrafaceText-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/NeutrafaceText-Light.woff2') format('woff2'),
    url('/assets/fonts/NeutrafaceText-Light.woff') format('woff'),
    url('/assets/fonts/NeutrafaceText-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PresicavLt';
  src: url('/assets/fonts/PresicavLt-Regular.eot');
  src:
    url('/assets/fonts/PresicavLt-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/PresicavLt-Regular.woff2') format('woff2'),
    url('/assets/fonts/PresicavLt-Regular.woff') format('woff'),
    url('/assets/fonts/PresicavLt-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Century Gothic';
  font-style: normal;
  font-weight: 400;
  src:
    local('Century Gothic'),
    url('/assets/fonts/Century-Gothic.woff') format('woff');
}
