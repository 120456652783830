.zoominout {
  transform: scale(1);
  animation: zoominoutanimation 1.2s;
}

@keyframes zoominoutanimation {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
