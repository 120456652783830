ngb-toast {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1200;
  width: 100%;
  min-width: 300px;
}

.toast {
  background-color: var(--white);

  .toast-header {
    .btn-close {
      display: none;
    }
  }

  .toast-body {
    background-color: var(--white);
  }

  .close {
    position: absolute;
    top: 2px;
    right: 10px;
  }
  .toast-content {
    width: 100%;
    min-width: 300px;
  }
}
