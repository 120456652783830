@import '../../mixin';

.px-4 {
  padding-inline: 60px !important;

  @include max(1280.5px) {
    padding-inline: 30px !important;
  }

  @include max(575.5px) {
    padding-inline: 15px !important;
  }
}
.py-3 {
  padding-block: 30px !important;
}
