@import '../../mixin';

app-table.table,
table {
  width: 100%;
  margin-block-end: 0px;
  margin-bottom: 0;

  thead tr > th.tcol-xl,
  tbody tr > td.tcol-xl {
    width: 1125px;
  }
}

.table-responsive {
  border: 1px solid var(--td-btn-background);
  max-height: 840px;

  .table {
    border-collapse: collapse;

    @include min(1920.5px) {
      width: 100%;
    }

    @include max(1619.5px) {
      width: 1440px;
    }
  }
}
