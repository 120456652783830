a {
  cursor: pointer;
}
.invalid-feedback {
  color: var(--text-copper) !important;
}
.form-control.is-invalid {
  filter: hue-rotate(45deg);
}
.footer-sticky-item {
  position: absolute;
  width: 100vw;
  max-width: calc(100vw - var(--sidebar-width));
  bottom: 40px;
}
@media only screen and (max-width: 1919.5px) {
  .footer-sticky-item {
    max-width: calc(100vw - var(--sidebar-arrow-width));
  }
}
@media only screen and (max-width: 1280.5px) {
  .footer-sticky-item {
    max-width: 100%;
  }
}
.label-20 label {
  font-size: 20px !important;
}
.font-lg{
  font-size: 20px !important;
  line-height: 24px;
  padding: 15px;
  margin: 0;
}

.tooltip-icon {
  color: var(--text-black);
}
.custom-gap-2{
  gap:30px
}
