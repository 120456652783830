ngb-popover-window {
  & .popover-header {
    background-color: var(--input-color-text);
    color: var(--white);
    border: 1px solid var(--input-color-text);
  }
  & .popover-body {
    background-color: var(--black);
    color: var(--white);
  }
}
