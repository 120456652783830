.card {
  background-color: var(--text-secondary) !important;
  border: 1px solid var(--border-color-gray) !important;
  border-radius: 0 !important;

  .card-header {
    padding: 11px 15px 11px;
    border: 1px solid var(--input-border-color);
    border-radius: 0 !important;
  }
}
.card-bg {
  padding-block-start: 13px !important;
  padding-block-end: 14px !important;
  padding-inline: 15px;
}
