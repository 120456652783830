/**
 * A mixin to style the image picker
*/
@mixin picker-style() {
  border: 1px solid currentColor !important;
  background: repeating-conic-gradient(var(--light) 0% 25%, transparent 0% 50%) 50% / 25px 25px !important;
  background-position:
    0 0,
    12.5px 0,
    12.5px -12.5px,
    0px 12.5px; /* Must be half of one side of the square */
}

ngp-image-picker .place-image .image-holder {
  @include picker-style();
}

ngp-image-picker .place-image .image-holder-loaded img {
  @include picker-style();
  padding: 3px;
}
