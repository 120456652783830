@import '../node_modules/bootstrap/scss/mixins';
.brand-logo {
  position: relative;
  display: block;
  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;
  width: auto;
  height: 112px;
  object-fit: contain;

  a,
  img {
    width: auto;
    height: 112px;
    line-height: 112px;
  }

  a {
    padding: 0;
    margin: 0;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: #000;
    }

    &:visited {
      text-decoration: none;
      color: #000;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: -15px;
    z-index: 14;
    -webkit-transition: all 100ms linear;
    -ms-transition: all 100ms linear;
    transition: all 100ms linear;
  }

  .navbar-slim & {
    height: 70px;

    a,
    img {
      height: 70px;
    }
  }
}

.brand-logo.brand-footer {
  a,
  span {
    color: white;

    &:hover {
      color: white;
    }
  }

  @include media-breakpoint-down(lg){
    margin-bottom: 40px;
  }

  @include media-breakpoint-down(sm){
    margin: 0px auto 40px auto;
    left: 25px;
  }
}

.brand-logo.large {
  width: 335px;
  height: 156px;
  margin: 10px auto 60px auto;
  left: 25px;

  img {
    line-height: 156px;
    width: 335px;
    height: 156px;
  }

  @include media-breakpoint-down(sm){
    left: -10px;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
