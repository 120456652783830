@font-face {
  font-family: 'brand-icons';
  src: url('brand-icons.eot?y1s1oo');
  src:
    url('brand-icons.eot?y1s1oo#iefix') format('embedded-opentype'),
    url('brand-icons.ttf?y1s1oo') format('truetype'),
    url('brand-icons.woff?y1s1oo') format('woff'),
    url('brand-icons.svg?y1s1oo#brand-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i.brand-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'brand-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.Icons_Toggle-OFF1 .path1:before {
    content: '\e953';
    color: rgb(0, 0, 0);
  }
  &.Icons_Toggle-OFF1 .path2:before {
    content: '\e954';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-OFF1 .path3:before {
    content: '\e955';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-OFF1 .path4:before {
    content: '\e956';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  &.Icons_Toggle-OFF1 .path5:before {
    content: '\e957';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-OFF1 .path6:before {
    content: '\e958';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-OFF1 .path7:before {
    content: '\e959';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  &.Icons_Toggle-OFF1 .path8:before {
    content: '\e95a';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-OFF1 .path9:before {
    content: '\e95b';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-ON1 .path1:before {
    content: '\e95c';
    color: rgb(0, 0, 0);
  }
  &.Icons_Toggle-ON1 .path2:before {
    content: '\e95d';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-ON1 .path3:before {
    content: '\e95e';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-ON1 .path4:before {
    content: '\e95f';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  &.Icons_Toggle-ON1 .path5:before {
    content: '\e960';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-ON1 .path6:before {
    content: '\e961';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-ON1 .path7:before {
    content: '\e962';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  &.Icons_Toggle-ON1 .path8:before {
    content: '\e963';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-ON1 .path9:before {
    content: '\e964';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Vertical-Arrow1:before {
    content: '\e965';
  }
  &.Icons_Z-to-A1:before {
    content: '\e966';
  }
  &.Icons_A-to-Z1:before {
    content: '\e967';
  }
  &.Icons_Checkbox-Empty1:before {
    content: '\e968';
  }
  &.Icons_Checkbox-Fill1:before {
    content: '\e969';
  }
  &.Icons_Dark-light-Mode1:before {
    content: '\e96a';
  }
  &.Icons_Folder-11 .path1:before {
    content: '\e96b';
    color: rgb(170, 170, 170);
  }
  &.Icons_Folder-11 .path2:before {
    content: '\e96c';
    margin-left: -1.3330078125em;
    color: rgb(153, 153, 153);
  }
  &.Icons_Folder-11 .path3:before {
    content: '\e96d';
    margin-left: -1.3330078125em;
    color: rgb(129, 129, 129);
  }
  &.Icons_Folder-21 .path1:before {
    content: '\e96e';
    color: rgb(255, 255, 255);
  }
  &.Icons_Folder-21 .path2:before {
    content: '\e96f';
    margin-left: -1.3125em;
    color: rgb(0, 0, 0);
  }
  &.Icons_Folder-View1:before {
    content: '\e970';
  }
  &.Icons_Horizontal-Arrow1:before {
    content: '\e971';
  }
  &.Icons_Info-11:before {
    content: '\e972';
  }
  &.Icons_Info-21:before {
    content: '\e973';
  }
  &.Icons_Logged-in1:before {
    content: '\e974';
  }
  &.Icons_LTCI-Specialist1:before {
    content: '\e975';
  }
  &.Icons_OurGoals:before {
    content: '\e94f';
    color: #fff;
  }
  &.Icons_PPT:before {
    content: '\e950';
    color: #fff;
  }
  &.Icons_Timeline:before {
    content: '\e951';
    color: #fff;
  }
  &.Icons_Agreement:before {
    content: '\e952';
    color: #fff;
  }
  &.Icons_LTCI-Specialist:before {
    content: '\e900';
  }
  &.Icons_Dark-light-Mode:before {
    content: '\e901';
  }
  &.Icons_A-to-Z:before {
    content: '\e902';
  }
  &.Icons_Back:before {
    content: '\e903';
  }
  &.Icons_Bell:before {
    content: '\e904';
  }
  &.Icons_Bin:before {
    content: '\e905';
  }
  &.Icons_Checkbox-Empty:before {
    content: '\e906';
  }
  &.Icons_Checkbox-Fill:before {
    content: '\e907';
  }
  &.Icons_Copy:before {
    content: '\e908';
  }
  &.Icons_Download:before {
    content: '\e909';
  }
  &.Icons_Drag:before {
    content: '\e90a';
  }
  &.Icons_Dropdown:before {
    content: '\e90b';
  }
  &.Icons_Envelop:before {
    content: '\e90c';
  }
  &.Icons_Folder-1 .path1:before {
    content: '\e90d';
    color: rgb(170, 170, 170);
  }
  &.Icons_Folder-1 .path2:before {
    content: '\e90e';
    margin-left: -1.3330078125em;
    color: rgb(153, 153, 153);
  }
  &.Icons_Folder-1 .path3:before {
    content: '\e90f';
    margin-left: -1.3330078125em;
    color: rgb(129, 129, 129);
  }
  &.Icons_Folder-2 .path1:before {
    content: '\e910';
    color: rgb(255, 255, 255);
  }
  &.Icons_Folder-2 .path2:before {
    content: '\e911';
    margin-left: -1.3125em;
    color: rgb(0, 0, 0);
  }
  &.Icons_Folder-View:before {
    content: '\e912';
  }
  &.Icons_Gears:before {
    content: '\e913';
  }
  &.Icons_Horizontal-Arrow:before {
    content: '\e914';
  }
  &.Icons_Info-1:before {
    content: '\e915';
  }
  &.Icons_Info-2:before {
    content: '\e916';
  }
  &.Icons_Logged-in:before {
    content: '\e917';
  }
  &.Icons_Management:before {
    content: '\e918';
  }
  &.Icons_MyAgingPlan:before {
    content: '\e919';
  }
  &.Icons_PDF:before {
    content: '\e91a';
  }
  &.Icons_Preview:before {
    content: '\e91b';
  }
  &.Icons_Profile:before {
    content: '\e91c';
  }
  &.Icons_Refresh:before {
    content: '\e91d';
  }
  &.Icons_Search:before {
    content: '\e91e';
  }
  &.Icons_Share:before {
    content: '\e91f';
  }
  &.Icons_Sort:before {
    content: '\e920';
  }
  &.Icons_Toggle-OFF .path1:before {
    content: '\e921';
    color: rgb(0, 0, 0);
  }
  &.Icons_Toggle-OFF .path2:before {
    content: '\e922';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-OFF .path3:before {
    content: '\e923';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-OFF .path4:before {
    content: '\e924';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  &.Icons_Toggle-OFF .path5:before {
    content: '\e925';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-OFF .path6:before {
    content: '\e926';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-OFF .path7:before {
    content: '\e927';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  &.Icons_Toggle-OFF .path8:before {
    content: '\e928';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-OFF .path9:before {
    content: '\e929';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-ON .path1:before {
    content: '\e92a';
    color: rgb(0, 0, 0);
  }
  &.Icons_Toggle-ON .path2:before {
    content: '\e92b';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-ON .path3:before {
    content: '\e92c';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-ON .path4:before {
    content: '\e92d';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  &.Icons_Toggle-ON .path5:before {
    content: '\e92e';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-ON .path6:before {
    content: '\e92f';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-ON .path7:before {
    content: '\e930';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  &.Icons_Toggle-ON .path8:before {
    content: '\e931';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Toggle-ON .path9:before {
    content: '\e932';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.Icons_Upload:before {
    content: '\e933';
  }
  &.Icons_Vertical-Arrow:before {
    content: '\e934';
  }
  &.Icons_W:before {
    content: '\e935';
  }
  &.Icons_Z-to-A:before {
    content: '\e936';
  }
  &.Logo_AgingPlan_Grey .path1:before {
    content: '\e937';
    color: rgb(214, 215, 217);
  }
  &.Logo_AgingPlan_Grey .path2:before {
    content: '\e938';
    margin-left: -1.927734375em;
    color: rgb(0, 0, 0);
  }
  &.Logo_AgingPlan_Grey .path3:before {
    content: '\e939';
    margin-left: -1.927734375em;
    color: rgb(0, 0, 0);
  }
  &.Logo_AgingPlan_Grey .path4:before {
    content: '\e93a';
    margin-left: -1.927734375em;
    color: rgb(0, 0, 0);
  }
  &.Logo_AgingPlan_Grey .path5:before {
    content: '\e93b';
    margin-left: -1.927734375em;
    color: rgb(0, 0, 0);
  }
  &.Logo_AgingPlan_Grey .path6:before {
    content: '\e93c';
    margin-left: -1.927734375em;
    color: rgb(0, 0, 0);
  }
  &.Logo_AgingPlan_Grey .path7:before {
    content: '\e93d';
    margin-left: -1.927734375em;
    color: rgb(0, 0, 0);
  }
  &.Logo_AgingPlan_Grey .path8:before {
    content: '\e93e';
    margin-left: -1.927734375em;
    color: rgb(0, 0, 0);
  }
  &.Logo_AgingPlan_Grey .path9:before {
    content: '\e93f';
    margin-left: -1.927734375em;
    color: rgb(0, 0, 0);
  }
  &.Logo_AgingPlan_Grey .path10:before {
    content: '\e940';
    margin-left: -1.927734375em;
    color: rgb(0, 0, 0);
  }
  &.Logo_AgingPlan_Grey .path11:before {
    content: '\e941';
    margin-left: -1.927734375em;
    color: rgb(0, 0, 0);
  }
  &.Logo_AgingPlan_Grey .path12:before {
    content: '\e942';
    margin-left: -1.927734375em;
    color: rgb(0, 0, 0);
  }
  &.Logo_AgingPlan_Tan .path1:before {
    content: '\e943';
    color: rgb(255, 255, 255);
  }
  &.Logo_AgingPlan_Tan .path2:before {
    content: '\e944';
    margin-left: -1.927734375em;
    color: rgb(255, 255, 255);
  }
  &.Logo_AgingPlan_Tan .path3:before {
    content: '\e945';
    margin-left: -1.927734375em;
    color: rgb(127, 120, 115);
  }
  &.Logo_AgingPlan_Tan .path4:before {
    content: '\e946';
    margin-left: -1.927734375em;
    color: rgb(255, 255, 255);
  }
  &.Logo_AgingPlan_Tan .path5:before {
    content: '\e947';
    margin-left: -1.927734375em;
    color: rgb(255, 255, 255);
  }
  &.Logo_AgingPlan_Tan .path6:before {
    content: '\e948';
    margin-left: -1.927734375em;
    color: rgb(255, 255, 255);
  }
  &.Logo_AgingPlan_Tan .path7:before {
    content: '\e949';
    margin-left: -1.927734375em;
    color: rgb(255, 255, 255);
  }
  &.Logo_AgingPlan_Tan .path8:before {
    content: '\e94a';
    margin-left: -1.927734375em;
    color: rgb(255, 255, 255);
  }
  &.Logo_AgingPlan_Tan .path9:before {
    content: '\e94b';
    margin-left: -1.927734375em;
    color: rgb(255, 255, 255);
  }
  &.Logo_AgingPlan_Tan .path10:before {
    content: '\e94c';
    margin-left: -1.927734375em;
    color: rgb(255, 255, 255);
  }
  &.Logo_AgingPlan_Tan .path11:before {
    content: '\e94d';
    margin-left: -1.927734375em;
    color: rgb(255, 255, 255);
  }
  &.Logo_AgingPlan_Tan .path12:before {
    content: '\e94e';
    margin-left: -1.927734375em;
    color: rgb(255, 255, 255);
  }
}
