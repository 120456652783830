// Media Query

@mixin min($minWidth) {
  @media (min-width: $minWidth) {
    @content;
  }
}

@mixin max($maxWidth) {
  @media (max-width: $maxWidth) {
    @content;
  }
}
