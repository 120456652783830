@use 'theme/mixin.scss' as *;

button {
  color: currentColor;
  border-style: solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  &.btn {
    display: inline-flex;
    line-height: initial;
    font-size: var(--font-size-base);

    * {
      display: inline-block;
    }

    &.btn-sm {
      width: calc(var(--btn-grid-breakpoint) * 2);
      height: calc(var(--btn-grid-breakpoint) * 3);
      min-height: calc(var(--btn-grid-breakpoint) * 3);
      min-width: calc(var(--btn-grid-breakpoint) * 2);
      padding: 0px;
      border-width: 0px;
    }
    &.btn-md {
      width: calc(var(--btn-grid-breakpoint) * 7);
      min-width: calc(var(--btn-grid-breakpoint) * 7);
      height: calc(var(--btn-grid-breakpoint) * 3);
      min-height: calc(var(--btn-grid-breakpoint) * 3);
    }
    &.btn-lg {
      width: calc(var(--btn-grid-breakpoint) * 15);
      min-width: calc(var(--btn-grid-breakpoint) * 15);
      height: calc(var(--btn-grid-breakpoint) * 3);
      min-height: calc(var(--btn-grid-breakpoint) * 3);
    }
    &.btn-xl {
      width: calc(var(--btn-grid-breakpoint) * 22);
      min-width: calc(var(--btn-grid-breakpoint) * 22);
      height: calc(var(--btn-grid-breakpoint) * 3);
      min-height: calc(var(--btn-grid-breakpoint) * 3);
      @include max(575px) {
        font-size: 10px;
      }
      @include max(415px) {
        width: calc(var(--btn-grid-breakpoint) * 15);
        min-width: calc(var(--btn-grid-breakpoint) * 15);
      }
    }
    &.btn-2xl {
      width: calc(var(--btn-grid-breakpoint) * 25);
      min-width: calc(var(--btn-grid-breakpoint) * 25);
      height: calc(var(--btn-grid-breakpoint) * 3);
      min-height: calc(var(--btn-grid-breakpoint) * 3);
    }
    &.btn-3xl {
      width: calc(var(--btn-grid-breakpoint) * 40);
      min-width: calc(var(--btn-grid-breakpoint) * 40);
      height: calc(var(--btn-grid-breakpoint) * 3);
      min-height: calc(var(--btn-grid-breakpoint) * 3);
    }
    &.btn-4xl {
      width: calc(var(--btn-grid-breakpoint) * 25);
      min-width: calc(var(--btn-grid-breakpoint) * 25);
      height: calc(var(--btn-grid-breakpoint) * 4);
      min-height: calc(var(--btn-grid-breakpoint) * 4);
    }
  }

  &,
  & span {
    text-transform: uppercase;
  }

  i {
    margin-right: 8px;
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
    font-size: 1.142857142857143em;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition:
      transform 0.3s,
      -webkit-transform 0.3s;
  }

  &.btn-bg-clear {
    i {
      color: var(--text-black);
    }
  }

  &,
  &:active,
  &:focus {
    outline: none;
  }

  &.active {
    background-color: var(--gray);
    color: white;
  }

  &.btn.btn-primary,
  &.btn.btn-secondary,
  &.btn.btn-success,
  &.btn.btn-info,
  &.btn.btn-dark,
  &.btn.btn-danger {
    color: var(--light);
  }
  &.btn.btn-link {
    color: var(--text-black);
  }
  &.btn.btn-outline-link {
    color: var(--text-black);
  }

  &.btn.btn-clear {
    border-color: transparent;

    &:hover {
      color: darken($gray, 30%);
      //background-color: #969696;
      // border-color: #898d8d;
    }

    &.active {
      background-color: var(--gray);
      color: white;
    }
  }

  &.btn.btn-white {
    color: var(--black);
    background-color: white;
    border-color: var(--black);
    border-width: 0px;
    overflow: hidden;
    transition: all 0.3s ease;

    // &:hover {
    //   background-color: var(--light);
    // }
    span {
      z-index: 2;
      position: relative;
    }
    /* swipe right to left */
    &.swipe-rtl {
      &:hover {
        border-color: var(--background-gray);
        color: #fff;
        &::after {
          transform: translateX(0);
          transition: transform 350ms ease;
        }
        &::before {
          opacity: 1;
          transform: translateX(0);
          transition: transform 350ms 360ms ease;
        }
      }
      &::before,
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--background-gray);
        z-index: -1;
      }
      &::after {
        transform: translateX(100%);
        z-index: 1;
      }
      &::before {
        z-index: 0;
        transform: translateX(-100%);
        transition: none;
        transition: transform 350ms ease;
      }
    }
  }

  &.btn.btn-black {
    background-color: lighten(black, 30%);
    border-color: black;
    color: white;
    overflow: hidden;
    transition: all 0.3s ease;
    border-width: 0px;

    span {
      z-index: 2;
      position: relative;
    }

    &:hover {
      background-color: black;
    }

    /* swipe right to left */
    &.swipe-rtl {
      &:hover {
        border-color: var(--background-gray);
        color: #fff;
        &::after {
          transform: translateX(0);
          transition: transform 350ms ease;
        }
        &::before {
          opacity: 1;
          transform: translateX(0);
          transition: transform 350ms 360ms ease;
        }
      }
      &::before,
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--background-gray);
        z-index: -1;
      }
      &::after {
        transform: translateX(100%);
        z-index: 1;
      }
      &::before {
        z-index: 0;
        transform: translateX(-100%);
        transition: none;
        transition: transform 350ms ease;
      }
    }
  }

  &.btn.btn-dark-black {
    background-color: black;
    border-color: black;
    color: white;
    border-width: 0px;

    span {
      z-index: 2;
      position: relative;
    }
    &:hover {
      background-color: black;
    }
    &.swipe-rtl {
      &:hover {
        color: var(--white);
        &::after {
          transform: translateX(0);
          transition: transform 350ms ease;
        }
        &::before {
          opacity: 1;
          transform: translateX(0);
          transition: transform 350ms 360ms ease;
        }
      }
      &::before,
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--button-color);
        z-index: -1;
      }
      &::after {
        transform: translateX(100%);
        z-index: 1;
      }
      &::before {
        z-index: 0;
        transform: translateX(-100%);
        transition: none;
        transition: transform 350ms ease;
      }
    }
  }

  &.btn.btn-red {
    background-color: var(--button-danger);
    border-color: var(--button-danger);
    color: var(--text-secondary);

    &:hover {
      background-color: var(--input-color-background);
      border-color: var(--button-danger);
      color: var(--button-danger);
    }
  }

  &.btn.btn-gray {
    background-color: #969696;
    border-color: #969696;
    color: white;

    &:hover {
      background-color: white;
      border-color: #969696;
      color: #969696;
    }
  }

  &.btn.btn-brown {
    background-color: var(--text-menu-hover);
    border-color: var(--text-menu-hover);
    color: var(--text-secondary);

    &:hover {
      background-color: var(--input-color-background);
      border-color: var(--text-menu-hover);
      color: var(--text-menu-hover);
    }
  }

  &.btn.btn-tan1 {
    background-color: var(--background-gray);
    border-color: var(--background-gray);
    color: var(--white);
    overflow: hidden;
    transition: all 0.3s ease;
    span {
      z-index: 2;
      position: relative;
    }
    &.active {
      background-color: var(--white);
      color: var(--black);
      &:hover {
        color: var(--white);
        background-color: var(--background-gray);
      }
    }

    /* swipe right to left */
    &.swipe-rtl {
      &:hover {
        border-color: var(--background-gray);
        color: var(--black);
        &::after {
          transform: translateX(0);
          transition: transform 350ms ease;
        }
        &::before {
          opacity: 1;
          transform: translateX(0);
          transition: transform 350ms 360ms ease;
        }
      }
      &::before,
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--white);
        z-index: -1;
      }
      &::after {
        transform: translateX(100%);
        z-index: 1;
      }
      &::before {
        z-index: 0;
        transform: translateX(-100%);
        transition: none;
        transition: transform 350ms ease;
      }
    }
  }

  &.btn.btn-tan2 {
    background-color: var(--tan-color-two);
    border-color: var(--tan-color-two);
    color: var(--text-secondary);
  }
  &.btn.btn-tan3 {
    background-color: var(--tan-color-three);
    border-color: var(--tan-color-three);
    color: var(--text-black);
  }
  &.btn.btn-gray1 {
    background-color: var(--text-neutral);
    border-color: var(--text-neutral);
    color: var(--text-secondary);
  }
  &.btn.btn-gray2 {
    background-color: var(--gray-color-two);
    border-color: var(--gray-color-two);
    color: var(--text-secondary);
  }
  &.btn.btn-gray3 {
    background-color: var(--text-tertiary);
    border-color: var(--text-tertiary);
    color: var(--text-black);
  }
  &.btn.btn-copper {
    background-color: var(--btn-copper);
    border-color: var(--btn-copper);
    color: var(--text-secondary);
  }

  &.btn.btn-light-black {
    background-color: var(--button-background-color);
    border: 1px solid var(--button-color);
    color: var(--white);

    &:hover {
      background-color: var(--input-color-background);
      border-color: var(--input-border-color);
      color: var(--text-neutral);
    }
  }

  &[disabled='true'] {
    &,
    button {
      filter: brightness(85%);
      cursor: not-allowed !important;
    }
  }
}

.wolf-btn {
  font-family: 'Gotham Book';
  font-style: normal;
  max-height: 60px;
  min-height: 45px;
  height: 45px;
  max-width: 370px;
  min-width: 198px;
  width: 198px;
  text-transform: uppercase;
  position: relative;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  span {
    z-index: 2;
    position: relative;
  }

  /* data table */
  &.dt {
    width: 225px;
  }

  /* btn size */
  &.lg {
    width: 100%;
    height: 60px;
  }
  &.sm {
    width: 198px;
    height: 45px;
  }
  /* font family */
  &.ntb {
    font-family: 'Neutraface Text Book', sans-serif;
  }
  &.gb {
    font-family: 'Gotham Book', sans-serif;
  }
  /* for anchor tag */
  &:hover {
    text-decoration: none;
  }
  /* colors */
  &.white {
    color: var(--background-gray);
    border-color: var(--white);
    background-color: var(--white);
    /* swipe left animation */
    &.swipe-left {
      &:hover {
        color: var(--white);
        &::before {
          transform: translateX(-50%);
          background-color: var(--background-gray);
        }
      }
      &::before {
        content: '';
        transform: translateX(-101%);
        position: absolute;
        left: 0px;
        top: -5px;
        height: 140%;
        width: 240%;
        transition: all 0.5s ease-in-out 0s;
      }
    }
    /* swipe right animation */
    &.swipe-right {
      &:hover {
        color: var(--white);
        &::before {
          transform: translateX(0%);
          background-color: var(--background-gray);
        }
      }
      &::before {
        content: '';
        transform: translateX(50%);
        position: absolute;
        left: 0px;
        top: 0px;
        height: 140%;
        width: 240%;
        transition: all 0.5s ease-in-out 0s;
      }
    }
    /* swipe left to right */
    &.swipe-ltr {
      &:hover {
        color: var(--white);
        &::before {
          transform: translateX(0);
          transition: transform 350ms ease;
        }
        &::after {
          opacity: 1;
          transform: translateX(0);
          transition: transform 350ms 360ms ease;
        }
      }
      &::before,
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--background-gray);
        z-index: -1;
      }
      &::before {
        transform: translateX(-100%);
        z-index: 1;
      }
      &::after {
        z-index: 0;
        transform: translateX(100%);
        transition: none;
        transition: transform 350ms ease;
      }
    }
    /* swipe right to left */
    &.swipe-rtl {
      &:hover {
        color: var(--white);
        &::after {
          transform: translateX(0);
          transition: transform 350ms ease;
        }
        &::before {
          opacity: 1;
          transform: translateX(0);
          transition: transform 350ms 360ms ease;
        }
      }
      &::before,
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--background-gray);
        z-index: -1;
      }
      &::after {
        transform: translateX(100%);
        z-index: 1;
      }
      &::before {
        z-index: 0;
        transform: translateX(-100%);
        transition: none;
        transition: transform 350ms ease;
      }
    }
    /* active */
    &.active {
      background-color: var(--background-gray);
      color: $white;
    }
    /* hover */
    &.hover {
      &:hover {
        color: var(--white);
        background-color: var(--background-gray);
      }
    }
  }
  &.primary {
    color: $primary;
    border-color: $primary;
    /* swipe left animation */
    &.swipe-left {
      &:hover {
        color: $white;
        &::before {
          transform: translateX(-50%);
          background-color: $primary;
        }
      }
      &::before {
        content: '';
        transform: translateX(-101%);
        position: absolute;
        left: 0px;
        top: -5px;
        height: 140%;
        width: 240%;
        transition: all 0.5s ease-in-out 0s;
      }
    }
    /* swipe right animation */
    &.swipe-right {
      &:hover {
        color: $white;
        &::before {
          transform: translateX(0%);
          background-color: $primary;
        }
      }
      &::before {
        content: '';
        transform: translateX(50%);
        position: absolute;
        left: 0px;
        top: 0px;
        height: 140%;
        width: 240%;
        transition: all 0.5s ease-in-out 0s;
      }
    }
    /* swipe left to right */
    &.swipe-ltr {
      &:hover {
        color: $white;
        &::before {
          transform: translateX(0);
          transition: transform 350ms ease;
        }
        &::after {
          opacity: 1;
          transform: translateX(0);
          transition: transform 350ms 360ms ease;
        }
      }
      &::before,
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background: $primary;
        z-index: -1;
      }
      &::before {
        transform: translateX(-100%);
        z-index: 1;
      }
      &::after {
        z-index: 0;
        transform: translateX(100%);
        transition: none;
        transition: transform 350ms ease;
      }
    }
    /* swipe right to left */
    &.swipe-rtl {
      &:hover {
        color: $white;
        &::after {
          transform: translateX(0);
          transition: transform 350ms ease;
        }
        &::before {
          opacity: 1;
          transform: translateX(0);
          transition: transform 350ms 360ms ease;
        }
      }
      &::before,
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background: $primary;
        z-index: -1;
      }
      &::after {
        transform: translateX(100%);
        z-index: 1;
      }
      &::before {
        z-index: 0;
        transform: translateX(-100%);
        transition: none;
        transition: transform 350ms ease;
      }
    }
    /* active */
    &.active {
      background-color: $primary;
      color: $white;
    }
    /* hover */
    &.hover {
      &:hover {
        color: $white;
        background-color: $primary;
      }
    }
  }
  &.clear {
    color: var(--gray);
    border-color: var(--gray);
    /* swipe left */
    &.swipe-left {
      &:hover {
        color: $white;
        &::before {
          transform: translateX(-50%);
          background-color: var(--gray);
        }
      }
      &::before {
        content: '';
        transform: translateX(-101%);
        position: absolute;
        left: 0px;
        top: -5px;
        height: 140%;
        width: 240%;
        transition: all 0.5s ease-in-out 0s;
      }
    }
    /* swipe right */
    &.swipe-right {
      &:hover {
        color: $white;
        &::before {
          transform: translateX(0%);
          background-color: var(--gray);
        }
      }
      &::before {
        content: '';
        transform: translateX(50%);
        position: absolute;
        left: 0px;
        top: 0px;
        height: 140%;
        width: 240%;
        transition: all 0.5s ease-in-out 0s;
      }
    }
    /* swipe left to right */
    &.swipe-ltr {
      &:hover {
        color: $white;
        &::before {
          transform: translateX(0);
          transition: transform 350ms ease;
        }
        &::after {
          opacity: 1;
          transform: translateX(0);
          transition: transform 350ms 360ms ease;
        }
      }
      &::before,
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--gray);
        z-index: -1;
      }
      &::before {
        transform: translateX(-100%);
        z-index: 1;
      }
      &::after {
        z-index: 0;
        transform: translateX(100%);
        transition: none;
        transition: transform 350ms ease;
      }
    }
    /* swipe right to left */
    &.swipe-rtl {
      &:hover {
        color: $white;
        &::after {
          transform: translateX(0);
          transition: transform 350ms ease;
        }
        &::before {
          opacity: 1;
          transform: translateX(0);
          transition: transform 350ms 360ms ease;
        }
      }
      &::before,
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--gray);
        z-index: -1;
      }
      &::after {
        transform: translateX(100%);
        z-index: 1;
      }
      &::before {
        z-index: 0;
        transform: translateX(-100%);
        transition: none;
        transition: transform 350ms ease;
      }
    }
    /* active */
    &.active {
      background-color: var(--gray);
      color: $white;
    }
    /* hover */
    &.hover {
      &:hover {
        color: $white;
        background-color: var(--gray);
      }
    }
  }
  &.red {
    color: $red;
    border-color: $red;
    /* swipe left */
    &.swipe-left {
      &:hover {
        color: $white;
        &::before {
          transform: translateX(-50%);
          background-color: $red;
        }
      }
      &::before {
        content: '';
        transform: translateX(-101%);
        position: absolute;
        left: 0px;
        top: -5px;
        height: 140%;
        width: 240%;
        transition: all 0.5s ease-in-out 0s;
      }
    }
    /* swipe right */
    &.swipe-right {
      &:hover {
        color: $white;
        &::before {
          transform: translateX(0%);
          background-color: $red;
        }
      }
      &::before {
        content: '';
        transform: translateX(50%);
        position: absolute;
        left: 0px;
        top: 0px;
        height: 140%;
        width: 240%;
        transition: all 0.5s ease-in-out 0s;
      }
    }
    /* swipe left to right */
    &.swipe-ltr {
      &:hover {
        color: $white;
        &::before {
          transform: translateX(0);
          transition: transform 350ms ease;
        }
        &::after {
          opacity: 1;
          transform: translateX(0);
          transition: transform 350ms 360ms ease;
        }
      }
      &::before,
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background: $red;
        z-index: -1;
      }
      &::before {
        transform: translateX(-100%);
        z-index: 1;
      }
      &::after {
        z-index: 0;
        transform: translateX(100%);
        transition: none;
        transition: transform 350ms ease;
      }
    }
    /* swipe right to left */
    &.swipe-rtl {
      &:hover {
        color: $white;
        &::after {
          transform: translateX(0);
          transition: transform 350ms ease;
        }
        &::before {
          opacity: 1;
          transform: translateX(0);
          transition: transform 350ms 360ms ease;
        }
      }
      &::before,
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background: $red;
        z-index: -1;
      }
      &::after {
        transform: translateX(100%);
        z-index: 1;
      }
      &::before {
        z-index: 0;
        transform: translateX(-100%);
        transition: none;
        transition: transform 350ms ease;
      }
    }
    /* active */
    &.active {
      background-color: $red;
      color: $white;
    }
    /* hover */
    &.hover {
      &:hover {
        color: $white;
        background-color: $red;
      }
    }
  }
  &.btn-tan {
    background-color: var(--background-gray);
    border-color: var(--background-gray);
    color: var(--text-secondary);
    overflow: hidden;
    transition: all 0.3s ease;
    border-width: 0;
    span {
      z-index: 2;
      position: relative;
    }
    &.active {
      background-color: var(--white);
      color: var(--black);
      &:hover {
        color: var(--white);
        background-color: var(--background-gray);
      }
    }

    /* swipe right to left */
    &.swipe-rtl {
      &:hover {
        border-color: var(--background-gray);
        color: var(--black);
        &::after {
          transform: translateX(0);
          transition: transform 350ms ease;
        }
        &::before {
          opacity: 1;
          transform: translateX(0);
          transition: transform 350ms 360ms ease;
        }
      }
      &::before,
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--white);
        z-index: -1;
      }
      &::after {
        transform: translateX(100%);
        z-index: 1;
      }
      &::before {
        z-index: 0;
        transform: translateX(-100%);
        transition: none;
        transition: transform 350ms ease;
      }
    }
  }
}
