@charset "UTF-8";
:root {
  --container-max-width: 1170px;
  --grid-breakpoint: 15px;
  --navbar-height: 144px;
  --sidebar-width: 360px;
  --sidebar-arrow-width: 60px;
  --btn-grid-breakpoint: 15px;
  --font-size-sm: 12px;
  --font-size-base: 15px;
  --font-size-lg: 20px;
  --font-size-xl: 25px;
}

@font-face {
  font-family: "Indigo Blue";
  src: url("/assets/fonts/IndigoBlue.eot");
  src: url("/assets/fonts/IndigoBlue.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/IndigoBlue.woff2") format("woff2"), url("/assets/fonts/IndigoBlue.woff") format("woff"), url("/assets/fonts/IndigoBlue.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Book";
  src: url("/assets/fonts/Gotham-Book.eot");
  src: url("/assets/fonts/Gotham-Book.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Gotham-Book.woff2") format("woff2"), url("/assets/fonts/Gotham-Book.woff") format("woff"), url("/assets/fonts/Gotham-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Light";
  src: url("/assets/fonts/Gotham-Light.eot");
  src: url("/assets/fonts/Gotham-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Gotham-Light.woff2") format("woff2"), url("/assets/fonts/Gotham-Light.woff") format("woff"), url("/assets/fonts/Gotham-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "Neutraface Text Book";
  src: url("/assets/fonts/NeutrafaceText-Book.eot");
  src: url("/assets/fonts/NeutrafaceText-Book.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/NeutrafaceText-Book.woff2") format("woff2"), url("/assets/fonts/NeutrafaceText-Book.woff") format("woff"), url("/assets/fonts/NeutrafaceText-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "EB Garamond";
  src: url("/assets/fonts/EBGaramond-Regular.eot");
  src: url("/assets/fonts/EBGaramond-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/EBGaramond-Regular.woff2") format("woff2"), url("/assets/fonts/EBGaramond-Regular.woff") format("woff"), url("/assets/fonts/EBGaramond-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Neutraface Text";
  src: url("/assets/fonts/NeutrafaceText-Light.eot");
  src: url("/assets/fonts/NeutrafaceText-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/NeutrafaceText-Light.woff2") format("woff2"), url("/assets/fonts/NeutrafaceText-Light.woff") format("woff"), url("/assets/fonts/NeutrafaceText-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "PresicavLt";
  src: url("/assets/fonts/PresicavLt-Regular.eot");
  src: url("/assets/fonts/PresicavLt-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/PresicavLt-Regular.woff2") format("woff2"), url("/assets/fonts/PresicavLt-Regular.woff") format("woff"), url("/assets/fonts/PresicavLt-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/OpenSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Century Gothic";
  font-style: normal;
  font-weight: 400;
  src: local("Century Gothic"), url("/assets/fonts/Century-Gothic.woff") format("woff");
}
@font-face {
  font-family: "brand-icons";
  src: url("brand-icons.eot?y1s1oo");
  src: url("brand-icons.eot?y1s1oo#iefix") format("embedded-opentype"), url("brand-icons.ttf?y1s1oo") format("truetype"), url("brand-icons.woff?y1s1oo") format("woff"), url("brand-icons.svg?y1s1oo#brand-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i.brand-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "brand-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
i.brand-icon.Icons_Toggle-OFF1 .path1:before {
  content: "\e953";
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Toggle-OFF1 .path2:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-OFF1 .path3:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-OFF1 .path4:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Toggle-OFF1 .path5:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-OFF1 .path6:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-OFF1 .path7:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Toggle-OFF1 .path8:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-OFF1 .path9:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-ON1 .path1:before {
  content: "\e95c";
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Toggle-ON1 .path2:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-ON1 .path3:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-ON1 .path4:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Toggle-ON1 .path5:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-ON1 .path6:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-ON1 .path7:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Toggle-ON1 .path8:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-ON1 .path9:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Vertical-Arrow1:before {
  content: "\e965";
}
i.brand-icon.Icons_Z-to-A1:before {
  content: "\e966";
}
i.brand-icon.Icons_A-to-Z1:before {
  content: "\e967";
}
i.brand-icon.Icons_Checkbox-Empty1:before {
  content: "\e968";
}
i.brand-icon.Icons_Checkbox-Fill1:before {
  content: "\e969";
}
i.brand-icon.Icons_Dark-light-Mode1:before {
  content: "\e96a";
}
i.brand-icon.Icons_Folder-11 .path1:before {
  content: "\e96b";
  color: rgb(170, 170, 170);
}
i.brand-icon.Icons_Folder-11 .path2:before {
  content: "\e96c";
  margin-left: -1.3330078125em;
  color: rgb(153, 153, 153);
}
i.brand-icon.Icons_Folder-11 .path3:before {
  content: "\e96d";
  margin-left: -1.3330078125em;
  color: rgb(129, 129, 129);
}
i.brand-icon.Icons_Folder-21 .path1:before {
  content: "\e96e";
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Folder-21 .path2:before {
  content: "\e96f";
  margin-left: -1.3125em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Folder-View1:before {
  content: "\e970";
}
i.brand-icon.Icons_Horizontal-Arrow1:before {
  content: "\e971";
}
i.brand-icon.Icons_Info-11:before {
  content: "\e972";
}
i.brand-icon.Icons_Info-21:before {
  content: "\e973";
}
i.brand-icon.Icons_Logged-in1:before {
  content: "\e974";
}
i.brand-icon.Icons_LTCI-Specialist1:before {
  content: "\e975";
}
i.brand-icon.Icons_OurGoals:before {
  content: "\e94f";
  color: #fff;
}
i.brand-icon.Icons_PPT:before {
  content: "\e950";
  color: #fff;
}
i.brand-icon.Icons_Timeline:before {
  content: "\e951";
  color: #fff;
}
i.brand-icon.Icons_Agreement:before {
  content: "\e952";
  color: #fff;
}
i.brand-icon.Icons_LTCI-Specialist:before {
  content: "\e900";
}
i.brand-icon.Icons_Dark-light-Mode:before {
  content: "\e901";
}
i.brand-icon.Icons_A-to-Z:before {
  content: "\e902";
}
i.brand-icon.Icons_Back:before {
  content: "\e903";
}
i.brand-icon.Icons_Bell:before {
  content: "\e904";
}
i.brand-icon.Icons_Bin:before {
  content: "\e905";
}
i.brand-icon.Icons_Checkbox-Empty:before {
  content: "\e906";
}
i.brand-icon.Icons_Checkbox-Fill:before {
  content: "\e907";
}
i.brand-icon.Icons_Copy:before {
  content: "\e908";
}
i.brand-icon.Icons_Download:before {
  content: "\e909";
}
i.brand-icon.Icons_Drag:before {
  content: "\e90a";
}
i.brand-icon.Icons_Dropdown:before {
  content: "\e90b";
}
i.brand-icon.Icons_Envelop:before {
  content: "\e90c";
}
i.brand-icon.Icons_Folder-1 .path1:before {
  content: "\e90d";
  color: rgb(170, 170, 170);
}
i.brand-icon.Icons_Folder-1 .path2:before {
  content: "\e90e";
  margin-left: -1.3330078125em;
  color: rgb(153, 153, 153);
}
i.brand-icon.Icons_Folder-1 .path3:before {
  content: "\e90f";
  margin-left: -1.3330078125em;
  color: rgb(129, 129, 129);
}
i.brand-icon.Icons_Folder-2 .path1:before {
  content: "\e910";
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Folder-2 .path2:before {
  content: "\e911";
  margin-left: -1.3125em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Folder-View:before {
  content: "\e912";
}
i.brand-icon.Icons_Gears:before {
  content: "\e913";
}
i.brand-icon.Icons_Horizontal-Arrow:before {
  content: "\e914";
}
i.brand-icon.Icons_Info-1:before {
  content: "\e915";
}
i.brand-icon.Icons_Info-2:before {
  content: "\e916";
}
i.brand-icon.Icons_Logged-in:before {
  content: "\e917";
}
i.brand-icon.Icons_Management:before {
  content: "\e918";
}
i.brand-icon.Icons_MyAgingPlan:before {
  content: "\e919";
}
i.brand-icon.Icons_PDF:before {
  content: "\e91a";
}
i.brand-icon.Icons_Preview:before {
  content: "\e91b";
}
i.brand-icon.Icons_Profile:before {
  content: "\e91c";
}
i.brand-icon.Icons_Refresh:before {
  content: "\e91d";
}
i.brand-icon.Icons_Search:before {
  content: "\e91e";
}
i.brand-icon.Icons_Share:before {
  content: "\e91f";
}
i.brand-icon.Icons_Sort:before {
  content: "\e920";
}
i.brand-icon.Icons_Toggle-OFF .path1:before {
  content: "\e921";
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Toggle-OFF .path2:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-OFF .path3:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-OFF .path4:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Toggle-OFF .path5:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-OFF .path6:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-OFF .path7:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Toggle-OFF .path8:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-OFF .path9:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-ON .path1:before {
  content: "\e92a";
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Toggle-ON .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-ON .path3:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-ON .path4:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Toggle-ON .path5:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-ON .path6:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-ON .path7:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Icons_Toggle-ON .path8:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Toggle-ON .path9:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Icons_Upload:before {
  content: "\e933";
}
i.brand-icon.Icons_Vertical-Arrow:before {
  content: "\e934";
}
i.brand-icon.Icons_W:before {
  content: "\e935";
}
i.brand-icon.Icons_Z-to-A:before {
  content: "\e936";
}
i.brand-icon.Logo_AgingPlan_Grey .path1:before {
  content: "\e937";
  color: rgb(214, 215, 217);
}
i.brand-icon.Logo_AgingPlan_Grey .path2:before {
  content: "\e938";
  margin-left: -1.927734375em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Logo_AgingPlan_Grey .path3:before {
  content: "\e939";
  margin-left: -1.927734375em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Logo_AgingPlan_Grey .path4:before {
  content: "\e93a";
  margin-left: -1.927734375em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Logo_AgingPlan_Grey .path5:before {
  content: "\e93b";
  margin-left: -1.927734375em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Logo_AgingPlan_Grey .path6:before {
  content: "\e93c";
  margin-left: -1.927734375em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Logo_AgingPlan_Grey .path7:before {
  content: "\e93d";
  margin-left: -1.927734375em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Logo_AgingPlan_Grey .path8:before {
  content: "\e93e";
  margin-left: -1.927734375em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Logo_AgingPlan_Grey .path9:before {
  content: "\e93f";
  margin-left: -1.927734375em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Logo_AgingPlan_Grey .path10:before {
  content: "\e940";
  margin-left: -1.927734375em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Logo_AgingPlan_Grey .path11:before {
  content: "\e941";
  margin-left: -1.927734375em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Logo_AgingPlan_Grey .path12:before {
  content: "\e942";
  margin-left: -1.927734375em;
  color: rgb(0, 0, 0);
}
i.brand-icon.Logo_AgingPlan_Tan .path1:before {
  content: "\e943";
  color: rgb(255, 255, 255);
}
i.brand-icon.Logo_AgingPlan_Tan .path2:before {
  content: "\e944";
  margin-left: -1.927734375em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Logo_AgingPlan_Tan .path3:before {
  content: "\e945";
  margin-left: -1.927734375em;
  color: rgb(127, 120, 115);
}
i.brand-icon.Logo_AgingPlan_Tan .path4:before {
  content: "\e946";
  margin-left: -1.927734375em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Logo_AgingPlan_Tan .path5:before {
  content: "\e947";
  margin-left: -1.927734375em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Logo_AgingPlan_Tan .path6:before {
  content: "\e948";
  margin-left: -1.927734375em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Logo_AgingPlan_Tan .path7:before {
  content: "\e949";
  margin-left: -1.927734375em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Logo_AgingPlan_Tan .path8:before {
  content: "\e94a";
  margin-left: -1.927734375em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Logo_AgingPlan_Tan .path9:before {
  content: "\e94b";
  margin-left: -1.927734375em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Logo_AgingPlan_Tan .path10:before {
  content: "\e94c";
  margin-left: -1.927734375em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Logo_AgingPlan_Tan .path11:before {
  content: "\e94d";
  margin-left: -1.927734375em;
  color: rgb(255, 255, 255);
}
i.brand-icon.Logo_AgingPlan_Tan .path12:before {
  content: "\e94e";
  margin-left: -1.927734375em;
  color: rgb(255, 255, 255);
}

.brand-logo {
  position: relative;
  display: block;
  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;
  width: auto;
  height: 112px;
  object-fit: contain;
}
.brand-logo a,
.brand-logo img {
  width: auto;
  height: 112px;
  line-height: 112px;
}
.brand-logo a {
  padding: 0;
  margin: 0;
  text-decoration: none;
}
.brand-logo a:hover {
  text-decoration: none;
  color: #000;
}
.brand-logo a:visited {
  text-decoration: none;
  color: #000;
}
.brand-logo img {
  position: absolute;
  top: 0;
  left: -15px;
  z-index: 14;
  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;
}
.navbar-slim .brand-logo {
  height: 70px;
}
.navbar-slim .brand-logo a,
.navbar-slim .brand-logo img {
  height: 70px;
}

.brand-logo.brand-footer a,
.brand-logo.brand-footer span {
  color: white;
}
.brand-logo.brand-footer a:hover,
.brand-logo.brand-footer span:hover {
  color: white;
}
@media (max-width: 1199.98px) {
  .brand-logo.brand-footer {
    margin-bottom: 40px;
  }
}
@media (max-width: 767.98px) {
  .brand-logo.brand-footer {
    margin: 0px auto 40px auto;
    left: 25px;
  }
}

.brand-logo.large {
  width: 335px;
  height: 156px;
  margin: 10px auto 60px auto;
  left: 25px;
}
.brand-logo.large img {
  line-height: 156px;
  width: 335px;
  height: 156px;
}
@media (max-width: 767.98px) {
  .brand-logo.large {
    left: -10px;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.zoominout {
  transform: scale(1);
  animation: zoominoutanimation 1.2s;
}

@keyframes zoominoutanimation {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
body ::-webkit-scrollbar-track {
  background-color: transparent;
}
body ::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: transparent;
}
body ::-webkit-scrollbar-thumb {
  background-color: var(--button-background-color);
}

.scroll {
  overflow-y: overlay;
  overflow-x: hidden;
  height: 100%;
  max-height: calc(100vh - 305px);
}

@media only screen and (max-width: 1280px) {
  .scroll {
    max-height: calc(100vh - 340px);
  }
}
@media only screen and (max-width: 767.5px) {
  .scroll {
    max-height: calc(100vh - 340px);
  }
}
a {
  cursor: pointer;
}

.invalid-feedback {
  color: var(--text-copper) !important;
}

.form-control.is-invalid {
  filter: hue-rotate(45deg);
}

.footer-sticky-item {
  position: absolute;
  width: 100vw;
  max-width: calc(100vw - var(--sidebar-width));
  bottom: 40px;
}

@media only screen and (max-width: 1919.5px) {
  .footer-sticky-item {
    max-width: calc(100vw - var(--sidebar-arrow-width));
  }
}
@media only screen and (max-width: 1280.5px) {
  .footer-sticky-item {
    max-width: 100%;
  }
}
.label-20 label {
  font-size: 20px !important;
}

.font-lg {
  font-size: 20px !important;
  line-height: 24px;
  padding: 15px;
  margin: 0;
}

.tooltip-icon {
  color: var(--text-black);
}

.custom-gap-2 {
  gap: 30px;
}

:root {
  --container-max-width: 1170px;
  --grid-breakpoint: 15px;
  --navbar-height: 144px;
  --sidebar-width: 360px;
  --sidebar-arrow-width: 60px;
  --btn-grid-breakpoint: 15px;
  --font-size-sm: 12px;
  --font-size-base: 15px;
  --font-size-lg: 20px;
  --font-size-xl: 25px;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #981b1e;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --text-color: #413e4a;
  --button-danger: #981b1e;
  --label-color: #ffffff;
  --label-background-color: #959595;
  --text-primary: #1a1a1a;
  --text-secondary: #ffffff;
  --text-tertiary: #f2f2f2;
  --text-black: #000000;
  --text-black-background-color: #191919;
  --small-text-color: #191919;
  --td-dark-bg: #ffffff;
  --select-background-color: #ffffff;
  --text-neutral: #959595;
  --text-disabled: #d3d3d3;
  --text-active: #e5e1cf;
  --text-hover: #787878;
  --text-menu-hover: #3f3b39;
  --button-color: #7f7873;
  --button-background-color: #333333;
  --form-color-border: #7c746a;
  --input-border-color: #959595;
  --input-color-background: #ffffff;
  --chrome-picker-background: #ffffff;
  --black: #000000;
  --input-color-text: #7f7873;
  --background-gray: #7f7873;
  --background-light-black: #3f3b39;
  --input-color-svg: #000000;
  --border-color-gray: #959595;
  --light-background: #f2f2f2;
  --input-img-background: #f2f2f2;
  --td-btn-background: #959595;
  --td-btn-disable-background: #959595;
  --td-btn-icon: #ffffff;
  --tr-active: #e5e4e3;
  --login-text-color: rgba(0, 0, 0, 0.5019607843);
  --tan-color-two: #3f3b39;
  --tan-color-three: #f2eeeb;
  --gray-color-two: #b3b3b3;
  --btn-copper: #b87333;
  --text-copper: #b87333;
  --light-hover: #f4f4f4;
  --primary: #7f7873;
  --secondary: #6c757d;
  --success: #000000;
  --info: #b3b3b3;
  --warning: #b87333;
  --danger: #981b1e;
  --light: #f4f4f4;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 480px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: "Gotham Book", "Helvetica Neue", Helvetica, Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Gotham Book", "Helvetica Neue", Helvetica, Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #7f7873;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #57524f;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Neutraface Text Book";
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 3.5rem;
}
@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.475rem + 2.7vw);
  }
}

h2, .h2 {
  font-size: 2rem;
}
@media (max-width: 1200px) {
  h2, .h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
}

h3, .h3 {
  font-size: 1.75rem;
}
@media (max-width: 1200px) {
  h3, .h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
}

h4, .h4 {
  font-size: 1.5rem;
}
@media (max-width: 1200px) {
  h4, .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
  }
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
  }
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 480px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 480px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-text-color,
.table-text-color > th,
.table-text-color > td {
  background-color: #cac9cc;
}
.table-text-color th,
.table-text-color td,
.table-text-color thead th,
.table-text-color tbody + tbody {
  border-color: #9c9ba1;
}

.table-hover .table-text-color:hover {
  background-color: #bdbcc0;
}
.table-hover .table-text-color:hover > td,
.table-hover .table-text-color:hover > th {
  background-color: #bdbcc0;
}

.table-button-danger,
.table-button-danger > th,
.table-button-danger > td {
  background-color: #e2bfc0;
}
.table-button-danger th,
.table-button-danger td,
.table-button-danger thead th,
.table-button-danger tbody + tbody {
  border-color: #c9888a;
}

.table-hover .table-button-danger:hover {
  background-color: #daadaf;
}
.table-hover .table-button-danger:hover > td,
.table-hover .table-button-danger:hover > th {
  background-color: #daadaf;
}

.table-label-color,
.table-label-color > th,
.table-label-color > td {
  background-color: white;
}
.table-label-color th,
.table-label-color td,
.table-label-color thead th,
.table-label-color tbody + tbody {
  border-color: white;
}

.table-hover .table-label-color:hover {
  background-color: #f2f2f2;
}
.table-hover .table-label-color:hover > td,
.table-hover .table-label-color:hover > th {
  background-color: #f2f2f2;
}

.table-label-background-color,
.table-label-background-color > th,
.table-label-background-color > td {
  background-color: #e1e1e1;
}
.table-label-background-color th,
.table-label-background-color td,
.table-label-background-color thead th,
.table-label-background-color tbody + tbody {
  border-color: #c8c8c8;
}

.table-hover .table-label-background-color:hover {
  background-color: #d4d4d4;
}
.table-hover .table-label-background-color:hover > td,
.table-hover .table-label-background-color:hover > th {
  background-color: #d4d4d4;
}

.table-text-primary,
.table-text-primary > th,
.table-text-primary > td {
  background-color: #bfbfbf;
}
.table-text-primary th,
.table-text-primary td,
.table-text-primary thead th,
.table-text-primary tbody + tbody {
  border-color: #888888;
}

.table-hover .table-text-primary:hover {
  background-color: #b2b2b2;
}
.table-hover .table-text-primary:hover > td,
.table-hover .table-text-primary:hover > th {
  background-color: #b2b2b2;
}

.table-text-secondary,
.table-text-secondary > th,
.table-text-secondary > td {
  background-color: white;
}
.table-text-secondary th,
.table-text-secondary td,
.table-text-secondary thead th,
.table-text-secondary tbody + tbody {
  border-color: white;
}

.table-hover .table-text-secondary:hover {
  background-color: #f2f2f2;
}
.table-hover .table-text-secondary:hover > td,
.table-hover .table-text-secondary:hover > th {
  background-color: #f2f2f2;
}

.table-text-tertiary,
.table-text-tertiary > th,
.table-text-tertiary > td {
  background-color: #fbfbfb;
}
.table-text-tertiary th,
.table-text-tertiary td,
.table-text-tertiary thead th,
.table-text-tertiary tbody + tbody {
  border-color: #f8f8f8;
}

.table-hover .table-text-tertiary:hover {
  background-color: #eeeeee;
}
.table-hover .table-text-tertiary:hover > td,
.table-hover .table-text-tertiary:hover > th {
  background-color: #eeeeee;
}

.table-text-black,
.table-text-black > th,
.table-text-black > td {
  background-color: #b8b8b8;
}
.table-text-black th,
.table-text-black td,
.table-text-black thead th,
.table-text-black tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-text-black:hover {
  background-color: #ababab;
}
.table-hover .table-text-black:hover > td,
.table-hover .table-text-black:hover > th {
  background-color: #ababab;
}

.table-text-black-background-color,
.table-text-black-background-color > th,
.table-text-black-background-color > td {
  background-color: #bfbfbf;
}
.table-text-black-background-color th,
.table-text-black-background-color td,
.table-text-black-background-color thead th,
.table-text-black-background-color tbody + tbody {
  border-color: #878787;
}

.table-hover .table-text-black-background-color:hover {
  background-color: #b2b2b2;
}
.table-hover .table-text-black-background-color:hover > td,
.table-hover .table-text-black-background-color:hover > th {
  background-color: #b2b2b2;
}

.table-small-text-color,
.table-small-text-color > th,
.table-small-text-color > td {
  background-color: #bfbfbf;
}
.table-small-text-color th,
.table-small-text-color td,
.table-small-text-color thead th,
.table-small-text-color tbody + tbody {
  border-color: #878787;
}

.table-hover .table-small-text-color:hover {
  background-color: #b2b2b2;
}
.table-hover .table-small-text-color:hover > td,
.table-hover .table-small-text-color:hover > th {
  background-color: #b2b2b2;
}

.table-td-dark-bg,
.table-td-dark-bg > th,
.table-td-dark-bg > td {
  background-color: white;
}
.table-td-dark-bg th,
.table-td-dark-bg td,
.table-td-dark-bg thead th,
.table-td-dark-bg tbody + tbody {
  border-color: white;
}

.table-hover .table-td-dark-bg:hover {
  background-color: #f2f2f2;
}
.table-hover .table-td-dark-bg:hover > td,
.table-hover .table-td-dark-bg:hover > th {
  background-color: #f2f2f2;
}

.table-select-background-color,
.table-select-background-color > th,
.table-select-background-color > td {
  background-color: white;
}
.table-select-background-color th,
.table-select-background-color td,
.table-select-background-color thead th,
.table-select-background-color tbody + tbody {
  border-color: white;
}

.table-hover .table-select-background-color:hover {
  background-color: #f2f2f2;
}
.table-hover .table-select-background-color:hover > td,
.table-hover .table-select-background-color:hover > th {
  background-color: #f2f2f2;
}

.table-text-neutral,
.table-text-neutral > th,
.table-text-neutral > td {
  background-color: #e1e1e1;
}
.table-text-neutral th,
.table-text-neutral td,
.table-text-neutral thead th,
.table-text-neutral tbody + tbody {
  border-color: #c8c8c8;
}

.table-hover .table-text-neutral:hover {
  background-color: #d4d4d4;
}
.table-hover .table-text-neutral:hover > td,
.table-hover .table-text-neutral:hover > th {
  background-color: #d4d4d4;
}

.table-text-disabled,
.table-text-disabled > th,
.table-text-disabled > td {
  background-color: #f3f3f3;
}
.table-text-disabled th,
.table-text-disabled td,
.table-text-disabled thead th,
.table-text-disabled tbody + tbody {
  border-color: #e8e8e8;
}

.table-hover .table-text-disabled:hover {
  background-color: #e6e6e6;
}
.table-hover .table-text-disabled:hover > td,
.table-hover .table-text-disabled:hover > th {
  background-color: #e6e6e6;
}

.table-text-active,
.table-text-active > th,
.table-text-active > td {
  background-color: #f8f7f2;
}
.table-text-active th,
.table-text-active td,
.table-text-active thead th,
.table-text-active tbody + tbody {
  border-color: #f1efe6;
}

.table-hover .table-text-active:hover {
  background-color: #efede1;
}
.table-hover .table-text-active:hover > td,
.table-hover .table-text-active:hover > th {
  background-color: #efede1;
}

.table-text-hover,
.table-text-hover > th,
.table-text-hover > td {
  background-color: #d9d9d9;
}
.table-text-hover th,
.table-text-hover td,
.table-text-hover thead th,
.table-text-hover tbody + tbody {
  border-color: #b9b9b9;
}

.table-hover .table-text-hover:hover {
  background-color: #cccccc;
}
.table-hover .table-text-hover:hover > td,
.table-hover .table-text-hover:hover > th {
  background-color: #cccccc;
}

.table-text-menu-hover,
.table-text-menu-hover > th,
.table-text-menu-hover > td {
  background-color: #c9c8c8;
}
.table-text-menu-hover th,
.table-text-menu-hover td,
.table-text-menu-hover thead th,
.table-text-menu-hover tbody + tbody {
  border-color: #9b9998;
}

.table-hover .table-text-menu-hover:hover {
  background-color: #bcbbbb;
}
.table-hover .table-text-menu-hover:hover > td,
.table-hover .table-text-menu-hover:hover > th {
  background-color: #bcbbbb;
}

.table-button-color,
.table-button-color > th,
.table-button-color > td {
  background-color: #dbd9d8;
}
.table-button-color th,
.table-button-color td,
.table-button-color thead th,
.table-button-color tbody + tbody {
  border-color: #bcb9b6;
}

.table-hover .table-button-color:hover {
  background-color: #cfcccb;
}
.table-hover .table-button-color:hover > td,
.table-hover .table-button-color:hover > th {
  background-color: #cfcccb;
}

.table-button-background-color,
.table-button-background-color > th,
.table-button-background-color > td {
  background-color: #c6c6c6;
}
.table-button-background-color th,
.table-button-background-color td,
.table-button-background-color thead th,
.table-button-background-color tbody + tbody {
  border-color: #959595;
}

.table-hover .table-button-background-color:hover {
  background-color: #b9b9b9;
}
.table-hover .table-button-background-color:hover > td,
.table-hover .table-button-background-color:hover > th {
  background-color: #b9b9b9;
}

.table-form-color-border,
.table-form-color-border > th,
.table-form-color-border > td {
  background-color: #dad8d5;
}
.table-form-color-border th,
.table-form-color-border td,
.table-form-color-border thead th,
.table-form-color-border tbody + tbody {
  border-color: #bbb7b2;
}

.table-hover .table-form-color-border:hover {
  background-color: #cecbc7;
}
.table-hover .table-form-color-border:hover > td,
.table-hover .table-form-color-border:hover > th {
  background-color: #cecbc7;
}

.table-input-border-color,
.table-input-border-color > th,
.table-input-border-color > td {
  background-color: #e1e1e1;
}
.table-input-border-color th,
.table-input-border-color td,
.table-input-border-color thead th,
.table-input-border-color tbody + tbody {
  border-color: #c8c8c8;
}

.table-hover .table-input-border-color:hover {
  background-color: #d4d4d4;
}
.table-hover .table-input-border-color:hover > td,
.table-hover .table-input-border-color:hover > th {
  background-color: #d4d4d4;
}

.table-input-color-background,
.table-input-color-background > th,
.table-input-color-background > td {
  background-color: white;
}
.table-input-color-background th,
.table-input-color-background td,
.table-input-color-background thead th,
.table-input-color-background tbody + tbody {
  border-color: white;
}

.table-hover .table-input-color-background:hover {
  background-color: #f2f2f2;
}
.table-hover .table-input-color-background:hover > td,
.table-hover .table-input-color-background:hover > th {
  background-color: #f2f2f2;
}

.table-chrome-picker-background,
.table-chrome-picker-background > th,
.table-chrome-picker-background > td {
  background-color: white;
}
.table-chrome-picker-background th,
.table-chrome-picker-background td,
.table-chrome-picker-background thead th,
.table-chrome-picker-background tbody + tbody {
  border-color: white;
}

.table-hover .table-chrome-picker-background:hover {
  background-color: #f2f2f2;
}
.table-hover .table-chrome-picker-background:hover > td,
.table-hover .table-chrome-picker-background:hover > th {
  background-color: #f2f2f2;
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8;
}
.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-black:hover {
  background-color: #ababab;
}
.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #ababab;
}

.table-input-color-text,
.table-input-color-text > th,
.table-input-color-text > td {
  background-color: #dbd9d8;
}
.table-input-color-text th,
.table-input-color-text td,
.table-input-color-text thead th,
.table-input-color-text tbody + tbody {
  border-color: #bcb9b6;
}

.table-hover .table-input-color-text:hover {
  background-color: #cfcccb;
}
.table-hover .table-input-color-text:hover > td,
.table-hover .table-input-color-text:hover > th {
  background-color: #cfcccb;
}

.table-background-gray,
.table-background-gray > th,
.table-background-gray > td {
  background-color: #dbd9d8;
}
.table-background-gray th,
.table-background-gray td,
.table-background-gray thead th,
.table-background-gray tbody + tbody {
  border-color: #bcb9b6;
}

.table-hover .table-background-gray:hover {
  background-color: #cfcccb;
}
.table-hover .table-background-gray:hover > td,
.table-hover .table-background-gray:hover > th {
  background-color: #cfcccb;
}

.table-background-light-black,
.table-background-light-black > th,
.table-background-light-black > td {
  background-color: #c9c8c8;
}
.table-background-light-black th,
.table-background-light-black td,
.table-background-light-black thead th,
.table-background-light-black tbody + tbody {
  border-color: #9b9998;
}

.table-hover .table-background-light-black:hover {
  background-color: #bcbbbb;
}
.table-hover .table-background-light-black:hover > td,
.table-hover .table-background-light-black:hover > th {
  background-color: #bcbbbb;
}

.table-input-color-svg,
.table-input-color-svg > th,
.table-input-color-svg > td {
  background-color: #b8b8b8;
}
.table-input-color-svg th,
.table-input-color-svg td,
.table-input-color-svg thead th,
.table-input-color-svg tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-input-color-svg:hover {
  background-color: #ababab;
}
.table-hover .table-input-color-svg:hover > td,
.table-hover .table-input-color-svg:hover > th {
  background-color: #ababab;
}

.table-border-color-gray,
.table-border-color-gray > th,
.table-border-color-gray > td {
  background-color: #e1e1e1;
}
.table-border-color-gray th,
.table-border-color-gray td,
.table-border-color-gray thead th,
.table-border-color-gray tbody + tbody {
  border-color: #c8c8c8;
}

.table-hover .table-border-color-gray:hover {
  background-color: #d4d4d4;
}
.table-hover .table-border-color-gray:hover > td,
.table-hover .table-border-color-gray:hover > th {
  background-color: #d4d4d4;
}

.table-light-background,
.table-light-background > th,
.table-light-background > td {
  background-color: #fbfbfb;
}
.table-light-background th,
.table-light-background td,
.table-light-background thead th,
.table-light-background tbody + tbody {
  border-color: #f8f8f8;
}

.table-hover .table-light-background:hover {
  background-color: #eeeeee;
}
.table-hover .table-light-background:hover > td,
.table-hover .table-light-background:hover > th {
  background-color: #eeeeee;
}

.table-input-img-background,
.table-input-img-background > th,
.table-input-img-background > td {
  background-color: #fbfbfb;
}
.table-input-img-background th,
.table-input-img-background td,
.table-input-img-background thead th,
.table-input-img-background tbody + tbody {
  border-color: #f8f8f8;
}

.table-hover .table-input-img-background:hover {
  background-color: #eeeeee;
}
.table-hover .table-input-img-background:hover > td,
.table-hover .table-input-img-background:hover > th {
  background-color: #eeeeee;
}

.table-td-btn-background,
.table-td-btn-background > th,
.table-td-btn-background > td {
  background-color: #e1e1e1;
}
.table-td-btn-background th,
.table-td-btn-background td,
.table-td-btn-background thead th,
.table-td-btn-background tbody + tbody {
  border-color: #c8c8c8;
}

.table-hover .table-td-btn-background:hover {
  background-color: #d4d4d4;
}
.table-hover .table-td-btn-background:hover > td,
.table-hover .table-td-btn-background:hover > th {
  background-color: #d4d4d4;
}

.table-td-btn-disable-background,
.table-td-btn-disable-background > th,
.table-td-btn-disable-background > td {
  background-color: #e1e1e1;
}
.table-td-btn-disable-background th,
.table-td-btn-disable-background td,
.table-td-btn-disable-background thead th,
.table-td-btn-disable-background tbody + tbody {
  border-color: #c8c8c8;
}

.table-hover .table-td-btn-disable-background:hover {
  background-color: #d4d4d4;
}
.table-hover .table-td-btn-disable-background:hover > td,
.table-hover .table-td-btn-disable-background:hover > th {
  background-color: #d4d4d4;
}

.table-td-btn-icon,
.table-td-btn-icon > th,
.table-td-btn-icon > td {
  background-color: white;
}
.table-td-btn-icon th,
.table-td-btn-icon td,
.table-td-btn-icon thead th,
.table-td-btn-icon tbody + tbody {
  border-color: white;
}

.table-hover .table-td-btn-icon:hover {
  background-color: #f2f2f2;
}
.table-hover .table-td-btn-icon:hover > td,
.table-hover .table-td-btn-icon:hover > th {
  background-color: #f2f2f2;
}

.table-tr-active,
.table-tr-active > th,
.table-tr-active > td {
  background-color: #f8f7f7;
}
.table-tr-active th,
.table-tr-active td,
.table-tr-active thead th,
.table-tr-active tbody + tbody {
  border-color: #f1f1f0;
}

.table-hover .table-tr-active:hover {
  background-color: #ece9e9;
}
.table-hover .table-tr-active:hover > td,
.table-hover .table-tr-active:hover > th {
  background-color: #ece9e9;
}

.table-login-text-color,
.table-login-text-color > th,
.table-login-text-color > td {
  background-color: rgba(226, 226, 226, 0.8605490196);
}
.table-login-text-color th,
.table-login-text-color td,
.table-login-text-color thead th,
.table-login-text-color tbody + tbody {
  border-color: rgba(187, 187, 187, 0.7410196078);
}

.table-hover .table-login-text-color:hover {
  background-color: rgba(213, 213, 213, 0.8605490196);
}
.table-hover .table-login-text-color:hover > td,
.table-hover .table-login-text-color:hover > th {
  background-color: rgba(213, 213, 213, 0.8605490196);
}

.table-tan-color-two,
.table-tan-color-two > th,
.table-tan-color-two > td {
  background-color: #c9c8c8;
}
.table-tan-color-two th,
.table-tan-color-two td,
.table-tan-color-two thead th,
.table-tan-color-two tbody + tbody {
  border-color: #9b9998;
}

.table-hover .table-tan-color-two:hover {
  background-color: #bcbbbb;
}
.table-hover .table-tan-color-two:hover > td,
.table-hover .table-tan-color-two:hover > th {
  background-color: #bcbbbb;
}

.table-tan-color-three,
.table-tan-color-three > th,
.table-tan-color-three > td {
  background-color: #fbfaf9;
}
.table-tan-color-three th,
.table-tan-color-three td,
.table-tan-color-three thead th,
.table-tan-color-three tbody + tbody {
  border-color: #f8f6f5;
}

.table-hover .table-tan-color-three:hover {
  background-color: #f1edea;
}
.table-hover .table-tan-color-three:hover > td,
.table-hover .table-tan-color-three:hover > th {
  background-color: #f1edea;
}

.table-gray-color-two,
.table-gray-color-two > th,
.table-gray-color-two > td {
  background-color: #eaeaea;
}
.table-gray-color-two th,
.table-gray-color-two td,
.table-gray-color-two thead th,
.table-gray-color-two tbody + tbody {
  border-color: #d7d7d7;
}

.table-hover .table-gray-color-two:hover {
  background-color: #dddddd;
}
.table-hover .table-gray-color-two:hover > td,
.table-hover .table-gray-color-two:hover > th {
  background-color: #dddddd;
}

.table-btn-copper,
.table-btn-copper > th,
.table-btn-copper > td {
  background-color: #ebd8c6;
}
.table-btn-copper th,
.table-btn-copper td,
.table-btn-copper thead th,
.table-btn-copper tbody + tbody {
  border-color: #dab695;
}

.table-hover .table-btn-copper:hover {
  background-color: #e4cbb3;
}
.table-hover .table-btn-copper:hover > td,
.table-hover .table-btn-copper:hover > th {
  background-color: #e4cbb3;
}

.table-text-copper,
.table-text-copper > th,
.table-text-copper > td {
  background-color: #ebd8c6;
}
.table-text-copper th,
.table-text-copper td,
.table-text-copper thead th,
.table-text-copper tbody + tbody {
  border-color: #dab695;
}

.table-hover .table-text-copper:hover {
  background-color: #e4cbb3;
}
.table-hover .table-text-copper:hover > td,
.table-hover .table-text-copper:hover > th {
  background-color: #e4cbb3;
}

.table-light-hover,
.table-light-hover > th,
.table-light-hover > td {
  background-color: #fcfcfc;
}
.table-light-hover th,
.table-light-hover td,
.table-light-hover thead th,
.table-light-hover tbody + tbody {
  border-color: #f9f9f9;
}

.table-hover .table-light-hover:hover {
  background-color: #efefef;
}
.table-hover .table-light-hover:hover > td,
.table-hover .table-light-hover:hover > th {
  background-color: #efefef;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #dbd9d8;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #bcb9b6;
}

.table-hover .table-primary:hover {
  background-color: #cfcccb;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #cfcccb;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8b8b8;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-success:hover {
  background-color: #ababab;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #ababab;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #eaeaea;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #d7d7d7;
}

.table-hover .table-info:hover {
  background-color: #dddddd;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #dddddd;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ebd8c6;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #dab695;
}

.table-hover .table-warning:hover {
  background-color: #e4cbb3;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #e4cbb3;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #e2bfc0;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #c9888a;
}

.table-hover .table-danger:hover {
  background-color: #daadaf;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #daadaf;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfc;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f9f9f9;
}

.table-hover .table-light:hover {
  background-color: #efefef;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #efefef;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 479.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-family: "Neutraface Text Book", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #413e4a;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid black;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #413e4a;
  background-color: #ffffff;
  border-color: #bcb8b5;
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #413e4a;
}
select.form-control:focus::-ms-value {
  color: #413e4a;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #000000;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #000000;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23000000' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #000000;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #000000;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23000000' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #000000;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #000000;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #000000;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #000000;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #1a1a1a;
  background-color: #1a1a1a;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #000000;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #000000;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #000000;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #981b1e;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(152, 27, 30, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #981b1e;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23981b1e' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23981b1e' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #981b1e;
  box-shadow: 0 0 0 0.2rem rgba(152, 27, 30, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #981b1e;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23981b1e' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23981b1e' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #981b1e;
  box-shadow: 0 0 0 0.2rem rgba(152, 27, 30, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #981b1e;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #981b1e;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #981b1e;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #c32327;
  background-color: #c32327;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(152, 27, 30, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #981b1e;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #981b1e;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #981b1e;
  box-shadow: 0 0 0 0.2rem rgba(152, 27, 30, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 480px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-family: "Gotham Book", "Helvetica Neue", Helvetica, Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 3px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-text-color {
  color: #ffffff;
  background-color: #413e4a;
  border-color: #413e4a;
}
.btn-text-color:hover {
  color: #ffffff;
  background-color: #2f2d35;
  border-color: #29272e;
}
.btn-text-color:focus, .btn-text-color.focus {
  color: #ffffff;
  background-color: #2f2d35;
  border-color: #29272e;
  box-shadow: 0 0 0 0.2rem rgba(94, 91, 101, 0.5);
}
.btn-text-color.disabled, .btn-text-color:disabled {
  color: #ffffff;
  background-color: #413e4a;
  border-color: #413e4a;
}
.btn-text-color:not(:disabled):not(.disabled):active, .btn-text-color:not(:disabled):not(.disabled).active, .show > .btn-text-color.dropdown-toggle {
  color: #ffffff;
  background-color: #29272e;
  border-color: #232127;
}
.btn-text-color:not(:disabled):not(.disabled):active:focus, .btn-text-color:not(:disabled):not(.disabled).active:focus, .show > .btn-text-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(94, 91, 101, 0.5);
}

.btn-button-danger {
  color: #ffffff;
  background-color: #981b1e;
  border-color: #981b1e;
}
.btn-button-danger:hover {
  color: #ffffff;
  background-color: #781518;
  border-color: #6d1315;
}
.btn-button-danger:focus, .btn-button-danger.focus {
  color: #ffffff;
  background-color: #781518;
  border-color: #6d1315;
  box-shadow: 0 0 0 0.2rem rgba(167, 61, 64, 0.5);
}
.btn-button-danger.disabled, .btn-button-danger:disabled {
  color: #ffffff;
  background-color: #981b1e;
  border-color: #981b1e;
}
.btn-button-danger:not(:disabled):not(.disabled):active, .btn-button-danger:not(:disabled):not(.disabled).active, .show > .btn-button-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #6d1315;
  border-color: #621113;
}
.btn-button-danger:not(:disabled):not(.disabled):active:focus, .btn-button-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-button-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(167, 61, 64, 0.5);
}

.btn-label-color {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-label-color:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-label-color:focus, .btn-label-color.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-label-color.disabled, .btn-label-color:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-label-color:not(:disabled):not(.disabled):active, .btn-label-color:not(:disabled):not(.disabled).active, .show > .btn-label-color.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-label-color:not(:disabled):not(.disabled):active:focus, .btn-label-color:not(:disabled):not(.disabled).active:focus, .show > .btn-label-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-label-background-color {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-label-background-color:hover {
  color: #ffffff;
  background-color: #828282;
  border-color: #7c7c7c;
}
.btn-label-background-color:focus, .btn-label-background-color.focus {
  color: #ffffff;
  background-color: #828282;
  border-color: #7c7c7c;
  box-shadow: 0 0 0 0.2rem rgba(165, 165, 165, 0.5);
}
.btn-label-background-color.disabled, .btn-label-background-color:disabled {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-label-background-color:not(:disabled):not(.disabled):active, .btn-label-background-color:not(:disabled):not(.disabled).active, .show > .btn-label-background-color.dropdown-toggle {
  color: #ffffff;
  background-color: #7c7c7c;
  border-color: #757575;
}
.btn-label-background-color:not(:disabled):not(.disabled):active:focus, .btn-label-background-color:not(:disabled):not(.disabled).active:focus, .show > .btn-label-background-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(165, 165, 165, 0.5);
}

.btn-text-primary {
  color: #ffffff;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}
.btn-text-primary:hover {
  color: #ffffff;
  background-color: #070707;
  border-color: #010101;
}
.btn-text-primary:focus, .btn-text-primary.focus {
  color: #ffffff;
  background-color: #070707;
  border-color: #010101;
  box-shadow: 0 0 0 0.2rem rgba(60, 60, 60, 0.5);
}
.btn-text-primary.disabled, .btn-text-primary:disabled {
  color: #ffffff;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}
.btn-text-primary:not(:disabled):not(.disabled):active, .btn-text-primary:not(:disabled):not(.disabled).active, .show > .btn-text-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #010101;
  border-color: black;
}
.btn-text-primary:not(:disabled):not(.disabled):active:focus, .btn-text-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-text-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 60, 60, 0.5);
}

.btn-text-secondary {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-text-secondary:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-text-secondary:focus, .btn-text-secondary.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-text-secondary.disabled, .btn-text-secondary:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-text-secondary:not(:disabled):not(.disabled):active, .btn-text-secondary:not(:disabled):not(.disabled).active, .show > .btn-text-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-text-secondary:not(:disabled):not(.disabled):active:focus, .btn-text-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-text-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-text-tertiary {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-text-tertiary:hover {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
}
.btn-text-tertiary:focus, .btn-text-tertiary.focus {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}
.btn-text-tertiary.disabled, .btn-text-tertiary:disabled {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-text-tertiary:not(:disabled):not(.disabled):active, .btn-text-tertiary:not(:disabled):not(.disabled).active, .show > .btn-text-tertiary.dropdown-toggle {
  color: #212529;
  background-color: #d9d9d9;
  border-color: #d2d2d2;
}
.btn-text-tertiary:not(:disabled):not(.disabled):active:focus, .btn-text-tertiary:not(:disabled):not(.disabled).active:focus, .show > .btn-text-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}

.btn-text-black {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-text-black:hover {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-text-black:focus, .btn-text-black.focus {
  color: #ffffff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-text-black.disabled, .btn-text-black:disabled {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-text-black:not(:disabled):not(.disabled):active, .btn-text-black:not(:disabled):not(.disabled).active, .show > .btn-text-black.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-text-black:not(:disabled):not(.disabled):active:focus, .btn-text-black:not(:disabled):not(.disabled).active:focus, .show > .btn-text-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-text-black-background-color {
  color: #ffffff;
  background-color: #191919;
  border-color: #191919;
}
.btn-text-black-background-color:hover {
  color: #ffffff;
  background-color: #060606;
  border-color: black;
}
.btn-text-black-background-color:focus, .btn-text-black-background-color.focus {
  color: #ffffff;
  background-color: #060606;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(60, 60, 60, 0.5);
}
.btn-text-black-background-color.disabled, .btn-text-black-background-color:disabled {
  color: #ffffff;
  background-color: #191919;
  border-color: #191919;
}
.btn-text-black-background-color:not(:disabled):not(.disabled):active, .btn-text-black-background-color:not(:disabled):not(.disabled).active, .show > .btn-text-black-background-color.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-text-black-background-color:not(:disabled):not(.disabled):active:focus, .btn-text-black-background-color:not(:disabled):not(.disabled).active:focus, .show > .btn-text-black-background-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 60, 60, 0.5);
}

.btn-small-text-color {
  color: #ffffff;
  background-color: #191919;
  border-color: #191919;
}
.btn-small-text-color:hover {
  color: #ffffff;
  background-color: #060606;
  border-color: black;
}
.btn-small-text-color:focus, .btn-small-text-color.focus {
  color: #ffffff;
  background-color: #060606;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(60, 60, 60, 0.5);
}
.btn-small-text-color.disabled, .btn-small-text-color:disabled {
  color: #ffffff;
  background-color: #191919;
  border-color: #191919;
}
.btn-small-text-color:not(:disabled):not(.disabled):active, .btn-small-text-color:not(:disabled):not(.disabled).active, .show > .btn-small-text-color.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-small-text-color:not(:disabled):not(.disabled):active:focus, .btn-small-text-color:not(:disabled):not(.disabled).active:focus, .show > .btn-small-text-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 60, 60, 0.5);
}

.btn-td-dark-bg {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-td-dark-bg:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-td-dark-bg:focus, .btn-td-dark-bg.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-td-dark-bg.disabled, .btn-td-dark-bg:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-td-dark-bg:not(:disabled):not(.disabled):active, .btn-td-dark-bg:not(:disabled):not(.disabled).active, .show > .btn-td-dark-bg.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-td-dark-bg:not(:disabled):not(.disabled):active:focus, .btn-td-dark-bg:not(:disabled):not(.disabled).active:focus, .show > .btn-td-dark-bg.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-select-background-color {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-select-background-color:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-select-background-color:focus, .btn-select-background-color.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-select-background-color.disabled, .btn-select-background-color:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-select-background-color:not(:disabled):not(.disabled):active, .btn-select-background-color:not(:disabled):not(.disabled).active, .show > .btn-select-background-color.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-select-background-color:not(:disabled):not(.disabled):active:focus, .btn-select-background-color:not(:disabled):not(.disabled).active:focus, .show > .btn-select-background-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-text-neutral {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-text-neutral:hover {
  color: #ffffff;
  background-color: #828282;
  border-color: #7c7c7c;
}
.btn-text-neutral:focus, .btn-text-neutral.focus {
  color: #ffffff;
  background-color: #828282;
  border-color: #7c7c7c;
  box-shadow: 0 0 0 0.2rem rgba(165, 165, 165, 0.5);
}
.btn-text-neutral.disabled, .btn-text-neutral:disabled {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-text-neutral:not(:disabled):not(.disabled):active, .btn-text-neutral:not(:disabled):not(.disabled).active, .show > .btn-text-neutral.dropdown-toggle {
  color: #ffffff;
  background-color: #7c7c7c;
  border-color: #757575;
}
.btn-text-neutral:not(:disabled):not(.disabled):active:focus, .btn-text-neutral:not(:disabled):not(.disabled).active:focus, .show > .btn-text-neutral.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(165, 165, 165, 0.5);
}

.btn-text-disabled {
  color: #212529;
  background-color: #d3d3d3;
  border-color: #d3d3d3;
}
.btn-text-disabled:hover {
  color: #212529;
  background-color: silver;
  border-color: #bababa;
}
.btn-text-disabled:focus, .btn-text-disabled.focus {
  color: #212529;
  background-color: silver;
  border-color: #bababa;
  box-shadow: 0 0 0 0.2rem rgba(184, 185, 186, 0.5);
}
.btn-text-disabled.disabled, .btn-text-disabled:disabled {
  color: #212529;
  background-color: #d3d3d3;
  border-color: #d3d3d3;
}
.btn-text-disabled:not(:disabled):not(.disabled):active, .btn-text-disabled:not(:disabled):not(.disabled).active, .show > .btn-text-disabled.dropdown-toggle {
  color: #212529;
  background-color: #bababa;
  border-color: #b3b3b3;
}
.btn-text-disabled:not(:disabled):not(.disabled):active:focus, .btn-text-disabled:not(:disabled):not(.disabled).active:focus, .show > .btn-text-disabled.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 185, 186, 0.5);
}

.btn-text-active {
  color: #212529;
  background-color: #e5e1cf;
  border-color: #e5e1cf;
}
.btn-text-active:hover {
  color: #212529;
  background-color: #d8d1b6;
  border-color: #d3ccae;
}
.btn-text-active:focus, .btn-text-active.focus {
  color: #212529;
  background-color: #d8d1b6;
  border-color: #d3ccae;
  box-shadow: 0 0 0 0.2rem rgba(200, 197, 182, 0.5);
}
.btn-text-active.disabled, .btn-text-active:disabled {
  color: #212529;
  background-color: #e5e1cf;
  border-color: #e5e1cf;
}
.btn-text-active:not(:disabled):not(.disabled):active, .btn-text-active:not(:disabled):not(.disabled).active, .show > .btn-text-active.dropdown-toggle {
  color: #212529;
  background-color: #d3ccae;
  border-color: #cfc7a6;
}
.btn-text-active:not(:disabled):not(.disabled):active:focus, .btn-text-active:not(:disabled):not(.disabled).active:focus, .show > .btn-text-active.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 197, 182, 0.5);
}

.btn-text-hover {
  color: #ffffff;
  background-color: #787878;
  border-color: #787878;
}
.btn-text-hover:hover {
  color: #ffffff;
  background-color: #656565;
  border-color: #5f5f5f;
}
.btn-text-hover:focus, .btn-text-hover.focus {
  color: #ffffff;
  background-color: #656565;
  border-color: #5f5f5f;
  box-shadow: 0 0 0 0.2rem rgba(140, 140, 140, 0.5);
}
.btn-text-hover.disabled, .btn-text-hover:disabled {
  color: #ffffff;
  background-color: #787878;
  border-color: #787878;
}
.btn-text-hover:not(:disabled):not(.disabled):active, .btn-text-hover:not(:disabled):not(.disabled).active, .show > .btn-text-hover.dropdown-toggle {
  color: #ffffff;
  background-color: #5f5f5f;
  border-color: #585858;
}
.btn-text-hover:not(:disabled):not(.disabled):active:focus, .btn-text-hover:not(:disabled):not(.disabled).active:focus, .show > .btn-text-hover.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(140, 140, 140, 0.5);
}

.btn-text-menu-hover {
  color: #ffffff;
  background-color: #3f3b39;
  border-color: #3f3b39;
}
.btn-text-menu-hover:hover {
  color: #ffffff;
  background-color: #2b2827;
  border-color: #242221;
}
.btn-text-menu-hover:focus, .btn-text-menu-hover.focus {
  color: #ffffff;
  background-color: #2b2827;
  border-color: #242221;
  box-shadow: 0 0 0 0.2rem rgba(92, 88, 87, 0.5);
}
.btn-text-menu-hover.disabled, .btn-text-menu-hover:disabled {
  color: #ffffff;
  background-color: #3f3b39;
  border-color: #3f3b39;
}
.btn-text-menu-hover:not(:disabled):not(.disabled):active, .btn-text-menu-hover:not(:disabled):not(.disabled).active, .show > .btn-text-menu-hover.dropdown-toggle {
  color: #ffffff;
  background-color: #242221;
  border-color: #1e1c1b;
}
.btn-text-menu-hover:not(:disabled):not(.disabled):active:focus, .btn-text-menu-hover:not(:disabled):not(.disabled).active:focus, .show > .btn-text-menu-hover.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 88, 87, 0.5);
}

.btn-button-color {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-button-color:hover {
  color: #ffffff;
  background-color: #6b6561;
  border-color: #645f5b;
}
.btn-button-color:focus, .btn-button-color.focus {
  color: #ffffff;
  background-color: #6b6561;
  border-color: #645f5b;
  box-shadow: 0 0 0 0.2rem rgba(146, 140, 136, 0.5);
}
.btn-button-color.disabled, .btn-button-color:disabled {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-button-color:not(:disabled):not(.disabled):active, .btn-button-color:not(:disabled):not(.disabled).active, .show > .btn-button-color.dropdown-toggle {
  color: #ffffff;
  background-color: #645f5b;
  border-color: #5e5855;
}
.btn-button-color:not(:disabled):not(.disabled):active:focus, .btn-button-color:not(:disabled):not(.disabled).active:focus, .show > .btn-button-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 140, 136, 0.5);
}

.btn-button-background-color {
  color: #ffffff;
  background-color: #333333;
  border-color: #333333;
}
.btn-button-background-color:hover {
  color: #ffffff;
  background-color: #202020;
  border-color: #1a1a1a;
}
.btn-button-background-color:focus, .btn-button-background-color.focus {
  color: #ffffff;
  background-color: #202020;
  border-color: #1a1a1a;
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}
.btn-button-background-color.disabled, .btn-button-background-color:disabled {
  color: #ffffff;
  background-color: #333333;
  border-color: #333333;
}
.btn-button-background-color:not(:disabled):not(.disabled):active, .btn-button-background-color:not(:disabled):not(.disabled).active, .show > .btn-button-background-color.dropdown-toggle {
  color: #ffffff;
  background-color: #1a1a1a;
  border-color: #131313;
}
.btn-button-background-color:not(:disabled):not(.disabled):active:focus, .btn-button-background-color:not(:disabled):not(.disabled).active:focus, .show > .btn-button-background-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}

.btn-form-color-border {
  color: #ffffff;
  background-color: #7c746a;
  border-color: #7c746a;
}
.btn-form-color-border:hover {
  color: #ffffff;
  background-color: #676158;
  border-color: #615a52;
}
.btn-form-color-border:focus, .btn-form-color-border.focus {
  color: #ffffff;
  background-color: #676158;
  border-color: #615a52;
  box-shadow: 0 0 0 0.2rem rgba(144, 137, 128, 0.5);
}
.btn-form-color-border.disabled, .btn-form-color-border:disabled {
  color: #ffffff;
  background-color: #7c746a;
  border-color: #7c746a;
}
.btn-form-color-border:not(:disabled):not(.disabled):active, .btn-form-color-border:not(:disabled):not(.disabled).active, .show > .btn-form-color-border.dropdown-toggle {
  color: #ffffff;
  background-color: #615a52;
  border-color: #5a544d;
}
.btn-form-color-border:not(:disabled):not(.disabled):active:focus, .btn-form-color-border:not(:disabled):not(.disabled).active:focus, .show > .btn-form-color-border.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 137, 128, 0.5);
}

.btn-input-border-color {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-input-border-color:hover {
  color: #ffffff;
  background-color: #828282;
  border-color: #7c7c7c;
}
.btn-input-border-color:focus, .btn-input-border-color.focus {
  color: #ffffff;
  background-color: #828282;
  border-color: #7c7c7c;
  box-shadow: 0 0 0 0.2rem rgba(165, 165, 165, 0.5);
}
.btn-input-border-color.disabled, .btn-input-border-color:disabled {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-input-border-color:not(:disabled):not(.disabled):active, .btn-input-border-color:not(:disabled):not(.disabled).active, .show > .btn-input-border-color.dropdown-toggle {
  color: #ffffff;
  background-color: #7c7c7c;
  border-color: #757575;
}
.btn-input-border-color:not(:disabled):not(.disabled):active:focus, .btn-input-border-color:not(:disabled):not(.disabled).active:focus, .show > .btn-input-border-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(165, 165, 165, 0.5);
}

.btn-input-color-background {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-input-color-background:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-input-color-background:focus, .btn-input-color-background.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-input-color-background.disabled, .btn-input-color-background:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-input-color-background:not(:disabled):not(.disabled):active, .btn-input-color-background:not(:disabled):not(.disabled).active, .show > .btn-input-color-background.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-input-color-background:not(:disabled):not(.disabled):active:focus, .btn-input-color-background:not(:disabled):not(.disabled).active:focus, .show > .btn-input-color-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-chrome-picker-background {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-chrome-picker-background:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-chrome-picker-background:focus, .btn-chrome-picker-background.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-chrome-picker-background.disabled, .btn-chrome-picker-background:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-chrome-picker-background:not(:disabled):not(.disabled):active, .btn-chrome-picker-background:not(:disabled):not(.disabled).active, .show > .btn-chrome-picker-background.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-chrome-picker-background:not(:disabled):not(.disabled):active:focus, .btn-chrome-picker-background:not(:disabled):not(.disabled).active:focus, .show > .btn-chrome-picker-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-black {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-black:hover {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-black:focus, .btn-black.focus {
  color: #ffffff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-black.disabled, .btn-black:disabled {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active, .show > .btn-black.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus, .show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-input-color-text {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-input-color-text:hover {
  color: #ffffff;
  background-color: #6b6561;
  border-color: #645f5b;
}
.btn-input-color-text:focus, .btn-input-color-text.focus {
  color: #ffffff;
  background-color: #6b6561;
  border-color: #645f5b;
  box-shadow: 0 0 0 0.2rem rgba(146, 140, 136, 0.5);
}
.btn-input-color-text.disabled, .btn-input-color-text:disabled {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-input-color-text:not(:disabled):not(.disabled):active, .btn-input-color-text:not(:disabled):not(.disabled).active, .show > .btn-input-color-text.dropdown-toggle {
  color: #ffffff;
  background-color: #645f5b;
  border-color: #5e5855;
}
.btn-input-color-text:not(:disabled):not(.disabled):active:focus, .btn-input-color-text:not(:disabled):not(.disabled).active:focus, .show > .btn-input-color-text.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 140, 136, 0.5);
}

.btn-background-gray {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-background-gray:hover {
  color: #ffffff;
  background-color: #6b6561;
  border-color: #645f5b;
}
.btn-background-gray:focus, .btn-background-gray.focus {
  color: #ffffff;
  background-color: #6b6561;
  border-color: #645f5b;
  box-shadow: 0 0 0 0.2rem rgba(146, 140, 136, 0.5);
}
.btn-background-gray.disabled, .btn-background-gray:disabled {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-background-gray:not(:disabled):not(.disabled):active, .btn-background-gray:not(:disabled):not(.disabled).active, .show > .btn-background-gray.dropdown-toggle {
  color: #ffffff;
  background-color: #645f5b;
  border-color: #5e5855;
}
.btn-background-gray:not(:disabled):not(.disabled):active:focus, .btn-background-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-background-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 140, 136, 0.5);
}

.btn-background-light-black {
  color: #ffffff;
  background-color: #3f3b39;
  border-color: #3f3b39;
}
.btn-background-light-black:hover {
  color: #ffffff;
  background-color: #2b2827;
  border-color: #242221;
}
.btn-background-light-black:focus, .btn-background-light-black.focus {
  color: #ffffff;
  background-color: #2b2827;
  border-color: #242221;
  box-shadow: 0 0 0 0.2rem rgba(92, 88, 87, 0.5);
}
.btn-background-light-black.disabled, .btn-background-light-black:disabled {
  color: #ffffff;
  background-color: #3f3b39;
  border-color: #3f3b39;
}
.btn-background-light-black:not(:disabled):not(.disabled):active, .btn-background-light-black:not(:disabled):not(.disabled).active, .show > .btn-background-light-black.dropdown-toggle {
  color: #ffffff;
  background-color: #242221;
  border-color: #1e1c1b;
}
.btn-background-light-black:not(:disabled):not(.disabled):active:focus, .btn-background-light-black:not(:disabled):not(.disabled).active:focus, .show > .btn-background-light-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 88, 87, 0.5);
}

.btn-input-color-svg {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-input-color-svg:hover {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-input-color-svg:focus, .btn-input-color-svg.focus {
  color: #ffffff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-input-color-svg.disabled, .btn-input-color-svg:disabled {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-input-color-svg:not(:disabled):not(.disabled):active, .btn-input-color-svg:not(:disabled):not(.disabled).active, .show > .btn-input-color-svg.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-input-color-svg:not(:disabled):not(.disabled):active:focus, .btn-input-color-svg:not(:disabled):not(.disabled).active:focus, .show > .btn-input-color-svg.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-border-color-gray {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-border-color-gray:hover {
  color: #ffffff;
  background-color: #828282;
  border-color: #7c7c7c;
}
.btn-border-color-gray:focus, .btn-border-color-gray.focus {
  color: #ffffff;
  background-color: #828282;
  border-color: #7c7c7c;
  box-shadow: 0 0 0 0.2rem rgba(165, 165, 165, 0.5);
}
.btn-border-color-gray.disabled, .btn-border-color-gray:disabled {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-border-color-gray:not(:disabled):not(.disabled):active, .btn-border-color-gray:not(:disabled):not(.disabled).active, .show > .btn-border-color-gray.dropdown-toggle {
  color: #ffffff;
  background-color: #7c7c7c;
  border-color: #757575;
}
.btn-border-color-gray:not(:disabled):not(.disabled):active:focus, .btn-border-color-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-border-color-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(165, 165, 165, 0.5);
}

.btn-light-background {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light-background:hover {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
}
.btn-light-background:focus, .btn-light-background.focus {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}
.btn-light-background.disabled, .btn-light-background:disabled {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light-background:not(:disabled):not(.disabled):active, .btn-light-background:not(:disabled):not(.disabled).active, .show > .btn-light-background.dropdown-toggle {
  color: #212529;
  background-color: #d9d9d9;
  border-color: #d2d2d2;
}
.btn-light-background:not(:disabled):not(.disabled):active:focus, .btn-light-background:not(:disabled):not(.disabled).active:focus, .show > .btn-light-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}

.btn-input-img-background {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-input-img-background:hover {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
}
.btn-input-img-background:focus, .btn-input-img-background.focus {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}
.btn-input-img-background.disabled, .btn-input-img-background:disabled {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-input-img-background:not(:disabled):not(.disabled):active, .btn-input-img-background:not(:disabled):not(.disabled).active, .show > .btn-input-img-background.dropdown-toggle {
  color: #212529;
  background-color: #d9d9d9;
  border-color: #d2d2d2;
}
.btn-input-img-background:not(:disabled):not(.disabled):active:focus, .btn-input-img-background:not(:disabled):not(.disabled).active:focus, .show > .btn-input-img-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}

.btn-td-btn-background {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-td-btn-background:hover {
  color: #ffffff;
  background-color: #828282;
  border-color: #7c7c7c;
}
.btn-td-btn-background:focus, .btn-td-btn-background.focus {
  color: #ffffff;
  background-color: #828282;
  border-color: #7c7c7c;
  box-shadow: 0 0 0 0.2rem rgba(165, 165, 165, 0.5);
}
.btn-td-btn-background.disabled, .btn-td-btn-background:disabled {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-td-btn-background:not(:disabled):not(.disabled):active, .btn-td-btn-background:not(:disabled):not(.disabled).active, .show > .btn-td-btn-background.dropdown-toggle {
  color: #ffffff;
  background-color: #7c7c7c;
  border-color: #757575;
}
.btn-td-btn-background:not(:disabled):not(.disabled):active:focus, .btn-td-btn-background:not(:disabled):not(.disabled).active:focus, .show > .btn-td-btn-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(165, 165, 165, 0.5);
}

.btn-td-btn-disable-background {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-td-btn-disable-background:hover {
  color: #ffffff;
  background-color: #828282;
  border-color: #7c7c7c;
}
.btn-td-btn-disable-background:focus, .btn-td-btn-disable-background.focus {
  color: #ffffff;
  background-color: #828282;
  border-color: #7c7c7c;
  box-shadow: 0 0 0 0.2rem rgba(165, 165, 165, 0.5);
}
.btn-td-btn-disable-background.disabled, .btn-td-btn-disable-background:disabled {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-td-btn-disable-background:not(:disabled):not(.disabled):active, .btn-td-btn-disable-background:not(:disabled):not(.disabled).active, .show > .btn-td-btn-disable-background.dropdown-toggle {
  color: #ffffff;
  background-color: #7c7c7c;
  border-color: #757575;
}
.btn-td-btn-disable-background:not(:disabled):not(.disabled):active:focus, .btn-td-btn-disable-background:not(:disabled):not(.disabled).active:focus, .show > .btn-td-btn-disable-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(165, 165, 165, 0.5);
}

.btn-td-btn-icon {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-td-btn-icon:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-td-btn-icon:focus, .btn-td-btn-icon.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-td-btn-icon.disabled, .btn-td-btn-icon:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-td-btn-icon:not(:disabled):not(.disabled):active, .btn-td-btn-icon:not(:disabled):not(.disabled).active, .show > .btn-td-btn-icon.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-td-btn-icon:not(:disabled):not(.disabled):active:focus, .btn-td-btn-icon:not(:disabled):not(.disabled).active:focus, .show > .btn-td-btn-icon.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-tr-active {
  color: #212529;
  background-color: #e5e4e3;
  border-color: #e5e4e3;
}
.btn-tr-active:hover {
  color: #212529;
  background-color: #d3d1cf;
  border-color: #cccbc9;
}
.btn-tr-active:focus, .btn-tr-active.focus {
  color: #212529;
  background-color: #d3d1cf;
  border-color: #cccbc9;
  box-shadow: 0 0 0 0.2rem rgba(200, 199, 199, 0.5);
}
.btn-tr-active.disabled, .btn-tr-active:disabled {
  color: #212529;
  background-color: #e5e4e3;
  border-color: #e5e4e3;
}
.btn-tr-active:not(:disabled):not(.disabled):active, .btn-tr-active:not(:disabled):not(.disabled).active, .show > .btn-tr-active.dropdown-toggle {
  color: #212529;
  background-color: #cccbc9;
  border-color: #c6c4c2;
}
.btn-tr-active:not(:disabled):not(.disabled):active:focus, .btn-tr-active:not(:disabled):not(.disabled).active:focus, .show > .btn-tr-active.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 199, 199, 0.5);
}

.btn-login-text-color {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5019607843);
  border-color: rgba(0, 0, 0, 0.5019607843);
}
.btn-login-text-color:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5019607843);
  border-color: rgba(0, 0, 0, 0.5019607843);
}
.btn-login-text-color:focus, .btn-login-text-color.focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5019607843);
  border-color: rgba(0, 0, 0, 0.5019607843);
  box-shadow: 0 0 0 0.2rem rgba(88, 88, 88, 0.5);
}
.btn-login-text-color.disabled, .btn-login-text-color:disabled {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5019607843);
  border-color: rgba(0, 0, 0, 0.5019607843);
}
.btn-login-text-color:not(:disabled):not(.disabled):active, .btn-login-text-color:not(:disabled):not(.disabled).active, .show > .btn-login-text-color.dropdown-toggle {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5019607843);
  border-color: rgba(0, 0, 0, 0.5019607843);
}
.btn-login-text-color:not(:disabled):not(.disabled):active:focus, .btn-login-text-color:not(:disabled):not(.disabled).active:focus, .show > .btn-login-text-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 88, 88, 0.5);
}

.btn-tan-color-two {
  color: #ffffff;
  background-color: #3f3b39;
  border-color: #3f3b39;
}
.btn-tan-color-two:hover {
  color: #ffffff;
  background-color: #2b2827;
  border-color: #242221;
}
.btn-tan-color-two:focus, .btn-tan-color-two.focus {
  color: #ffffff;
  background-color: #2b2827;
  border-color: #242221;
  box-shadow: 0 0 0 0.2rem rgba(92, 88, 87, 0.5);
}
.btn-tan-color-two.disabled, .btn-tan-color-two:disabled {
  color: #ffffff;
  background-color: #3f3b39;
  border-color: #3f3b39;
}
.btn-tan-color-two:not(:disabled):not(.disabled):active, .btn-tan-color-two:not(:disabled):not(.disabled).active, .show > .btn-tan-color-two.dropdown-toggle {
  color: #ffffff;
  background-color: #242221;
  border-color: #1e1c1b;
}
.btn-tan-color-two:not(:disabled):not(.disabled):active:focus, .btn-tan-color-two:not(:disabled):not(.disabled).active:focus, .show > .btn-tan-color-two.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 88, 87, 0.5);
}

.btn-tan-color-three {
  color: #212529;
  background-color: #f2eeeb;
  border-color: #f2eeeb;
}
.btn-tan-color-three:hover {
  color: #212529;
  background-color: #e3dad4;
  border-color: #ded4cc;
}
.btn-tan-color-three:focus, .btn-tan-color-three.focus {
  color: #212529;
  background-color: #e3dad4;
  border-color: #ded4cc;
  box-shadow: 0 0 0 0.2rem rgba(211, 208, 206, 0.5);
}
.btn-tan-color-three.disabled, .btn-tan-color-three:disabled {
  color: #212529;
  background-color: #f2eeeb;
  border-color: #f2eeeb;
}
.btn-tan-color-three:not(:disabled):not(.disabled):active, .btn-tan-color-three:not(:disabled):not(.disabled).active, .show > .btn-tan-color-three.dropdown-toggle {
  color: #212529;
  background-color: #ded4cc;
  border-color: #d9cdc4;
}
.btn-tan-color-three:not(:disabled):not(.disabled):active:focus, .btn-tan-color-three:not(:disabled):not(.disabled).active:focus, .show > .btn-tan-color-three.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 208, 206, 0.5);
}

.btn-gray-color-two {
  color: #212529;
  background-color: #b3b3b3;
  border-color: #b3b3b3;
}
.btn-gray-color-two:hover {
  color: #212529;
  background-color: #a0a0a0;
  border-color: #9a9a9a;
}
.btn-gray-color-two:focus, .btn-gray-color-two.focus {
  color: #212529;
  background-color: #a0a0a0;
  border-color: #9a9a9a;
  box-shadow: 0 0 0 0.2rem rgba(157, 158, 158, 0.5);
}
.btn-gray-color-two.disabled, .btn-gray-color-two:disabled {
  color: #212529;
  background-color: #b3b3b3;
  border-color: #b3b3b3;
}
.btn-gray-color-two:not(:disabled):not(.disabled):active, .btn-gray-color-two:not(:disabled):not(.disabled).active, .show > .btn-gray-color-two.dropdown-toggle {
  color: #212529;
  background-color: #9a9a9a;
  border-color: #939393;
}
.btn-gray-color-two:not(:disabled):not(.disabled):active:focus, .btn-gray-color-two:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-color-two.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 158, 158, 0.5);
}

.btn-btn-copper {
  color: #ffffff;
  background-color: #b87333;
  border-color: #b87333;
}
.btn-btn-copper:hover {
  color: #ffffff;
  background-color: #9a602b;
  border-color: #905a28;
}
.btn-btn-copper:focus, .btn-btn-copper.focus {
  color: #ffffff;
  background-color: #9a602b;
  border-color: #905a28;
  box-shadow: 0 0 0 0.2rem rgba(195, 136, 82, 0.5);
}
.btn-btn-copper.disabled, .btn-btn-copper:disabled {
  color: #ffffff;
  background-color: #b87333;
  border-color: #b87333;
}
.btn-btn-copper:not(:disabled):not(.disabled):active, .btn-btn-copper:not(:disabled):not(.disabled).active, .show > .btn-btn-copper.dropdown-toggle {
  color: #ffffff;
  background-color: #905a28;
  border-color: #865425;
}
.btn-btn-copper:not(:disabled):not(.disabled):active:focus, .btn-btn-copper:not(:disabled):not(.disabled).active:focus, .show > .btn-btn-copper.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 136, 82, 0.5);
}

.btn-text-copper {
  color: #ffffff;
  background-color: #b87333;
  border-color: #b87333;
}
.btn-text-copper:hover {
  color: #ffffff;
  background-color: #9a602b;
  border-color: #905a28;
}
.btn-text-copper:focus, .btn-text-copper.focus {
  color: #ffffff;
  background-color: #9a602b;
  border-color: #905a28;
  box-shadow: 0 0 0 0.2rem rgba(195, 136, 82, 0.5);
}
.btn-text-copper.disabled, .btn-text-copper:disabled {
  color: #ffffff;
  background-color: #b87333;
  border-color: #b87333;
}
.btn-text-copper:not(:disabled):not(.disabled):active, .btn-text-copper:not(:disabled):not(.disabled).active, .show > .btn-text-copper.dropdown-toggle {
  color: #ffffff;
  background-color: #905a28;
  border-color: #865425;
}
.btn-text-copper:not(:disabled):not(.disabled):active:focus, .btn-text-copper:not(:disabled):not(.disabled).active:focus, .show > .btn-text-copper.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 136, 82, 0.5);
}

.btn-light-hover {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-light-hover:hover {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
}
.btn-light-hover:focus, .btn-light-hover.focus {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
  box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5);
}
.btn-light-hover.disabled, .btn-light-hover:disabled {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-light-hover:not(:disabled):not(.disabled):active, .btn-light-hover:not(:disabled):not(.disabled).active, .show > .btn-light-hover.dropdown-toggle {
  color: #212529;
  background-color: #dbdbdb;
  border-color: #d4d4d4;
}
.btn-light-hover:not(:disabled):not(.disabled):active:focus, .btn-light-hover:not(:disabled):not(.disabled).active:focus, .show > .btn-light-hover.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5);
}

.btn-primary {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #6b6561;
  border-color: #645f5b;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #6b6561;
  border-color: #645f5b;
  box-shadow: 0 0 0 0.2rem rgba(146, 140, 136, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #645f5b;
  border-color: #5e5855;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 140, 136, 0.5);
}

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-success:hover {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #b3b3b3;
  border-color: #b3b3b3;
}
.btn-info:hover {
  color: #212529;
  background-color: #a0a0a0;
  border-color: #9a9a9a;
}
.btn-info:focus, .btn-info.focus {
  color: #212529;
  background-color: #a0a0a0;
  border-color: #9a9a9a;
  box-shadow: 0 0 0 0.2rem rgba(157, 158, 158, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #b3b3b3;
  border-color: #b3b3b3;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #212529;
  background-color: #9a9a9a;
  border-color: #939393;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 158, 158, 0.5);
}

.btn-warning {
  color: #ffffff;
  background-color: #b87333;
  border-color: #b87333;
}
.btn-warning:hover {
  color: #ffffff;
  background-color: #9a602b;
  border-color: #905a28;
}
.btn-warning:focus, .btn-warning.focus {
  color: #ffffff;
  background-color: #9a602b;
  border-color: #905a28;
  box-shadow: 0 0 0 0.2rem rgba(195, 136, 82, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #ffffff;
  background-color: #b87333;
  border-color: #b87333;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #905a28;
  border-color: #865425;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 136, 82, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #981b1e;
  border-color: #981b1e;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #781518;
  border-color: #6d1315;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #781518;
  border-color: #6d1315;
  box-shadow: 0 0 0 0.2rem rgba(167, 61, 64, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #981b1e;
  border-color: #981b1e;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #6d1315;
  border-color: #621113;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(167, 61, 64, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-light:hover {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
  box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dbdbdb;
  border-color: #d4d4d4;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-text-color {
  color: #413e4a;
  border-color: #413e4a;
}
.btn-outline-text-color:hover {
  color: #ffffff;
  background-color: #413e4a;
  border-color: #413e4a;
}
.btn-outline-text-color:focus, .btn-outline-text-color.focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 62, 74, 0.5);
}
.btn-outline-text-color.disabled, .btn-outline-text-color:disabled {
  color: #413e4a;
  background-color: transparent;
}
.btn-outline-text-color:not(:disabled):not(.disabled):active, .btn-outline-text-color:not(:disabled):not(.disabled).active, .show > .btn-outline-text-color.dropdown-toggle {
  color: #ffffff;
  background-color: #413e4a;
  border-color: #413e4a;
}
.btn-outline-text-color:not(:disabled):not(.disabled):active:focus, .btn-outline-text-color:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-text-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 62, 74, 0.5);
}

.btn-outline-button-danger {
  color: #981b1e;
  border-color: #981b1e;
}
.btn-outline-button-danger:hover {
  color: #ffffff;
  background-color: #981b1e;
  border-color: #981b1e;
}
.btn-outline-button-danger:focus, .btn-outline-button-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(152, 27, 30, 0.5);
}
.btn-outline-button-danger.disabled, .btn-outline-button-danger:disabled {
  color: #981b1e;
  background-color: transparent;
}
.btn-outline-button-danger:not(:disabled):not(.disabled):active, .btn-outline-button-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-button-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #981b1e;
  border-color: #981b1e;
}
.btn-outline-button-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-button-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-button-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(152, 27, 30, 0.5);
}

.btn-outline-label-color {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-label-color:hover {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-label-color:focus, .btn-outline-label-color.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-label-color.disabled, .btn-outline-label-color:disabled {
  color: #ffffff;
  background-color: transparent;
}
.btn-outline-label-color:not(:disabled):not(.disabled):active, .btn-outline-label-color:not(:disabled):not(.disabled).active, .show > .btn-outline-label-color.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-label-color:not(:disabled):not(.disabled):active:focus, .btn-outline-label-color:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-label-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-label-background-color {
  color: #959595;
  border-color: #959595;
}
.btn-outline-label-background-color:hover {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-outline-label-background-color:focus, .btn-outline-label-background-color.focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}
.btn-outline-label-background-color.disabled, .btn-outline-label-background-color:disabled {
  color: #959595;
  background-color: transparent;
}
.btn-outline-label-background-color:not(:disabled):not(.disabled):active, .btn-outline-label-background-color:not(:disabled):not(.disabled).active, .show > .btn-outline-label-background-color.dropdown-toggle {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-outline-label-background-color:not(:disabled):not(.disabled):active:focus, .btn-outline-label-background-color:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-label-background-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}

.btn-outline-text-primary {
  color: #1a1a1a;
  border-color: #1a1a1a;
}
.btn-outline-text-primary:hover {
  color: #ffffff;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}
.btn-outline-text-primary:focus, .btn-outline-text-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 26, 26, 0.5);
}
.btn-outline-text-primary.disabled, .btn-outline-text-primary:disabled {
  color: #1a1a1a;
  background-color: transparent;
}
.btn-outline-text-primary:not(:disabled):not(.disabled):active, .btn-outline-text-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-text-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}
.btn-outline-text-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-text-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-text-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 26, 26, 0.5);
}

.btn-outline-text-secondary {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-text-secondary:hover {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-text-secondary:focus, .btn-outline-text-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-text-secondary.disabled, .btn-outline-text-secondary:disabled {
  color: #ffffff;
  background-color: transparent;
}
.btn-outline-text-secondary:not(:disabled):not(.disabled):active, .btn-outline-text-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-text-secondary.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-text-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-text-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-text-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-text-tertiary {
  color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-text-tertiary:hover {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-text-tertiary:focus, .btn-outline-text-tertiary.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}
.btn-outline-text-tertiary.disabled, .btn-outline-text-tertiary:disabled {
  color: #f2f2f2;
  background-color: transparent;
}
.btn-outline-text-tertiary:not(:disabled):not(.disabled):active, .btn-outline-text-tertiary:not(:disabled):not(.disabled).active, .show > .btn-outline-text-tertiary.dropdown-toggle {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-text-tertiary:not(:disabled):not(.disabled):active:focus, .btn-outline-text-tertiary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-text-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.btn-outline-text-black {
  color: #000000;
  border-color: #000000;
}
.btn-outline-text-black:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-text-black:focus, .btn-outline-text-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-text-black.disabled, .btn-outline-text-black:disabled {
  color: #000000;
  background-color: transparent;
}
.btn-outline-text-black:not(:disabled):not(.disabled):active, .btn-outline-text-black:not(:disabled):not(.disabled).active, .show > .btn-outline-text-black.dropdown-toggle {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-text-black:not(:disabled):not(.disabled):active:focus, .btn-outline-text-black:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-text-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-text-black-background-color {
  color: #191919;
  border-color: #191919;
}
.btn-outline-text-black-background-color:hover {
  color: #ffffff;
  background-color: #191919;
  border-color: #191919;
}
.btn-outline-text-black-background-color:focus, .btn-outline-text-black-background-color.focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 25, 25, 0.5);
}
.btn-outline-text-black-background-color.disabled, .btn-outline-text-black-background-color:disabled {
  color: #191919;
  background-color: transparent;
}
.btn-outline-text-black-background-color:not(:disabled):not(.disabled):active, .btn-outline-text-black-background-color:not(:disabled):not(.disabled).active, .show > .btn-outline-text-black-background-color.dropdown-toggle {
  color: #ffffff;
  background-color: #191919;
  border-color: #191919;
}
.btn-outline-text-black-background-color:not(:disabled):not(.disabled):active:focus, .btn-outline-text-black-background-color:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-text-black-background-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 25, 25, 0.5);
}

.btn-outline-small-text-color {
  color: #191919;
  border-color: #191919;
}
.btn-outline-small-text-color:hover {
  color: #ffffff;
  background-color: #191919;
  border-color: #191919;
}
.btn-outline-small-text-color:focus, .btn-outline-small-text-color.focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 25, 25, 0.5);
}
.btn-outline-small-text-color.disabled, .btn-outline-small-text-color:disabled {
  color: #191919;
  background-color: transparent;
}
.btn-outline-small-text-color:not(:disabled):not(.disabled):active, .btn-outline-small-text-color:not(:disabled):not(.disabled).active, .show > .btn-outline-small-text-color.dropdown-toggle {
  color: #ffffff;
  background-color: #191919;
  border-color: #191919;
}
.btn-outline-small-text-color:not(:disabled):not(.disabled):active:focus, .btn-outline-small-text-color:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-small-text-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 25, 25, 0.5);
}

.btn-outline-td-dark-bg {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-td-dark-bg:hover {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-td-dark-bg:focus, .btn-outline-td-dark-bg.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-td-dark-bg.disabled, .btn-outline-td-dark-bg:disabled {
  color: #ffffff;
  background-color: transparent;
}
.btn-outline-td-dark-bg:not(:disabled):not(.disabled):active, .btn-outline-td-dark-bg:not(:disabled):not(.disabled).active, .show > .btn-outline-td-dark-bg.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-td-dark-bg:not(:disabled):not(.disabled):active:focus, .btn-outline-td-dark-bg:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-td-dark-bg.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-select-background-color {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-select-background-color:hover {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-select-background-color:focus, .btn-outline-select-background-color.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-select-background-color.disabled, .btn-outline-select-background-color:disabled {
  color: #ffffff;
  background-color: transparent;
}
.btn-outline-select-background-color:not(:disabled):not(.disabled):active, .btn-outline-select-background-color:not(:disabled):not(.disabled).active, .show > .btn-outline-select-background-color.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-select-background-color:not(:disabled):not(.disabled):active:focus, .btn-outline-select-background-color:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-select-background-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-text-neutral {
  color: #959595;
  border-color: #959595;
}
.btn-outline-text-neutral:hover {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-outline-text-neutral:focus, .btn-outline-text-neutral.focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}
.btn-outline-text-neutral.disabled, .btn-outline-text-neutral:disabled {
  color: #959595;
  background-color: transparent;
}
.btn-outline-text-neutral:not(:disabled):not(.disabled):active, .btn-outline-text-neutral:not(:disabled):not(.disabled).active, .show > .btn-outline-text-neutral.dropdown-toggle {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-outline-text-neutral:not(:disabled):not(.disabled):active:focus, .btn-outline-text-neutral:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-text-neutral.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}

.btn-outline-text-disabled {
  color: #d3d3d3;
  border-color: #d3d3d3;
}
.btn-outline-text-disabled:hover {
  color: #212529;
  background-color: #d3d3d3;
  border-color: #d3d3d3;
}
.btn-outline-text-disabled:focus, .btn-outline-text-disabled.focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 211, 0.5);
}
.btn-outline-text-disabled.disabled, .btn-outline-text-disabled:disabled {
  color: #d3d3d3;
  background-color: transparent;
}
.btn-outline-text-disabled:not(:disabled):not(.disabled):active, .btn-outline-text-disabled:not(:disabled):not(.disabled).active, .show > .btn-outline-text-disabled.dropdown-toggle {
  color: #212529;
  background-color: #d3d3d3;
  border-color: #d3d3d3;
}
.btn-outline-text-disabled:not(:disabled):not(.disabled):active:focus, .btn-outline-text-disabled:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-text-disabled.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 211, 0.5);
}

.btn-outline-text-active {
  color: #e5e1cf;
  border-color: #e5e1cf;
}
.btn-outline-text-active:hover {
  color: #212529;
  background-color: #e5e1cf;
  border-color: #e5e1cf;
}
.btn-outline-text-active:focus, .btn-outline-text-active.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 225, 207, 0.5);
}
.btn-outline-text-active.disabled, .btn-outline-text-active:disabled {
  color: #e5e1cf;
  background-color: transparent;
}
.btn-outline-text-active:not(:disabled):not(.disabled):active, .btn-outline-text-active:not(:disabled):not(.disabled).active, .show > .btn-outline-text-active.dropdown-toggle {
  color: #212529;
  background-color: #e5e1cf;
  border-color: #e5e1cf;
}
.btn-outline-text-active:not(:disabled):not(.disabled):active:focus, .btn-outline-text-active:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-text-active.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 225, 207, 0.5);
}

.btn-outline-text-hover {
  color: #787878;
  border-color: #787878;
}
.btn-outline-text-hover:hover {
  color: #ffffff;
  background-color: #787878;
  border-color: #787878;
}
.btn-outline-text-hover:focus, .btn-outline-text-hover.focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 120, 120, 0.5);
}
.btn-outline-text-hover.disabled, .btn-outline-text-hover:disabled {
  color: #787878;
  background-color: transparent;
}
.btn-outline-text-hover:not(:disabled):not(.disabled):active, .btn-outline-text-hover:not(:disabled):not(.disabled).active, .show > .btn-outline-text-hover.dropdown-toggle {
  color: #ffffff;
  background-color: #787878;
  border-color: #787878;
}
.btn-outline-text-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-text-hover:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-text-hover.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 120, 120, 0.5);
}

.btn-outline-text-menu-hover {
  color: #3f3b39;
  border-color: #3f3b39;
}
.btn-outline-text-menu-hover:hover {
  color: #ffffff;
  background-color: #3f3b39;
  border-color: #3f3b39;
}
.btn-outline-text-menu-hover:focus, .btn-outline-text-menu-hover.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 59, 57, 0.5);
}
.btn-outline-text-menu-hover.disabled, .btn-outline-text-menu-hover:disabled {
  color: #3f3b39;
  background-color: transparent;
}
.btn-outline-text-menu-hover:not(:disabled):not(.disabled):active, .btn-outline-text-menu-hover:not(:disabled):not(.disabled).active, .show > .btn-outline-text-menu-hover.dropdown-toggle {
  color: #ffffff;
  background-color: #3f3b39;
  border-color: #3f3b39;
}
.btn-outline-text-menu-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-text-menu-hover:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-text-menu-hover.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 59, 57, 0.5);
}

.btn-outline-button-color {
  color: #7f7873;
  border-color: #7f7873;
}
.btn-outline-button-color:hover {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-outline-button-color:focus, .btn-outline-button-color.focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 120, 115, 0.5);
}
.btn-outline-button-color.disabled, .btn-outline-button-color:disabled {
  color: #7f7873;
  background-color: transparent;
}
.btn-outline-button-color:not(:disabled):not(.disabled):active, .btn-outline-button-color:not(:disabled):not(.disabled).active, .show > .btn-outline-button-color.dropdown-toggle {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-outline-button-color:not(:disabled):not(.disabled):active:focus, .btn-outline-button-color:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-button-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 120, 115, 0.5);
}

.btn-outline-button-background-color {
  color: #333333;
  border-color: #333333;
}
.btn-outline-button-background-color:hover {
  color: #ffffff;
  background-color: #333333;
  border-color: #333333;
}
.btn-outline-button-background-color:focus, .btn-outline-button-background-color.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}
.btn-outline-button-background-color.disabled, .btn-outline-button-background-color:disabled {
  color: #333333;
  background-color: transparent;
}
.btn-outline-button-background-color:not(:disabled):not(.disabled):active, .btn-outline-button-background-color:not(:disabled):not(.disabled).active, .show > .btn-outline-button-background-color.dropdown-toggle {
  color: #ffffff;
  background-color: #333333;
  border-color: #333333;
}
.btn-outline-button-background-color:not(:disabled):not(.disabled):active:focus, .btn-outline-button-background-color:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-button-background-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.btn-outline-form-color-border {
  color: #7c746a;
  border-color: #7c746a;
}
.btn-outline-form-color-border:hover {
  color: #ffffff;
  background-color: #7c746a;
  border-color: #7c746a;
}
.btn-outline-form-color-border:focus, .btn-outline-form-color-border.focus {
  box-shadow: 0 0 0 0.2rem rgba(124, 116, 106, 0.5);
}
.btn-outline-form-color-border.disabled, .btn-outline-form-color-border:disabled {
  color: #7c746a;
  background-color: transparent;
}
.btn-outline-form-color-border:not(:disabled):not(.disabled):active, .btn-outline-form-color-border:not(:disabled):not(.disabled).active, .show > .btn-outline-form-color-border.dropdown-toggle {
  color: #ffffff;
  background-color: #7c746a;
  border-color: #7c746a;
}
.btn-outline-form-color-border:not(:disabled):not(.disabled):active:focus, .btn-outline-form-color-border:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-form-color-border.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(124, 116, 106, 0.5);
}

.btn-outline-input-border-color {
  color: #959595;
  border-color: #959595;
}
.btn-outline-input-border-color:hover {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-outline-input-border-color:focus, .btn-outline-input-border-color.focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}
.btn-outline-input-border-color.disabled, .btn-outline-input-border-color:disabled {
  color: #959595;
  background-color: transparent;
}
.btn-outline-input-border-color:not(:disabled):not(.disabled):active, .btn-outline-input-border-color:not(:disabled):not(.disabled).active, .show > .btn-outline-input-border-color.dropdown-toggle {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-outline-input-border-color:not(:disabled):not(.disabled):active:focus, .btn-outline-input-border-color:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-input-border-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}

.btn-outline-input-color-background {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-input-color-background:hover {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-input-color-background:focus, .btn-outline-input-color-background.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-input-color-background.disabled, .btn-outline-input-color-background:disabled {
  color: #ffffff;
  background-color: transparent;
}
.btn-outline-input-color-background:not(:disabled):not(.disabled):active, .btn-outline-input-color-background:not(:disabled):not(.disabled).active, .show > .btn-outline-input-color-background.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-input-color-background:not(:disabled):not(.disabled):active:focus, .btn-outline-input-color-background:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-input-color-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-chrome-picker-background {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-chrome-picker-background:hover {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-chrome-picker-background:focus, .btn-outline-chrome-picker-background.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-chrome-picker-background.disabled, .btn-outline-chrome-picker-background:disabled {
  color: #ffffff;
  background-color: transparent;
}
.btn-outline-chrome-picker-background:not(:disabled):not(.disabled):active, .btn-outline-chrome-picker-background:not(:disabled):not(.disabled).active, .show > .btn-outline-chrome-picker-background.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-chrome-picker-background:not(:disabled):not(.disabled):active:focus, .btn-outline-chrome-picker-background:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-chrome-picker-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-black {
  color: #000000;
  border-color: #000000;
}
.btn-outline-black:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-black:focus, .btn-outline-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-black.disabled, .btn-outline-black:disabled {
  color: #000000;
  background-color: transparent;
}
.btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active, .show > .btn-outline-black.dropdown-toggle {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-input-color-text {
  color: #7f7873;
  border-color: #7f7873;
}
.btn-outline-input-color-text:hover {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-outline-input-color-text:focus, .btn-outline-input-color-text.focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 120, 115, 0.5);
}
.btn-outline-input-color-text.disabled, .btn-outline-input-color-text:disabled {
  color: #7f7873;
  background-color: transparent;
}
.btn-outline-input-color-text:not(:disabled):not(.disabled):active, .btn-outline-input-color-text:not(:disabled):not(.disabled).active, .show > .btn-outline-input-color-text.dropdown-toggle {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-outline-input-color-text:not(:disabled):not(.disabled):active:focus, .btn-outline-input-color-text:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-input-color-text.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 120, 115, 0.5);
}

.btn-outline-background-gray {
  color: #7f7873;
  border-color: #7f7873;
}
.btn-outline-background-gray:hover {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-outline-background-gray:focus, .btn-outline-background-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 120, 115, 0.5);
}
.btn-outline-background-gray.disabled, .btn-outline-background-gray:disabled {
  color: #7f7873;
  background-color: transparent;
}
.btn-outline-background-gray:not(:disabled):not(.disabled):active, .btn-outline-background-gray:not(:disabled):not(.disabled).active, .show > .btn-outline-background-gray.dropdown-toggle {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-outline-background-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-background-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-background-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 120, 115, 0.5);
}

.btn-outline-background-light-black {
  color: #3f3b39;
  border-color: #3f3b39;
}
.btn-outline-background-light-black:hover {
  color: #ffffff;
  background-color: #3f3b39;
  border-color: #3f3b39;
}
.btn-outline-background-light-black:focus, .btn-outline-background-light-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 59, 57, 0.5);
}
.btn-outline-background-light-black.disabled, .btn-outline-background-light-black:disabled {
  color: #3f3b39;
  background-color: transparent;
}
.btn-outline-background-light-black:not(:disabled):not(.disabled):active, .btn-outline-background-light-black:not(:disabled):not(.disabled).active, .show > .btn-outline-background-light-black.dropdown-toggle {
  color: #ffffff;
  background-color: #3f3b39;
  border-color: #3f3b39;
}
.btn-outline-background-light-black:not(:disabled):not(.disabled):active:focus, .btn-outline-background-light-black:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-background-light-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 59, 57, 0.5);
}

.btn-outline-input-color-svg {
  color: #000000;
  border-color: #000000;
}
.btn-outline-input-color-svg:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-input-color-svg:focus, .btn-outline-input-color-svg.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-input-color-svg.disabled, .btn-outline-input-color-svg:disabled {
  color: #000000;
  background-color: transparent;
}
.btn-outline-input-color-svg:not(:disabled):not(.disabled):active, .btn-outline-input-color-svg:not(:disabled):not(.disabled).active, .show > .btn-outline-input-color-svg.dropdown-toggle {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-input-color-svg:not(:disabled):not(.disabled):active:focus, .btn-outline-input-color-svg:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-input-color-svg.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-border-color-gray {
  color: #959595;
  border-color: #959595;
}
.btn-outline-border-color-gray:hover {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-outline-border-color-gray:focus, .btn-outline-border-color-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}
.btn-outline-border-color-gray.disabled, .btn-outline-border-color-gray:disabled {
  color: #959595;
  background-color: transparent;
}
.btn-outline-border-color-gray:not(:disabled):not(.disabled):active, .btn-outline-border-color-gray:not(:disabled):not(.disabled).active, .show > .btn-outline-border-color-gray.dropdown-toggle {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-outline-border-color-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-border-color-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-border-color-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}

.btn-outline-light-background {
  color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light-background:hover {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light-background:focus, .btn-outline-light-background.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}
.btn-outline-light-background.disabled, .btn-outline-light-background:disabled {
  color: #f2f2f2;
  background-color: transparent;
}
.btn-outline-light-background:not(:disabled):not(.disabled):active, .btn-outline-light-background:not(:disabled):not(.disabled).active, .show > .btn-outline-light-background.dropdown-toggle {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light-background:not(:disabled):not(.disabled):active:focus, .btn-outline-light-background:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.btn-outline-input-img-background {
  color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-input-img-background:hover {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-input-img-background:focus, .btn-outline-input-img-background.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}
.btn-outline-input-img-background.disabled, .btn-outline-input-img-background:disabled {
  color: #f2f2f2;
  background-color: transparent;
}
.btn-outline-input-img-background:not(:disabled):not(.disabled):active, .btn-outline-input-img-background:not(:disabled):not(.disabled).active, .show > .btn-outline-input-img-background.dropdown-toggle {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-input-img-background:not(:disabled):not(.disabled):active:focus, .btn-outline-input-img-background:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-input-img-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.btn-outline-td-btn-background {
  color: #959595;
  border-color: #959595;
}
.btn-outline-td-btn-background:hover {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-outline-td-btn-background:focus, .btn-outline-td-btn-background.focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}
.btn-outline-td-btn-background.disabled, .btn-outline-td-btn-background:disabled {
  color: #959595;
  background-color: transparent;
}
.btn-outline-td-btn-background:not(:disabled):not(.disabled):active, .btn-outline-td-btn-background:not(:disabled):not(.disabled).active, .show > .btn-outline-td-btn-background.dropdown-toggle {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-outline-td-btn-background:not(:disabled):not(.disabled):active:focus, .btn-outline-td-btn-background:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-td-btn-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}

.btn-outline-td-btn-disable-background {
  color: #959595;
  border-color: #959595;
}
.btn-outline-td-btn-disable-background:hover {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-outline-td-btn-disable-background:focus, .btn-outline-td-btn-disable-background.focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}
.btn-outline-td-btn-disable-background.disabled, .btn-outline-td-btn-disable-background:disabled {
  color: #959595;
  background-color: transparent;
}
.btn-outline-td-btn-disable-background:not(:disabled):not(.disabled):active, .btn-outline-td-btn-disable-background:not(:disabled):not(.disabled).active, .show > .btn-outline-td-btn-disable-background.dropdown-toggle {
  color: #ffffff;
  background-color: #959595;
  border-color: #959595;
}
.btn-outline-td-btn-disable-background:not(:disabled):not(.disabled):active:focus, .btn-outline-td-btn-disable-background:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-td-btn-disable-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}

.btn-outline-td-btn-icon {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-td-btn-icon:hover {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-td-btn-icon:focus, .btn-outline-td-btn-icon.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-td-btn-icon.disabled, .btn-outline-td-btn-icon:disabled {
  color: #ffffff;
  background-color: transparent;
}
.btn-outline-td-btn-icon:not(:disabled):not(.disabled):active, .btn-outline-td-btn-icon:not(:disabled):not(.disabled).active, .show > .btn-outline-td-btn-icon.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-td-btn-icon:not(:disabled):not(.disabled):active:focus, .btn-outline-td-btn-icon:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-td-btn-icon.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-tr-active {
  color: #e5e4e3;
  border-color: #e5e4e3;
}
.btn-outline-tr-active:hover {
  color: #212529;
  background-color: #e5e4e3;
  border-color: #e5e4e3;
}
.btn-outline-tr-active:focus, .btn-outline-tr-active.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 228, 227, 0.5);
}
.btn-outline-tr-active.disabled, .btn-outline-tr-active:disabled {
  color: #e5e4e3;
  background-color: transparent;
}
.btn-outline-tr-active:not(:disabled):not(.disabled):active, .btn-outline-tr-active:not(:disabled):not(.disabled).active, .show > .btn-outline-tr-active.dropdown-toggle {
  color: #212529;
  background-color: #e5e4e3;
  border-color: #e5e4e3;
}
.btn-outline-tr-active:not(:disabled):not(.disabled):active:focus, .btn-outline-tr-active:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-tr-active.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 228, 227, 0.5);
}

.btn-outline-login-text-color {
  color: rgba(0, 0, 0, 0.5019607843);
  border-color: rgba(0, 0, 0, 0.5019607843);
}
.btn-outline-login-text-color:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5019607843);
  border-color: rgba(0, 0, 0, 0.5019607843);
}
.btn-outline-login-text-color:focus, .btn-outline-login-text-color.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-login-text-color.disabled, .btn-outline-login-text-color:disabled {
  color: rgba(0, 0, 0, 0.5019607843);
  background-color: transparent;
}
.btn-outline-login-text-color:not(:disabled):not(.disabled):active, .btn-outline-login-text-color:not(:disabled):not(.disabled).active, .show > .btn-outline-login-text-color.dropdown-toggle {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5019607843);
  border-color: rgba(0, 0, 0, 0.5019607843);
}
.btn-outline-login-text-color:not(:disabled):not(.disabled):active:focus, .btn-outline-login-text-color:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-login-text-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-tan-color-two {
  color: #3f3b39;
  border-color: #3f3b39;
}
.btn-outline-tan-color-two:hover {
  color: #ffffff;
  background-color: #3f3b39;
  border-color: #3f3b39;
}
.btn-outline-tan-color-two:focus, .btn-outline-tan-color-two.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 59, 57, 0.5);
}
.btn-outline-tan-color-two.disabled, .btn-outline-tan-color-two:disabled {
  color: #3f3b39;
  background-color: transparent;
}
.btn-outline-tan-color-two:not(:disabled):not(.disabled):active, .btn-outline-tan-color-two:not(:disabled):not(.disabled).active, .show > .btn-outline-tan-color-two.dropdown-toggle {
  color: #ffffff;
  background-color: #3f3b39;
  border-color: #3f3b39;
}
.btn-outline-tan-color-two:not(:disabled):not(.disabled):active:focus, .btn-outline-tan-color-two:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-tan-color-two.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 59, 57, 0.5);
}

.btn-outline-tan-color-three {
  color: #f2eeeb;
  border-color: #f2eeeb;
}
.btn-outline-tan-color-three:hover {
  color: #212529;
  background-color: #f2eeeb;
  border-color: #f2eeeb;
}
.btn-outline-tan-color-three:focus, .btn-outline-tan-color-three.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 238, 235, 0.5);
}
.btn-outline-tan-color-three.disabled, .btn-outline-tan-color-three:disabled {
  color: #f2eeeb;
  background-color: transparent;
}
.btn-outline-tan-color-three:not(:disabled):not(.disabled):active, .btn-outline-tan-color-three:not(:disabled):not(.disabled).active, .show > .btn-outline-tan-color-three.dropdown-toggle {
  color: #212529;
  background-color: #f2eeeb;
  border-color: #f2eeeb;
}
.btn-outline-tan-color-three:not(:disabled):not(.disabled):active:focus, .btn-outline-tan-color-three:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-tan-color-three.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 238, 235, 0.5);
}

.btn-outline-gray-color-two {
  color: #b3b3b3;
  border-color: #b3b3b3;
}
.btn-outline-gray-color-two:hover {
  color: #212529;
  background-color: #b3b3b3;
  border-color: #b3b3b3;
}
.btn-outline-gray-color-two:focus, .btn-outline-gray-color-two.focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 179, 179, 0.5);
}
.btn-outline-gray-color-two.disabled, .btn-outline-gray-color-two:disabled {
  color: #b3b3b3;
  background-color: transparent;
}
.btn-outline-gray-color-two:not(:disabled):not(.disabled):active, .btn-outline-gray-color-two:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-color-two.dropdown-toggle {
  color: #212529;
  background-color: #b3b3b3;
  border-color: #b3b3b3;
}
.btn-outline-gray-color-two:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-color-two:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-color-two.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 179, 179, 0.5);
}

.btn-outline-btn-copper {
  color: #b87333;
  border-color: #b87333;
}
.btn-outline-btn-copper:hover {
  color: #ffffff;
  background-color: #b87333;
  border-color: #b87333;
}
.btn-outline-btn-copper:focus, .btn-outline-btn-copper.focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 115, 51, 0.5);
}
.btn-outline-btn-copper.disabled, .btn-outline-btn-copper:disabled {
  color: #b87333;
  background-color: transparent;
}
.btn-outline-btn-copper:not(:disabled):not(.disabled):active, .btn-outline-btn-copper:not(:disabled):not(.disabled).active, .show > .btn-outline-btn-copper.dropdown-toggle {
  color: #ffffff;
  background-color: #b87333;
  border-color: #b87333;
}
.btn-outline-btn-copper:not(:disabled):not(.disabled):active:focus, .btn-outline-btn-copper:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-btn-copper.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 115, 51, 0.5);
}

.btn-outline-text-copper {
  color: #b87333;
  border-color: #b87333;
}
.btn-outline-text-copper:hover {
  color: #ffffff;
  background-color: #b87333;
  border-color: #b87333;
}
.btn-outline-text-copper:focus, .btn-outline-text-copper.focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 115, 51, 0.5);
}
.btn-outline-text-copper.disabled, .btn-outline-text-copper:disabled {
  color: #b87333;
  background-color: transparent;
}
.btn-outline-text-copper:not(:disabled):not(.disabled):active, .btn-outline-text-copper:not(:disabled):not(.disabled).active, .show > .btn-outline-text-copper.dropdown-toggle {
  color: #ffffff;
  background-color: #b87333;
  border-color: #b87333;
}
.btn-outline-text-copper:not(:disabled):not(.disabled):active:focus, .btn-outline-text-copper:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-text-copper.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 115, 51, 0.5);
}

.btn-outline-light-hover {
  color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-outline-light-hover:hover {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-outline-light-hover:focus, .btn-outline-light-hover.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}
.btn-outline-light-hover.disabled, .btn-outline-light-hover:disabled {
  color: #f4f4f4;
  background-color: transparent;
}
.btn-outline-light-hover:not(:disabled):not(.disabled):active, .btn-outline-light-hover:not(:disabled):not(.disabled).active, .show > .btn-outline-light-hover.dropdown-toggle {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-outline-light-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-light-hover:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light-hover.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}

.btn-outline-primary {
  color: #7f7873;
  border-color: #7f7873;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 120, 115, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #7f7873;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 120, 115, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #000000;
  border-color: #000000;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #000000;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-info {
  color: #b3b3b3;
  border-color: #b3b3b3;
}
.btn-outline-info:hover {
  color: #212529;
  background-color: #b3b3b3;
  border-color: #b3b3b3;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 179, 179, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #b3b3b3;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #b3b3b3;
  border-color: #b3b3b3;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 179, 179, 0.5);
}

.btn-outline-warning {
  color: #b87333;
  border-color: #b87333;
}
.btn-outline-warning:hover {
  color: #ffffff;
  background-color: #b87333;
  border-color: #b87333;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 115, 51, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #b87333;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #b87333;
  border-color: #b87333;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 115, 51, 0.5);
}

.btn-outline-danger {
  color: #981b1e;
  border-color: #981b1e;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #981b1e;
  border-color: #981b1e;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(152, 27, 30, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #981b1e;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #981b1e;
  border-color: #981b1e;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(152, 27, 30, 0.5);
}

.btn-outline-light {
  color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f4f4f4;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #7f7873;
  text-decoration: none;
}
.btn-link:hover {
  color: #57524f;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 480px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #7f7873;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -3px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -3px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #413e4a;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid black;
  border-radius: 0;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #7f7873;
  background-color: #7f7873;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #bcb8b5;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #d4d2d0;
  border-color: #d4d2d0;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #7f7873;
  background-color: #7f7873;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(127, 120, 115, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(127, 120, 115, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(127, 120, 115, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(127, 120, 115, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-family: "Neutraface Text Book", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #413e4a;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid black;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #bcb8b5;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(127, 120, 115, 0.25);
}
.custom-select:focus::-ms-value {
  color: #413e4a;
  background-color: #ffffff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #413e4a;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #bcb8b5;
  box-shadow: none;
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-family: "Neutraface Text Book", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #413e4a;
  background-color: #ffffff;
  border: 1px solid black;
  border-radius: 0;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #413e4a;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #7f7873;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #d4d2d0;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #7f7873;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #d4d2d0;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #7f7873;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #d4d2d0;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #7f7873;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-xxl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 479.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 480px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1399.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl,
  .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl,
  .navbar-expand-xxl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 480px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 480px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 480px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #7f7873;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #57524f;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-text-color {
  color: #ffffff;
  background-color: #413e4a;
}
a.badge-text-color:hover, a.badge-text-color:focus {
  color: #ffffff;
  background-color: #29272e;
}
a.badge-text-color:focus, a.badge-text-color.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(65, 62, 74, 0.5);
}

.badge-button-danger {
  color: #ffffff;
  background-color: #981b1e;
}
a.badge-button-danger:hover, a.badge-button-danger:focus {
  color: #ffffff;
  background-color: #6d1315;
}
a.badge-button-danger:focus, a.badge-button-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(152, 27, 30, 0.5);
}

.badge-label-color {
  color: #212529;
  background-color: #ffffff;
}
a.badge-label-color:hover, a.badge-label-color:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-label-color:focus, a.badge-label-color.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-label-background-color {
  color: #ffffff;
  background-color: #959595;
}
a.badge-label-background-color:hover, a.badge-label-background-color:focus {
  color: #ffffff;
  background-color: #7c7c7c;
}
a.badge-label-background-color:focus, a.badge-label-background-color.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}

.badge-text-primary {
  color: #ffffff;
  background-color: #1a1a1a;
}
a.badge-text-primary:hover, a.badge-text-primary:focus {
  color: #ffffff;
  background-color: #010101;
}
a.badge-text-primary:focus, a.badge-text-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(26, 26, 26, 0.5);
}

.badge-text-secondary {
  color: #212529;
  background-color: #ffffff;
}
a.badge-text-secondary:hover, a.badge-text-secondary:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-text-secondary:focus, a.badge-text-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-text-tertiary {
  color: #212529;
  background-color: #f2f2f2;
}
a.badge-text-tertiary:hover, a.badge-text-tertiary:focus {
  color: #212529;
  background-color: #d9d9d9;
}
a.badge-text-tertiary:focus, a.badge-text-tertiary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.badge-text-black {
  color: #ffffff;
  background-color: #000000;
}
a.badge-text-black:hover, a.badge-text-black:focus {
  color: #ffffff;
  background-color: black;
}
a.badge-text-black:focus, a.badge-text-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-text-black-background-color {
  color: #ffffff;
  background-color: #191919;
}
a.badge-text-black-background-color:hover, a.badge-text-black-background-color:focus {
  color: #ffffff;
  background-color: black;
}
a.badge-text-black-background-color:focus, a.badge-text-black-background-color.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(25, 25, 25, 0.5);
}

.badge-small-text-color {
  color: #ffffff;
  background-color: #191919;
}
a.badge-small-text-color:hover, a.badge-small-text-color:focus {
  color: #ffffff;
  background-color: black;
}
a.badge-small-text-color:focus, a.badge-small-text-color.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(25, 25, 25, 0.5);
}

.badge-td-dark-bg {
  color: #212529;
  background-color: #ffffff;
}
a.badge-td-dark-bg:hover, a.badge-td-dark-bg:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-td-dark-bg:focus, a.badge-td-dark-bg.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-select-background-color {
  color: #212529;
  background-color: #ffffff;
}
a.badge-select-background-color:hover, a.badge-select-background-color:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-select-background-color:focus, a.badge-select-background-color.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-text-neutral {
  color: #ffffff;
  background-color: #959595;
}
a.badge-text-neutral:hover, a.badge-text-neutral:focus {
  color: #ffffff;
  background-color: #7c7c7c;
}
a.badge-text-neutral:focus, a.badge-text-neutral.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}

.badge-text-disabled {
  color: #212529;
  background-color: #d3d3d3;
}
a.badge-text-disabled:hover, a.badge-text-disabled:focus {
  color: #212529;
  background-color: #bababa;
}
a.badge-text-disabled:focus, a.badge-text-disabled.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 211, 0.5);
}

.badge-text-active {
  color: #212529;
  background-color: #e5e1cf;
}
a.badge-text-active:hover, a.badge-text-active:focus {
  color: #212529;
  background-color: #d3ccae;
}
a.badge-text-active:focus, a.badge-text-active.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 225, 207, 0.5);
}

.badge-text-hover {
  color: #ffffff;
  background-color: #787878;
}
a.badge-text-hover:hover, a.badge-text-hover:focus {
  color: #ffffff;
  background-color: #5f5f5f;
}
a.badge-text-hover:focus, a.badge-text-hover.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(120, 120, 120, 0.5);
}

.badge-text-menu-hover {
  color: #ffffff;
  background-color: #3f3b39;
}
a.badge-text-menu-hover:hover, a.badge-text-menu-hover:focus {
  color: #ffffff;
  background-color: #242221;
}
a.badge-text-menu-hover:focus, a.badge-text-menu-hover.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(63, 59, 57, 0.5);
}

.badge-button-color {
  color: #ffffff;
  background-color: #7f7873;
}
a.badge-button-color:hover, a.badge-button-color:focus {
  color: #ffffff;
  background-color: #645f5b;
}
a.badge-button-color:focus, a.badge-button-color.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(127, 120, 115, 0.5);
}

.badge-button-background-color {
  color: #ffffff;
  background-color: #333333;
}
a.badge-button-background-color:hover, a.badge-button-background-color:focus {
  color: #ffffff;
  background-color: #1a1a1a;
}
a.badge-button-background-color:focus, a.badge-button-background-color.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.badge-form-color-border {
  color: #ffffff;
  background-color: #7c746a;
}
a.badge-form-color-border:hover, a.badge-form-color-border:focus {
  color: #ffffff;
  background-color: #615a52;
}
a.badge-form-color-border:focus, a.badge-form-color-border.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(124, 116, 106, 0.5);
}

.badge-input-border-color {
  color: #ffffff;
  background-color: #959595;
}
a.badge-input-border-color:hover, a.badge-input-border-color:focus {
  color: #ffffff;
  background-color: #7c7c7c;
}
a.badge-input-border-color:focus, a.badge-input-border-color.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}

.badge-input-color-background {
  color: #212529;
  background-color: #ffffff;
}
a.badge-input-color-background:hover, a.badge-input-color-background:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-input-color-background:focus, a.badge-input-color-background.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-chrome-picker-background {
  color: #212529;
  background-color: #ffffff;
}
a.badge-chrome-picker-background:hover, a.badge-chrome-picker-background:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-chrome-picker-background:focus, a.badge-chrome-picker-background.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-black {
  color: #ffffff;
  background-color: #000000;
}
a.badge-black:hover, a.badge-black:focus {
  color: #ffffff;
  background-color: black;
}
a.badge-black:focus, a.badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-input-color-text {
  color: #ffffff;
  background-color: #7f7873;
}
a.badge-input-color-text:hover, a.badge-input-color-text:focus {
  color: #ffffff;
  background-color: #645f5b;
}
a.badge-input-color-text:focus, a.badge-input-color-text.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(127, 120, 115, 0.5);
}

.badge-background-gray {
  color: #ffffff;
  background-color: #7f7873;
}
a.badge-background-gray:hover, a.badge-background-gray:focus {
  color: #ffffff;
  background-color: #645f5b;
}
a.badge-background-gray:focus, a.badge-background-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(127, 120, 115, 0.5);
}

.badge-background-light-black {
  color: #ffffff;
  background-color: #3f3b39;
}
a.badge-background-light-black:hover, a.badge-background-light-black:focus {
  color: #ffffff;
  background-color: #242221;
}
a.badge-background-light-black:focus, a.badge-background-light-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(63, 59, 57, 0.5);
}

.badge-input-color-svg {
  color: #ffffff;
  background-color: #000000;
}
a.badge-input-color-svg:hover, a.badge-input-color-svg:focus {
  color: #ffffff;
  background-color: black;
}
a.badge-input-color-svg:focus, a.badge-input-color-svg.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-border-color-gray {
  color: #ffffff;
  background-color: #959595;
}
a.badge-border-color-gray:hover, a.badge-border-color-gray:focus {
  color: #ffffff;
  background-color: #7c7c7c;
}
a.badge-border-color-gray:focus, a.badge-border-color-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}

.badge-light-background {
  color: #212529;
  background-color: #f2f2f2;
}
a.badge-light-background:hover, a.badge-light-background:focus {
  color: #212529;
  background-color: #d9d9d9;
}
a.badge-light-background:focus, a.badge-light-background.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.badge-input-img-background {
  color: #212529;
  background-color: #f2f2f2;
}
a.badge-input-img-background:hover, a.badge-input-img-background:focus {
  color: #212529;
  background-color: #d9d9d9;
}
a.badge-input-img-background:focus, a.badge-input-img-background.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.badge-td-btn-background {
  color: #ffffff;
  background-color: #959595;
}
a.badge-td-btn-background:hover, a.badge-td-btn-background:focus {
  color: #ffffff;
  background-color: #7c7c7c;
}
a.badge-td-btn-background:focus, a.badge-td-btn-background.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}

.badge-td-btn-disable-background {
  color: #ffffff;
  background-color: #959595;
}
a.badge-td-btn-disable-background:hover, a.badge-td-btn-disable-background:focus {
  color: #ffffff;
  background-color: #7c7c7c;
}
a.badge-td-btn-disable-background:focus, a.badge-td-btn-disable-background.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(149, 149, 149, 0.5);
}

.badge-td-btn-icon {
  color: #212529;
  background-color: #ffffff;
}
a.badge-td-btn-icon:hover, a.badge-td-btn-icon:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-td-btn-icon:focus, a.badge-td-btn-icon.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-tr-active {
  color: #212529;
  background-color: #e5e4e3;
}
a.badge-tr-active:hover, a.badge-tr-active:focus {
  color: #212529;
  background-color: #cccbc9;
}
a.badge-tr-active:focus, a.badge-tr-active.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 228, 227, 0.5);
}

.badge-login-text-color {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5019607843);
}
a.badge-login-text-color:hover, a.badge-login-text-color:focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5019607843);
}
a.badge-login-text-color:focus, a.badge-login-text-color.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-tan-color-two {
  color: #ffffff;
  background-color: #3f3b39;
}
a.badge-tan-color-two:hover, a.badge-tan-color-two:focus {
  color: #ffffff;
  background-color: #242221;
}
a.badge-tan-color-two:focus, a.badge-tan-color-two.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(63, 59, 57, 0.5);
}

.badge-tan-color-three {
  color: #212529;
  background-color: #f2eeeb;
}
a.badge-tan-color-three:hover, a.badge-tan-color-three:focus {
  color: #212529;
  background-color: #ded4cc;
}
a.badge-tan-color-three:focus, a.badge-tan-color-three.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 238, 235, 0.5);
}

.badge-gray-color-two {
  color: #212529;
  background-color: #b3b3b3;
}
a.badge-gray-color-two:hover, a.badge-gray-color-two:focus {
  color: #212529;
  background-color: #9a9a9a;
}
a.badge-gray-color-two:focus, a.badge-gray-color-two.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(179, 179, 179, 0.5);
}

.badge-btn-copper {
  color: #ffffff;
  background-color: #b87333;
}
a.badge-btn-copper:hover, a.badge-btn-copper:focus {
  color: #ffffff;
  background-color: #905a28;
}
a.badge-btn-copper:focus, a.badge-btn-copper.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(184, 115, 51, 0.5);
}

.badge-text-copper {
  color: #ffffff;
  background-color: #b87333;
}
a.badge-text-copper:hover, a.badge-text-copper:focus {
  color: #ffffff;
  background-color: #905a28;
}
a.badge-text-copper:focus, a.badge-text-copper.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(184, 115, 51, 0.5);
}

.badge-light-hover {
  color: #212529;
  background-color: #f4f4f4;
}
a.badge-light-hover:hover, a.badge-light-hover:focus {
  color: #212529;
  background-color: #dbdbdb;
}
a.badge-light-hover:focus, a.badge-light-hover.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}

.badge-primary {
  color: #ffffff;
  background-color: #7f7873;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #645f5b;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(127, 120, 115, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #ffffff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #000000;
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: black;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #b3b3b3;
}
a.badge-info:hover, a.badge-info:focus {
  color: #212529;
  background-color: #9a9a9a;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(179, 179, 179, 0.5);
}

.badge-warning {
  color: #ffffff;
  background-color: #b87333;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #ffffff;
  background-color: #905a28;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(184, 115, 51, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #981b1e;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #6d1315;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(152, 27, 30, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f4f4f4;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dbdbdb;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 480px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-text-color {
  color: #222026;
  background-color: #d9d8db;
  border-color: #cac9cc;
}
.alert-text-color hr {
  border-top-color: #bdbcc0;
}
.alert-text-color .alert-link {
  color: #09090a;
}

.alert-button-danger {
  color: #4f0e10;
  background-color: #ead1d2;
  border-color: #e2bfc0;
}
.alert-button-danger hr {
  border-top-color: #daadaf;
}
.alert-button-danger .alert-link {
  color: #240607;
}

.alert-label-color {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-label-color hr {
  border-top-color: #f2f2f2;
}
.alert-label-color .alert-link {
  color: #6c6c6c;
}

.alert-label-background-color {
  color: #4d4d4d;
  background-color: #eaeaea;
  border-color: #e1e1e1;
}
.alert-label-background-color hr {
  border-top-color: #d4d4d4;
}
.alert-label-background-color .alert-link {
  color: #343434;
}

.alert-text-primary {
  color: #0e0e0e;
  background-color: #d1d1d1;
  border-color: #bfbfbf;
}
.alert-text-primary hr {
  border-top-color: #b2b2b2;
}
.alert-text-primary .alert-link {
  color: black;
}

.alert-text-secondary {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-text-secondary hr {
  border-top-color: #f2f2f2;
}
.alert-text-secondary .alert-link {
  color: #6c6c6c;
}

.alert-text-tertiary {
  color: #7e7e7e;
  background-color: #fcfcfc;
  border-color: #fbfbfb;
}
.alert-text-tertiary hr {
  border-top-color: #eeeeee;
}
.alert-text-tertiary .alert-link {
  color: #656565;
}

.alert-text-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}
.alert-text-black hr {
  border-top-color: #ababab;
}
.alert-text-black .alert-link {
  color: black;
}

.alert-text-black-background-color {
  color: #0d0d0d;
  background-color: #d1d1d1;
  border-color: #bfbfbf;
}
.alert-text-black-background-color hr {
  border-top-color: #b2b2b2;
}
.alert-text-black-background-color .alert-link {
  color: black;
}

.alert-small-text-color {
  color: #0d0d0d;
  background-color: #d1d1d1;
  border-color: #bfbfbf;
}
.alert-small-text-color hr {
  border-top-color: #b2b2b2;
}
.alert-small-text-color .alert-link {
  color: black;
}

.alert-td-dark-bg {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-td-dark-bg hr {
  border-top-color: #f2f2f2;
}
.alert-td-dark-bg .alert-link {
  color: #6c6c6c;
}

.alert-select-background-color {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-select-background-color hr {
  border-top-color: #f2f2f2;
}
.alert-select-background-color .alert-link {
  color: #6c6c6c;
}

.alert-text-neutral {
  color: #4d4d4d;
  background-color: #eaeaea;
  border-color: #e1e1e1;
}
.alert-text-neutral hr {
  border-top-color: #d4d4d4;
}
.alert-text-neutral .alert-link {
  color: #343434;
}

.alert-text-disabled {
  color: #6e6e6e;
  background-color: #f6f6f6;
  border-color: #f3f3f3;
}
.alert-text-disabled hr {
  border-top-color: #e6e6e6;
}
.alert-text-disabled .alert-link {
  color: #555555;
}

.alert-text-active {
  color: #77756c;
  background-color: #faf9f5;
  border-color: #f8f7f2;
}
.alert-text-active hr {
  border-top-color: #efede1;
}
.alert-text-active .alert-link {
  color: #5c5b54;
}

.alert-text-hover {
  color: #3e3e3e;
  background-color: #e4e4e4;
  border-color: #d9d9d9;
}
.alert-text-hover hr {
  border-top-color: #cccccc;
}
.alert-text-hover .alert-link {
  color: #252525;
}

.alert-text-menu-hover {
  color: #211f1e;
  background-color: #d9d8d7;
  border-color: #c9c8c8;
}
.alert-text-menu-hover hr {
  border-top-color: #bcbbbb;
}
.alert-text-menu-hover .alert-link {
  color: #060606;
}

.alert-button-color {
  color: #423e3c;
  background-color: #e5e4e3;
  border-color: #dbd9d8;
}
.alert-button-color hr {
  border-top-color: #cfcccb;
}
.alert-button-color .alert-link {
  color: #272524;
}

.alert-button-background-color {
  color: #1b1b1b;
  background-color: #d6d6d6;
  border-color: #c6c6c6;
}
.alert-button-background-color hr {
  border-top-color: #b9b9b9;
}
.alert-button-background-color .alert-link {
  color: #020202;
}

.alert-form-color-border {
  color: #403c37;
  background-color: #e5e3e1;
  border-color: #dad8d5;
}
.alert-form-color-border hr {
  border-top-color: #cecbc7;
}
.alert-form-color-border .alert-link {
  color: #25221f;
}

.alert-input-border-color {
  color: #4d4d4d;
  background-color: #eaeaea;
  border-color: #e1e1e1;
}
.alert-input-border-color hr {
  border-top-color: #d4d4d4;
}
.alert-input-border-color .alert-link {
  color: #343434;
}

.alert-input-color-background {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-input-color-background hr {
  border-top-color: #f2f2f2;
}
.alert-input-color-background .alert-link {
  color: #6c6c6c;
}

.alert-chrome-picker-background {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-chrome-picker-background hr {
  border-top-color: #f2f2f2;
}
.alert-chrome-picker-background .alert-link {
  color: #6c6c6c;
}

.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}
.alert-black hr {
  border-top-color: #ababab;
}
.alert-black .alert-link {
  color: black;
}

.alert-input-color-text {
  color: #423e3c;
  background-color: #e5e4e3;
  border-color: #dbd9d8;
}
.alert-input-color-text hr {
  border-top-color: #cfcccb;
}
.alert-input-color-text .alert-link {
  color: #272524;
}

.alert-background-gray {
  color: #423e3c;
  background-color: #e5e4e3;
  border-color: #dbd9d8;
}
.alert-background-gray hr {
  border-top-color: #cfcccb;
}
.alert-background-gray .alert-link {
  color: #272524;
}

.alert-background-light-black {
  color: #211f1e;
  background-color: #d9d8d7;
  border-color: #c9c8c8;
}
.alert-background-light-black hr {
  border-top-color: #bcbbbb;
}
.alert-background-light-black .alert-link {
  color: #060606;
}

.alert-input-color-svg {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}
.alert-input-color-svg hr {
  border-top-color: #ababab;
}
.alert-input-color-svg .alert-link {
  color: black;
}

.alert-border-color-gray {
  color: #4d4d4d;
  background-color: #eaeaea;
  border-color: #e1e1e1;
}
.alert-border-color-gray hr {
  border-top-color: #d4d4d4;
}
.alert-border-color-gray .alert-link {
  color: #343434;
}

.alert-light-background {
  color: #7e7e7e;
  background-color: #fcfcfc;
  border-color: #fbfbfb;
}
.alert-light-background hr {
  border-top-color: #eeeeee;
}
.alert-light-background .alert-link {
  color: #656565;
}

.alert-input-img-background {
  color: #7e7e7e;
  background-color: #fcfcfc;
  border-color: #fbfbfb;
}
.alert-input-img-background hr {
  border-top-color: #eeeeee;
}
.alert-input-img-background .alert-link {
  color: #656565;
}

.alert-td-btn-background {
  color: #4d4d4d;
  background-color: #eaeaea;
  border-color: #e1e1e1;
}
.alert-td-btn-background hr {
  border-top-color: #d4d4d4;
}
.alert-td-btn-background .alert-link {
  color: #343434;
}

.alert-td-btn-disable-background {
  color: #4d4d4d;
  background-color: #eaeaea;
  border-color: #e1e1e1;
}
.alert-td-btn-disable-background hr {
  border-top-color: #d4d4d4;
}
.alert-td-btn-disable-background .alert-link {
  color: #343434;
}

.alert-td-btn-icon {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-td-btn-icon hr {
  border-top-color: #f2f2f2;
}
.alert-td-btn-icon .alert-link {
  color: #6c6c6c;
}

.alert-tr-active {
  color: #777776;
  background-color: #fafaf9;
  border-color: #f8f7f7;
}
.alert-tr-active hr {
  border-top-color: #ece9e9;
}
.alert-tr-active .alert-link {
  color: #5d5d5d;
}

.alert-login-text-color {
  color: rgba(0, 0, 0, 0.7410196078);
  background-color: rgba(235, 235, 235, 0.9003921569);
  border-color: rgba(226, 226, 226, 0.8605490196);
}
.alert-login-text-color hr {
  border-top-color: rgba(213, 213, 213, 0.8605490196);
}
.alert-login-text-color .alert-link {
  color: rgba(0, 0, 0, 0.7410196078);
}

.alert-tan-color-two {
  color: #211f1e;
  background-color: #d9d8d7;
  border-color: #c9c8c8;
}
.alert-tan-color-two hr {
  border-top-color: #bcbbbb;
}
.alert-tan-color-two .alert-link {
  color: #060606;
}

.alert-tan-color-three {
  color: #7e7c7a;
  background-color: #fcfcfb;
  border-color: #fbfaf9;
}
.alert-tan-color-three hr {
  border-top-color: #f1edea;
}
.alert-tan-color-three .alert-link {
  color: #646361;
}

.alert-gray-color-two {
  color: #5d5d5d;
  background-color: #f0f0f0;
  border-color: #eaeaea;
}
.alert-gray-color-two hr {
  border-top-color: #dddddd;
}
.alert-gray-color-two .alert-link {
  color: #444444;
}

.alert-btn-copper {
  color: #603c1b;
  background-color: #f1e3d6;
  border-color: #ebd8c6;
}
.alert-btn-copper hr {
  border-top-color: #e4cbb3;
}
.alert-btn-copper .alert-link {
  color: #382310;
}

.alert-text-copper {
  color: #603c1b;
  background-color: #f1e3d6;
  border-color: #ebd8c6;
}
.alert-text-copper hr {
  border-top-color: #e4cbb3;
}
.alert-text-copper .alert-link {
  color: #382310;
}

.alert-light-hover {
  color: #7f7f7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}
.alert-light-hover hr {
  border-top-color: #efefef;
}
.alert-light-hover .alert-link {
  color: #666666;
}

.alert-primary {
  color: #423e3c;
  background-color: #e5e4e3;
  border-color: #dbd9d8;
}
.alert-primary hr {
  border-top-color: #cfcccb;
}
.alert-primary .alert-link {
  color: #272524;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}
.alert-success hr {
  border-top-color: #ababab;
}
.alert-success .alert-link {
  color: black;
}

.alert-info {
  color: #5d5d5d;
  background-color: #f0f0f0;
  border-color: #eaeaea;
}
.alert-info hr {
  border-top-color: #dddddd;
}
.alert-info .alert-link {
  color: #444444;
}

.alert-warning {
  color: #603c1b;
  background-color: #f1e3d6;
  border-color: #ebd8c6;
}
.alert-warning hr {
  border-top-color: #e4cbb3;
}
.alert-warning .alert-link {
  color: #382310;
}

.alert-danger {
  color: #4f0e10;
  background-color: #ead1d2;
  border-color: #e2bfc0;
}
.alert-danger hr {
  border-top-color: #daadaf;
}
.alert-danger .alert-link {
  color: #240607;
}

.alert-light {
  color: #7f7f7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}
.alert-light hr {
  border-top-color: #efefef;
}
.alert-light .alert-link {
  color: #666666;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #7f7873;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #7f7873;
  border-color: #7f7873;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 480px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-text-color {
  color: #222026;
  background-color: #cac9cc;
}
.list-group-item-text-color.list-group-item-action:hover, .list-group-item-text-color.list-group-item-action:focus {
  color: #222026;
  background-color: #bdbcc0;
}
.list-group-item-text-color.list-group-item-action.active {
  color: #ffffff;
  background-color: #222026;
  border-color: #222026;
}

.list-group-item-button-danger {
  color: #4f0e10;
  background-color: #e2bfc0;
}
.list-group-item-button-danger.list-group-item-action:hover, .list-group-item-button-danger.list-group-item-action:focus {
  color: #4f0e10;
  background-color: #daadaf;
}
.list-group-item-button-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #4f0e10;
  border-color: #4f0e10;
}

.list-group-item-label-color {
  color: #858585;
  background-color: white;
}
.list-group-item-label-color.list-group-item-action:hover, .list-group-item-label-color.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-label-color.list-group-item-action.active {
  color: #ffffff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-label-background-color {
  color: #4d4d4d;
  background-color: #e1e1e1;
}
.list-group-item-label-background-color.list-group-item-action:hover, .list-group-item-label-background-color.list-group-item-action:focus {
  color: #4d4d4d;
  background-color: #d4d4d4;
}
.list-group-item-label-background-color.list-group-item-action.active {
  color: #ffffff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.list-group-item-text-primary {
  color: #0e0e0e;
  background-color: #bfbfbf;
}
.list-group-item-text-primary.list-group-item-action:hover, .list-group-item-text-primary.list-group-item-action:focus {
  color: #0e0e0e;
  background-color: #b2b2b2;
}
.list-group-item-text-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #0e0e0e;
  border-color: #0e0e0e;
}

.list-group-item-text-secondary {
  color: #858585;
  background-color: white;
}
.list-group-item-text-secondary.list-group-item-action:hover, .list-group-item-text-secondary.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-text-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-text-tertiary {
  color: #7e7e7e;
  background-color: #fbfbfb;
}
.list-group-item-text-tertiary.list-group-item-action:hover, .list-group-item-text-tertiary.list-group-item-action:focus {
  color: #7e7e7e;
  background-color: #eeeeee;
}
.list-group-item-text-tertiary.list-group-item-action.active {
  color: #ffffff;
  background-color: #7e7e7e;
  border-color: #7e7e7e;
}

.list-group-item-text-black {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-text-black.list-group-item-action:hover, .list-group-item-text-black.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}
.list-group-item-text-black.list-group-item-action.active {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.list-group-item-text-black-background-color {
  color: #0d0d0d;
  background-color: #bfbfbf;
}
.list-group-item-text-black-background-color.list-group-item-action:hover, .list-group-item-text-black-background-color.list-group-item-action:focus {
  color: #0d0d0d;
  background-color: #b2b2b2;
}
.list-group-item-text-black-background-color.list-group-item-action.active {
  color: #ffffff;
  background-color: #0d0d0d;
  border-color: #0d0d0d;
}

.list-group-item-small-text-color {
  color: #0d0d0d;
  background-color: #bfbfbf;
}
.list-group-item-small-text-color.list-group-item-action:hover, .list-group-item-small-text-color.list-group-item-action:focus {
  color: #0d0d0d;
  background-color: #b2b2b2;
}
.list-group-item-small-text-color.list-group-item-action.active {
  color: #ffffff;
  background-color: #0d0d0d;
  border-color: #0d0d0d;
}

.list-group-item-td-dark-bg {
  color: #858585;
  background-color: white;
}
.list-group-item-td-dark-bg.list-group-item-action:hover, .list-group-item-td-dark-bg.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-td-dark-bg.list-group-item-action.active {
  color: #ffffff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-select-background-color {
  color: #858585;
  background-color: white;
}
.list-group-item-select-background-color.list-group-item-action:hover, .list-group-item-select-background-color.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-select-background-color.list-group-item-action.active {
  color: #ffffff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-text-neutral {
  color: #4d4d4d;
  background-color: #e1e1e1;
}
.list-group-item-text-neutral.list-group-item-action:hover, .list-group-item-text-neutral.list-group-item-action:focus {
  color: #4d4d4d;
  background-color: #d4d4d4;
}
.list-group-item-text-neutral.list-group-item-action.active {
  color: #ffffff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.list-group-item-text-disabled {
  color: #6e6e6e;
  background-color: #f3f3f3;
}
.list-group-item-text-disabled.list-group-item-action:hover, .list-group-item-text-disabled.list-group-item-action:focus {
  color: #6e6e6e;
  background-color: #e6e6e6;
}
.list-group-item-text-disabled.list-group-item-action.active {
  color: #ffffff;
  background-color: #6e6e6e;
  border-color: #6e6e6e;
}

.list-group-item-text-active {
  color: #77756c;
  background-color: #f8f7f2;
}
.list-group-item-text-active.list-group-item-action:hover, .list-group-item-text-active.list-group-item-action:focus {
  color: #77756c;
  background-color: #efede1;
}
.list-group-item-text-active.list-group-item-action.active {
  color: #ffffff;
  background-color: #77756c;
  border-color: #77756c;
}

.list-group-item-text-hover {
  color: #3e3e3e;
  background-color: #d9d9d9;
}
.list-group-item-text-hover.list-group-item-action:hover, .list-group-item-text-hover.list-group-item-action:focus {
  color: #3e3e3e;
  background-color: #cccccc;
}
.list-group-item-text-hover.list-group-item-action.active {
  color: #ffffff;
  background-color: #3e3e3e;
  border-color: #3e3e3e;
}

.list-group-item-text-menu-hover {
  color: #211f1e;
  background-color: #c9c8c8;
}
.list-group-item-text-menu-hover.list-group-item-action:hover, .list-group-item-text-menu-hover.list-group-item-action:focus {
  color: #211f1e;
  background-color: #bcbbbb;
}
.list-group-item-text-menu-hover.list-group-item-action.active {
  color: #ffffff;
  background-color: #211f1e;
  border-color: #211f1e;
}

.list-group-item-button-color {
  color: #423e3c;
  background-color: #dbd9d8;
}
.list-group-item-button-color.list-group-item-action:hover, .list-group-item-button-color.list-group-item-action:focus {
  color: #423e3c;
  background-color: #cfcccb;
}
.list-group-item-button-color.list-group-item-action.active {
  color: #ffffff;
  background-color: #423e3c;
  border-color: #423e3c;
}

.list-group-item-button-background-color {
  color: #1b1b1b;
  background-color: #c6c6c6;
}
.list-group-item-button-background-color.list-group-item-action:hover, .list-group-item-button-background-color.list-group-item-action:focus {
  color: #1b1b1b;
  background-color: #b9b9b9;
}
.list-group-item-button-background-color.list-group-item-action.active {
  color: #ffffff;
  background-color: #1b1b1b;
  border-color: #1b1b1b;
}

.list-group-item-form-color-border {
  color: #403c37;
  background-color: #dad8d5;
}
.list-group-item-form-color-border.list-group-item-action:hover, .list-group-item-form-color-border.list-group-item-action:focus {
  color: #403c37;
  background-color: #cecbc7;
}
.list-group-item-form-color-border.list-group-item-action.active {
  color: #ffffff;
  background-color: #403c37;
  border-color: #403c37;
}

.list-group-item-input-border-color {
  color: #4d4d4d;
  background-color: #e1e1e1;
}
.list-group-item-input-border-color.list-group-item-action:hover, .list-group-item-input-border-color.list-group-item-action:focus {
  color: #4d4d4d;
  background-color: #d4d4d4;
}
.list-group-item-input-border-color.list-group-item-action.active {
  color: #ffffff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.list-group-item-input-color-background {
  color: #858585;
  background-color: white;
}
.list-group-item-input-color-background.list-group-item-action:hover, .list-group-item-input-color-background.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-input-color-background.list-group-item-action.active {
  color: #ffffff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-chrome-picker-background {
  color: #858585;
  background-color: white;
}
.list-group-item-chrome-picker-background.list-group-item-action:hover, .list-group-item-chrome-picker-background.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-chrome-picker-background.list-group-item-action.active {
  color: #ffffff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-black {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}
.list-group-item-black.list-group-item-action.active {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.list-group-item-input-color-text {
  color: #423e3c;
  background-color: #dbd9d8;
}
.list-group-item-input-color-text.list-group-item-action:hover, .list-group-item-input-color-text.list-group-item-action:focus {
  color: #423e3c;
  background-color: #cfcccb;
}
.list-group-item-input-color-text.list-group-item-action.active {
  color: #ffffff;
  background-color: #423e3c;
  border-color: #423e3c;
}

.list-group-item-background-gray {
  color: #423e3c;
  background-color: #dbd9d8;
}
.list-group-item-background-gray.list-group-item-action:hover, .list-group-item-background-gray.list-group-item-action:focus {
  color: #423e3c;
  background-color: #cfcccb;
}
.list-group-item-background-gray.list-group-item-action.active {
  color: #ffffff;
  background-color: #423e3c;
  border-color: #423e3c;
}

.list-group-item-background-light-black {
  color: #211f1e;
  background-color: #c9c8c8;
}
.list-group-item-background-light-black.list-group-item-action:hover, .list-group-item-background-light-black.list-group-item-action:focus {
  color: #211f1e;
  background-color: #bcbbbb;
}
.list-group-item-background-light-black.list-group-item-action.active {
  color: #ffffff;
  background-color: #211f1e;
  border-color: #211f1e;
}

.list-group-item-input-color-svg {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-input-color-svg.list-group-item-action:hover, .list-group-item-input-color-svg.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}
.list-group-item-input-color-svg.list-group-item-action.active {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.list-group-item-border-color-gray {
  color: #4d4d4d;
  background-color: #e1e1e1;
}
.list-group-item-border-color-gray.list-group-item-action:hover, .list-group-item-border-color-gray.list-group-item-action:focus {
  color: #4d4d4d;
  background-color: #d4d4d4;
}
.list-group-item-border-color-gray.list-group-item-action.active {
  color: #ffffff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.list-group-item-light-background {
  color: #7e7e7e;
  background-color: #fbfbfb;
}
.list-group-item-light-background.list-group-item-action:hover, .list-group-item-light-background.list-group-item-action:focus {
  color: #7e7e7e;
  background-color: #eeeeee;
}
.list-group-item-light-background.list-group-item-action.active {
  color: #ffffff;
  background-color: #7e7e7e;
  border-color: #7e7e7e;
}

.list-group-item-input-img-background {
  color: #7e7e7e;
  background-color: #fbfbfb;
}
.list-group-item-input-img-background.list-group-item-action:hover, .list-group-item-input-img-background.list-group-item-action:focus {
  color: #7e7e7e;
  background-color: #eeeeee;
}
.list-group-item-input-img-background.list-group-item-action.active {
  color: #ffffff;
  background-color: #7e7e7e;
  border-color: #7e7e7e;
}

.list-group-item-td-btn-background {
  color: #4d4d4d;
  background-color: #e1e1e1;
}
.list-group-item-td-btn-background.list-group-item-action:hover, .list-group-item-td-btn-background.list-group-item-action:focus {
  color: #4d4d4d;
  background-color: #d4d4d4;
}
.list-group-item-td-btn-background.list-group-item-action.active {
  color: #ffffff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.list-group-item-td-btn-disable-background {
  color: #4d4d4d;
  background-color: #e1e1e1;
}
.list-group-item-td-btn-disable-background.list-group-item-action:hover, .list-group-item-td-btn-disable-background.list-group-item-action:focus {
  color: #4d4d4d;
  background-color: #d4d4d4;
}
.list-group-item-td-btn-disable-background.list-group-item-action.active {
  color: #ffffff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.list-group-item-td-btn-icon {
  color: #858585;
  background-color: white;
}
.list-group-item-td-btn-icon.list-group-item-action:hover, .list-group-item-td-btn-icon.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-td-btn-icon.list-group-item-action.active {
  color: #ffffff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-tr-active {
  color: #777776;
  background-color: #f8f7f7;
}
.list-group-item-tr-active.list-group-item-action:hover, .list-group-item-tr-active.list-group-item-action:focus {
  color: #777776;
  background-color: #ece9e9;
}
.list-group-item-tr-active.list-group-item-action.active {
  color: #ffffff;
  background-color: #777776;
  border-color: #777776;
}

.list-group-item-login-text-color {
  color: rgba(0, 0, 0, 0.7410196078);
  background-color: rgba(226, 226, 226, 0.8605490196);
}
.list-group-item-login-text-color.list-group-item-action:hover, .list-group-item-login-text-color.list-group-item-action:focus {
  color: rgba(0, 0, 0, 0.7410196078);
  background-color: rgba(213, 213, 213, 0.8605490196);
}
.list-group-item-login-text-color.list-group-item-action.active {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7410196078);
  border-color: rgba(0, 0, 0, 0.7410196078);
}

.list-group-item-tan-color-two {
  color: #211f1e;
  background-color: #c9c8c8;
}
.list-group-item-tan-color-two.list-group-item-action:hover, .list-group-item-tan-color-two.list-group-item-action:focus {
  color: #211f1e;
  background-color: #bcbbbb;
}
.list-group-item-tan-color-two.list-group-item-action.active {
  color: #ffffff;
  background-color: #211f1e;
  border-color: #211f1e;
}

.list-group-item-tan-color-three {
  color: #7e7c7a;
  background-color: #fbfaf9;
}
.list-group-item-tan-color-three.list-group-item-action:hover, .list-group-item-tan-color-three.list-group-item-action:focus {
  color: #7e7c7a;
  background-color: #f1edea;
}
.list-group-item-tan-color-three.list-group-item-action.active {
  color: #ffffff;
  background-color: #7e7c7a;
  border-color: #7e7c7a;
}

.list-group-item-gray-color-two {
  color: #5d5d5d;
  background-color: #eaeaea;
}
.list-group-item-gray-color-two.list-group-item-action:hover, .list-group-item-gray-color-two.list-group-item-action:focus {
  color: #5d5d5d;
  background-color: #dddddd;
}
.list-group-item-gray-color-two.list-group-item-action.active {
  color: #ffffff;
  background-color: #5d5d5d;
  border-color: #5d5d5d;
}

.list-group-item-btn-copper {
  color: #603c1b;
  background-color: #ebd8c6;
}
.list-group-item-btn-copper.list-group-item-action:hover, .list-group-item-btn-copper.list-group-item-action:focus {
  color: #603c1b;
  background-color: #e4cbb3;
}
.list-group-item-btn-copper.list-group-item-action.active {
  color: #ffffff;
  background-color: #603c1b;
  border-color: #603c1b;
}

.list-group-item-text-copper {
  color: #603c1b;
  background-color: #ebd8c6;
}
.list-group-item-text-copper.list-group-item-action:hover, .list-group-item-text-copper.list-group-item-action:focus {
  color: #603c1b;
  background-color: #e4cbb3;
}
.list-group-item-text-copper.list-group-item-action.active {
  color: #ffffff;
  background-color: #603c1b;
  border-color: #603c1b;
}

.list-group-item-light-hover {
  color: #7f7f7f;
  background-color: #fcfcfc;
}
.list-group-item-light-hover.list-group-item-action:hover, .list-group-item-light-hover.list-group-item-action:focus {
  color: #7f7f7f;
  background-color: #efefef;
}
.list-group-item-light-hover.list-group-item-action.active {
  color: #ffffff;
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}

.list-group-item-primary {
  color: #423e3c;
  background-color: #dbd9d8;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #423e3c;
  background-color: #cfcccb;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #423e3c;
  border-color: #423e3c;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.list-group-item-info {
  color: #5d5d5d;
  background-color: #eaeaea;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #5d5d5d;
  background-color: #dddddd;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #5d5d5d;
  border-color: #5d5d5d;
}

.list-group-item-warning {
  color: #603c1b;
  background-color: #ebd8c6;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #603c1b;
  background-color: #e4cbb3;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #603c1b;
  border-color: #603c1b;
}

.list-group-item-danger {
  color: #4f0e10;
  background-color: #e2bfc0;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #4f0e10;
  background-color: #daadaf;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #4f0e10;
  border-color: #4f0e10;
}

.list-group-item-light {
  color: #7f7f7f;
  background-color: #fcfcfc;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7f7f7f;
  background-color: #efefef;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
@media (max-width: 1200px) {
  .close {
    font-size: calc(1.275rem + 0.3vw);
  }
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 480px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Gotham Book", "Helvetica Neue", Helvetica, Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Gotham Book", "Helvetica Neue", Helvetica, Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-text-color {
  background-color: #413e4a !important;
}

a.bg-text-color:hover, a.bg-text-color:focus,
button.bg-text-color:hover,
button.bg-text-color:focus {
  background-color: #29272e !important;
}

.bg-button-danger {
  background-color: #981b1e !important;
}

a.bg-button-danger:hover, a.bg-button-danger:focus,
button.bg-button-danger:hover,
button.bg-button-danger:focus {
  background-color: #6d1315 !important;
}

.bg-label-color {
  background-color: #ffffff !important;
}

a.bg-label-color:hover, a.bg-label-color:focus,
button.bg-label-color:hover,
button.bg-label-color:focus {
  background-color: #e6e6e6 !important;
}

.bg-label-background-color {
  background-color: #959595 !important;
}

a.bg-label-background-color:hover, a.bg-label-background-color:focus,
button.bg-label-background-color:hover,
button.bg-label-background-color:focus {
  background-color: #7c7c7c !important;
}

.bg-text-primary {
  background-color: #1a1a1a !important;
}

a.bg-text-primary:hover, a.bg-text-primary:focus,
button.bg-text-primary:hover,
button.bg-text-primary:focus {
  background-color: #010101 !important;
}

.bg-text-secondary {
  background-color: #ffffff !important;
}

a.bg-text-secondary:hover, a.bg-text-secondary:focus,
button.bg-text-secondary:hover,
button.bg-text-secondary:focus {
  background-color: #e6e6e6 !important;
}

.bg-text-tertiary {
  background-color: #f2f2f2 !important;
}

a.bg-text-tertiary:hover, a.bg-text-tertiary:focus,
button.bg-text-tertiary:hover,
button.bg-text-tertiary:focus {
  background-color: #d9d9d9 !important;
}

.bg-text-black {
  background-color: #000000 !important;
}

a.bg-text-black:hover, a.bg-text-black:focus,
button.bg-text-black:hover,
button.bg-text-black:focus {
  background-color: black !important;
}

.bg-text-black-background-color {
  background-color: #191919 !important;
}

a.bg-text-black-background-color:hover, a.bg-text-black-background-color:focus,
button.bg-text-black-background-color:hover,
button.bg-text-black-background-color:focus {
  background-color: black !important;
}

.bg-small-text-color {
  background-color: #191919 !important;
}

a.bg-small-text-color:hover, a.bg-small-text-color:focus,
button.bg-small-text-color:hover,
button.bg-small-text-color:focus {
  background-color: black !important;
}

.bg-td-dark-bg {
  background-color: #ffffff !important;
}

a.bg-td-dark-bg:hover, a.bg-td-dark-bg:focus,
button.bg-td-dark-bg:hover,
button.bg-td-dark-bg:focus {
  background-color: #e6e6e6 !important;
}

.bg-select-background-color {
  background-color: #ffffff !important;
}

a.bg-select-background-color:hover, a.bg-select-background-color:focus,
button.bg-select-background-color:hover,
button.bg-select-background-color:focus {
  background-color: #e6e6e6 !important;
}

.bg-text-neutral {
  background-color: #959595 !important;
}

a.bg-text-neutral:hover, a.bg-text-neutral:focus,
button.bg-text-neutral:hover,
button.bg-text-neutral:focus {
  background-color: #7c7c7c !important;
}

.bg-text-disabled {
  background-color: #d3d3d3 !important;
}

a.bg-text-disabled:hover, a.bg-text-disabled:focus,
button.bg-text-disabled:hover,
button.bg-text-disabled:focus {
  background-color: #bababa !important;
}

.bg-text-active {
  background-color: #e5e1cf !important;
}

a.bg-text-active:hover, a.bg-text-active:focus,
button.bg-text-active:hover,
button.bg-text-active:focus {
  background-color: #d3ccae !important;
}

.bg-text-hover {
  background-color: #787878 !important;
}

a.bg-text-hover:hover, a.bg-text-hover:focus,
button.bg-text-hover:hover,
button.bg-text-hover:focus {
  background-color: #5f5f5f !important;
}

.bg-text-menu-hover {
  background-color: #3f3b39 !important;
}

a.bg-text-menu-hover:hover, a.bg-text-menu-hover:focus,
button.bg-text-menu-hover:hover,
button.bg-text-menu-hover:focus {
  background-color: #242221 !important;
}

.bg-button-color {
  background-color: #7f7873 !important;
}

a.bg-button-color:hover, a.bg-button-color:focus,
button.bg-button-color:hover,
button.bg-button-color:focus {
  background-color: #645f5b !important;
}

.bg-button-background-color {
  background-color: #333333 !important;
}

a.bg-button-background-color:hover, a.bg-button-background-color:focus,
button.bg-button-background-color:hover,
button.bg-button-background-color:focus {
  background-color: #1a1a1a !important;
}

.bg-form-color-border {
  background-color: #7c746a !important;
}

a.bg-form-color-border:hover, a.bg-form-color-border:focus,
button.bg-form-color-border:hover,
button.bg-form-color-border:focus {
  background-color: #615a52 !important;
}

.bg-input-border-color {
  background-color: #959595 !important;
}

a.bg-input-border-color:hover, a.bg-input-border-color:focus,
button.bg-input-border-color:hover,
button.bg-input-border-color:focus {
  background-color: #7c7c7c !important;
}

.bg-input-color-background {
  background-color: #ffffff !important;
}

a.bg-input-color-background:hover, a.bg-input-color-background:focus,
button.bg-input-color-background:hover,
button.bg-input-color-background:focus {
  background-color: #e6e6e6 !important;
}

.bg-chrome-picker-background {
  background-color: #ffffff !important;
}

a.bg-chrome-picker-background:hover, a.bg-chrome-picker-background:focus,
button.bg-chrome-picker-background:hover,
button.bg-chrome-picker-background:focus {
  background-color: #e6e6e6 !important;
}

.bg-black {
  background-color: #000000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-input-color-text {
  background-color: #7f7873 !important;
}

a.bg-input-color-text:hover, a.bg-input-color-text:focus,
button.bg-input-color-text:hover,
button.bg-input-color-text:focus {
  background-color: #645f5b !important;
}

.bg-background-gray {
  background-color: #7f7873 !important;
}

a.bg-background-gray:hover, a.bg-background-gray:focus,
button.bg-background-gray:hover,
button.bg-background-gray:focus {
  background-color: #645f5b !important;
}

.bg-background-light-black {
  background-color: #3f3b39 !important;
}

a.bg-background-light-black:hover, a.bg-background-light-black:focus,
button.bg-background-light-black:hover,
button.bg-background-light-black:focus {
  background-color: #242221 !important;
}

.bg-input-color-svg {
  background-color: #000000 !important;
}

a.bg-input-color-svg:hover, a.bg-input-color-svg:focus,
button.bg-input-color-svg:hover,
button.bg-input-color-svg:focus {
  background-color: black !important;
}

.bg-border-color-gray {
  background-color: #959595 !important;
}

a.bg-border-color-gray:hover, a.bg-border-color-gray:focus,
button.bg-border-color-gray:hover,
button.bg-border-color-gray:focus {
  background-color: #7c7c7c !important;
}

.bg-light-background {
  background-color: #f2f2f2 !important;
}

a.bg-light-background:hover, a.bg-light-background:focus,
button.bg-light-background:hover,
button.bg-light-background:focus {
  background-color: #d9d9d9 !important;
}

.bg-input-img-background {
  background-color: #f2f2f2 !important;
}

a.bg-input-img-background:hover, a.bg-input-img-background:focus,
button.bg-input-img-background:hover,
button.bg-input-img-background:focus {
  background-color: #d9d9d9 !important;
}

.bg-td-btn-background {
  background-color: #959595 !important;
}

a.bg-td-btn-background:hover, a.bg-td-btn-background:focus,
button.bg-td-btn-background:hover,
button.bg-td-btn-background:focus {
  background-color: #7c7c7c !important;
}

.bg-td-btn-disable-background {
  background-color: #959595 !important;
}

a.bg-td-btn-disable-background:hover, a.bg-td-btn-disable-background:focus,
button.bg-td-btn-disable-background:hover,
button.bg-td-btn-disable-background:focus {
  background-color: #7c7c7c !important;
}

.bg-td-btn-icon {
  background-color: #ffffff !important;
}

a.bg-td-btn-icon:hover, a.bg-td-btn-icon:focus,
button.bg-td-btn-icon:hover,
button.bg-td-btn-icon:focus {
  background-color: #e6e6e6 !important;
}

.bg-tr-active {
  background-color: #e5e4e3 !important;
}

a.bg-tr-active:hover, a.bg-tr-active:focus,
button.bg-tr-active:hover,
button.bg-tr-active:focus {
  background-color: #cccbc9 !important;
}

.bg-login-text-color {
  background-color: rgba(0, 0, 0, 0.5019607843) !important;
}

a.bg-login-text-color:hover, a.bg-login-text-color:focus,
button.bg-login-text-color:hover,
button.bg-login-text-color:focus {
  background-color: rgba(0, 0, 0, 0.5019607843) !important;
}

.bg-tan-color-two {
  background-color: #3f3b39 !important;
}

a.bg-tan-color-two:hover, a.bg-tan-color-two:focus,
button.bg-tan-color-two:hover,
button.bg-tan-color-two:focus {
  background-color: #242221 !important;
}

.bg-tan-color-three {
  background-color: #f2eeeb !important;
}

a.bg-tan-color-three:hover, a.bg-tan-color-three:focus,
button.bg-tan-color-three:hover,
button.bg-tan-color-three:focus {
  background-color: #ded4cc !important;
}

.bg-gray-color-two {
  background-color: #b3b3b3 !important;
}

a.bg-gray-color-two:hover, a.bg-gray-color-two:focus,
button.bg-gray-color-two:hover,
button.bg-gray-color-two:focus {
  background-color: #9a9a9a !important;
}

.bg-btn-copper {
  background-color: #b87333 !important;
}

a.bg-btn-copper:hover, a.bg-btn-copper:focus,
button.bg-btn-copper:hover,
button.bg-btn-copper:focus {
  background-color: #905a28 !important;
}

.bg-text-copper {
  background-color: #b87333 !important;
}

a.bg-text-copper:hover, a.bg-text-copper:focus,
button.bg-text-copper:hover,
button.bg-text-copper:focus {
  background-color: #905a28 !important;
}

.bg-light-hover {
  background-color: #f4f4f4 !important;
}

a.bg-light-hover:hover, a.bg-light-hover:focus,
button.bg-light-hover:hover,
button.bg-light-hover:focus {
  background-color: #dbdbdb !important;
}

.bg-primary {
  background-color: #7f7873 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #645f5b !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #000000 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: black !important;
}

.bg-info {
  background-color: #b3b3b3 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #9a9a9a !important;
}

.bg-warning {
  background-color: #b87333 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #905a28 !important;
}

.bg-danger {
  background-color: #981b1e !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #6d1315 !important;
}

.bg-light {
  background-color: #f4f4f4 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dbdbdb !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-text-color {
  border-color: #413e4a !important;
}

.border-button-danger {
  border-color: #981b1e !important;
}

.border-label-color {
  border-color: #ffffff !important;
}

.border-label-background-color {
  border-color: #959595 !important;
}

.border-text-primary {
  border-color: #1a1a1a !important;
}

.border-text-secondary {
  border-color: #ffffff !important;
}

.border-text-tertiary {
  border-color: #f2f2f2 !important;
}

.border-text-black {
  border-color: #000000 !important;
}

.border-text-black-background-color {
  border-color: #191919 !important;
}

.border-small-text-color {
  border-color: #191919 !important;
}

.border-td-dark-bg {
  border-color: #ffffff !important;
}

.border-select-background-color {
  border-color: #ffffff !important;
}

.border-text-neutral {
  border-color: #959595 !important;
}

.border-text-disabled {
  border-color: #d3d3d3 !important;
}

.border-text-active {
  border-color: #e5e1cf !important;
}

.border-text-hover {
  border-color: #787878 !important;
}

.border-text-menu-hover {
  border-color: #3f3b39 !important;
}

.border-button-color {
  border-color: #7f7873 !important;
}

.border-button-background-color {
  border-color: #333333 !important;
}

.border-form-color-border {
  border-color: #7c746a !important;
}

.border-input-border-color {
  border-color: #959595 !important;
}

.border-input-color-background {
  border-color: #ffffff !important;
}

.border-chrome-picker-background {
  border-color: #ffffff !important;
}

.border-black {
  border-color: #000000 !important;
}

.border-input-color-text {
  border-color: #7f7873 !important;
}

.border-background-gray {
  border-color: #7f7873 !important;
}

.border-background-light-black {
  border-color: #3f3b39 !important;
}

.border-input-color-svg {
  border-color: #000000 !important;
}

.border-border-color-gray {
  border-color: #959595 !important;
}

.border-light-background {
  border-color: #f2f2f2 !important;
}

.border-input-img-background {
  border-color: #f2f2f2 !important;
}

.border-td-btn-background {
  border-color: #959595 !important;
}

.border-td-btn-disable-background {
  border-color: #959595 !important;
}

.border-td-btn-icon {
  border-color: #ffffff !important;
}

.border-tr-active {
  border-color: #e5e4e3 !important;
}

.border-login-text-color {
  border-color: rgba(0, 0, 0, 0.5019607843) !important;
}

.border-tan-color-two {
  border-color: #3f3b39 !important;
}

.border-tan-color-three {
  border-color: #f2eeeb !important;
}

.border-gray-color-two {
  border-color: #b3b3b3 !important;
}

.border-btn-copper {
  border-color: #b87333 !important;
}

.border-text-copper {
  border-color: #b87333 !important;
}

.border-light-hover {
  border-color: #f4f4f4 !important;
}

.border-primary {
  border-color: #7f7873 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #000000 !important;
}

.border-info {
  border-color: #b3b3b3 !important;
}

.border-warning {
  border-color: #b87333 !important;
}

.border-danger {
  border-color: #981b1e !important;
}

.border-light {
  border-color: #f4f4f4 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 480px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 480px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 480px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 480px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 480px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-text-color {
  color: #413e4a !important;
}

a.text-text-color:hover, a.text-text-color:focus {
  color: #1c1b20 !important;
}

.text-button-danger {
  color: #981b1e !important;
}

a.text-button-danger:hover, a.text-button-danger:focus {
  color: #570f11 !important;
}

.text-label-color {
  color: #ffffff !important;
}

a.text-label-color:hover, a.text-label-color:focus {
  color: #d9d9d9 !important;
}

.text-label-background-color {
  color: #959595 !important;
}

a.text-label-background-color:hover, a.text-label-background-color:focus {
  color: #6f6f6f !important;
}

.text-text-primary {
  color: #1a1a1a !important;
}

a.text-text-primary:hover, a.text-text-primary:focus {
  color: black !important;
}

.text-text-secondary {
  color: #ffffff !important;
}

a.text-text-secondary:hover, a.text-text-secondary:focus {
  color: #d9d9d9 !important;
}

.text-text-tertiary {
  color: #f2f2f2 !important;
}

a.text-text-tertiary:hover, a.text-text-tertiary:focus {
  color: #cccccc !important;
}

.text-text-black {
  color: #000000 !important;
}

a.text-text-black:hover, a.text-text-black:focus {
  color: black !important;
}

.text-text-black-background-color {
  color: #191919 !important;
}

a.text-text-black-background-color:hover, a.text-text-black-background-color:focus {
  color: black !important;
}

.text-small-text-color {
  color: #191919 !important;
}

a.text-small-text-color:hover, a.text-small-text-color:focus {
  color: black !important;
}

.text-td-dark-bg {
  color: #ffffff !important;
}

a.text-td-dark-bg:hover, a.text-td-dark-bg:focus {
  color: #d9d9d9 !important;
}

.text-select-background-color {
  color: #ffffff !important;
}

a.text-select-background-color:hover, a.text-select-background-color:focus {
  color: #d9d9d9 !important;
}

.text-text-neutral {
  color: #959595 !important;
}

a.text-text-neutral:hover, a.text-text-neutral:focus {
  color: #6f6f6f !important;
}

.text-text-disabled {
  color: #d3d3d3 !important;
}

a.text-text-disabled:hover, a.text-text-disabled:focus {
  color: #adadad !important;
}

.text-text-active {
  color: #e5e1cf !important;
}

a.text-text-active:hover, a.text-text-active:focus {
  color: #cac29d !important;
}

.text-text-hover {
  color: #787878 !important;
}

a.text-text-hover:hover, a.text-text-hover:focus {
  color: #525252 !important;
}

.text-text-menu-hover {
  color: #3f3b39 !important;
}

a.text-text-menu-hover:hover, a.text-text-menu-hover:focus {
  color: #171515 !important;
}

.text-button-color {
  color: #7f7873 !important;
}

a.text-button-color:hover, a.text-button-color:focus {
  color: #57524f !important;
}

.text-button-background-color {
  color: #333333 !important;
}

a.text-button-background-color:hover, a.text-button-background-color:focus {
  color: #0d0d0d !important;
}

.text-form-color-border {
  color: #7c746a !important;
}

a.text-form-color-border:hover, a.text-form-color-border:focus {
  color: #534d47 !important;
}

.text-input-border-color {
  color: #959595 !important;
}

a.text-input-border-color:hover, a.text-input-border-color:focus {
  color: #6f6f6f !important;
}

.text-input-color-background {
  color: #ffffff !important;
}

a.text-input-color-background:hover, a.text-input-color-background:focus {
  color: #d9d9d9 !important;
}

.text-chrome-picker-background {
  color: #ffffff !important;
}

a.text-chrome-picker-background:hover, a.text-chrome-picker-background:focus {
  color: #d9d9d9 !important;
}

.text-black {
  color: #000000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-input-color-text {
  color: #7f7873 !important;
}

a.text-input-color-text:hover, a.text-input-color-text:focus {
  color: #57524f !important;
}

.text-background-gray {
  color: #7f7873 !important;
}

a.text-background-gray:hover, a.text-background-gray:focus {
  color: #57524f !important;
}

.text-background-light-black {
  color: #3f3b39 !important;
}

a.text-background-light-black:hover, a.text-background-light-black:focus {
  color: #171515 !important;
}

.text-input-color-svg {
  color: #000000 !important;
}

a.text-input-color-svg:hover, a.text-input-color-svg:focus {
  color: black !important;
}

.text-border-color-gray {
  color: #959595 !important;
}

a.text-border-color-gray:hover, a.text-border-color-gray:focus {
  color: #6f6f6f !important;
}

.text-light-background {
  color: #f2f2f2 !important;
}

a.text-light-background:hover, a.text-light-background:focus {
  color: #cccccc !important;
}

.text-input-img-background {
  color: #f2f2f2 !important;
}

a.text-input-img-background:hover, a.text-input-img-background:focus {
  color: #cccccc !important;
}

.text-td-btn-background {
  color: #959595 !important;
}

a.text-td-btn-background:hover, a.text-td-btn-background:focus {
  color: #6f6f6f !important;
}

.text-td-btn-disable-background {
  color: #959595 !important;
}

a.text-td-btn-disable-background:hover, a.text-td-btn-disable-background:focus {
  color: #6f6f6f !important;
}

.text-td-btn-icon {
  color: #ffffff !important;
}

a.text-td-btn-icon:hover, a.text-td-btn-icon:focus {
  color: #d9d9d9 !important;
}

.text-tr-active {
  color: #e5e4e3 !important;
}

a.text-tr-active:hover, a.text-tr-active:focus {
  color: #c0bebb !important;
}

.text-login-text-color {
  color: rgba(0, 0, 0, 0.5019607843) !important;
}

a.text-login-text-color:hover, a.text-login-text-color:focus {
  color: rgba(0, 0, 0, 0.5019607843) !important;
}

.text-tan-color-two {
  color: #3f3b39 !important;
}

a.text-tan-color-two:hover, a.text-tan-color-two:focus {
  color: #171515 !important;
}

.text-tan-color-three {
  color: #f2eeeb !important;
}

a.text-tan-color-three:hover, a.text-tan-color-three:focus {
  color: #d4c7bd !important;
}

.text-gray-color-two {
  color: #b3b3b3 !important;
}

a.text-gray-color-two:hover, a.text-gray-color-two:focus {
  color: #8d8d8d !important;
}

.text-btn-copper {
  color: #b87333 !important;
}

a.text-btn-copper:hover, a.text-btn-copper:focus {
  color: #7c4e22 !important;
}

.text-text-copper {
  color: #b87333 !important;
}

a.text-text-copper:hover, a.text-text-copper:focus {
  color: #7c4e22 !important;
}

.text-light-hover {
  color: #f4f4f4 !important;
}

a.text-light-hover:hover, a.text-light-hover:focus {
  color: #cecece !important;
}

.text-primary {
  color: #7f7873 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #57524f !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #000000 !important;
}

a.text-success:hover, a.text-success:focus {
  color: black !important;
}

.text-info {
  color: #b3b3b3 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #8d8d8d !important;
}

.text-warning {
  color: #b87333 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #7c4e22 !important;
}

.text-danger {
  color: #981b1e !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #570f11 !important;
}

.text-light {
  color: #f4f4f4 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cecece !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
button {
  color: currentColor;
  border-style: solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}
button.btn {
  display: inline-flex;
  line-height: initial;
  font-size: var(--font-size-base);
}
button.btn * {
  display: inline-block;
}
button.btn.btn-sm, .btn-group-sm > button.btn {
  width: calc(var(--btn-grid-breakpoint) * 2);
  height: calc(var(--btn-grid-breakpoint) * 3);
  min-height: calc(var(--btn-grid-breakpoint) * 3);
  min-width: calc(var(--btn-grid-breakpoint) * 2);
  padding: 0px;
  border-width: 0px;
}
button.btn.btn-md {
  width: calc(var(--btn-grid-breakpoint) * 7);
  min-width: calc(var(--btn-grid-breakpoint) * 7);
  height: calc(var(--btn-grid-breakpoint) * 3);
  min-height: calc(var(--btn-grid-breakpoint) * 3);
}
button.btn.btn-lg, .btn-group-lg > button.btn {
  width: calc(var(--btn-grid-breakpoint) * 15);
  min-width: calc(var(--btn-grid-breakpoint) * 15);
  height: calc(var(--btn-grid-breakpoint) * 3);
  min-height: calc(var(--btn-grid-breakpoint) * 3);
}
button.btn.btn-xl {
  width: calc(var(--btn-grid-breakpoint) * 22);
  min-width: calc(var(--btn-grid-breakpoint) * 22);
  height: calc(var(--btn-grid-breakpoint) * 3);
  min-height: calc(var(--btn-grid-breakpoint) * 3);
}
@media (max-width: 575px) {
  button.btn.btn-xl {
    font-size: 10px;
  }
}
@media (max-width: 415px) {
  button.btn.btn-xl {
    width: calc(var(--btn-grid-breakpoint) * 15);
    min-width: calc(var(--btn-grid-breakpoint) * 15);
  }
}
button.btn.btn-2xl {
  width: calc(var(--btn-grid-breakpoint) * 25);
  min-width: calc(var(--btn-grid-breakpoint) * 25);
  height: calc(var(--btn-grid-breakpoint) * 3);
  min-height: calc(var(--btn-grid-breakpoint) * 3);
}
button.btn.btn-3xl {
  width: calc(var(--btn-grid-breakpoint) * 40);
  min-width: calc(var(--btn-grid-breakpoint) * 40);
  height: calc(var(--btn-grid-breakpoint) * 3);
  min-height: calc(var(--btn-grid-breakpoint) * 3);
}
button.btn.btn-4xl {
  width: calc(var(--btn-grid-breakpoint) * 25);
  min-width: calc(var(--btn-grid-breakpoint) * 25);
  height: calc(var(--btn-grid-breakpoint) * 4);
  min-height: calc(var(--btn-grid-breakpoint) * 4);
}
button, button span {
  text-transform: uppercase;
}
button i {
  margin-right: 8px;
  -webkit-transform: translateX(4px);
  transform: translateX(4px);
  font-size: 1.1428571429em;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
button.btn-bg-clear i {
  color: var(--text-black);
}
button, button:active, button:focus {
  outline: none;
}
button.active {
  background-color: var(--gray);
  color: white;
}
button.btn.btn-primary, button.btn.btn-secondary, button.btn.btn-success, button.btn.btn-info, button.btn.btn-dark, button.btn.btn-danger {
  color: var(--light);
}
button.btn.btn-link {
  color: var(--text-black);
}
button.btn.btn-outline-link {
  color: var(--text-black);
}
button.btn.btn-clear {
  border-color: transparent;
}
button.btn.btn-clear:hover {
  color: #4a4a4a;
}
button.btn.btn-clear.active {
  background-color: var(--gray);
  color: white;
}
button.btn.btn-white {
  color: var(--black);
  background-color: white;
  border-color: var(--black);
  border-width: 0px;
  overflow: hidden;
  transition: all 0.3s ease;
  /* swipe right to left */
}
button.btn.btn-white span {
  z-index: 2;
  position: relative;
}
button.btn.btn-white.swipe-rtl:hover {
  border-color: var(--background-gray);
  color: #fff;
}
button.btn.btn-white.swipe-rtl:hover::after {
  transform: translateX(0);
  transition: transform 350ms ease;
}
button.btn.btn-white.swipe-rtl:hover::before {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
button.btn.btn-white.swipe-rtl::before, button.btn.btn-white.swipe-rtl::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--background-gray);
  z-index: -1;
}
button.btn.btn-white.swipe-rtl::after {
  transform: translateX(100%);
  z-index: 1;
}
button.btn.btn-white.swipe-rtl::before {
  z-index: 0;
  transform: translateX(-100%);
  transition: none;
  transition: transform 350ms ease;
}
button.btn.btn-black {
  background-color: #4d4d4d;
  border-color: black;
  color: white;
  overflow: hidden;
  transition: all 0.3s ease;
  border-width: 0px;
  /* swipe right to left */
}
button.btn.btn-black span {
  z-index: 2;
  position: relative;
}
button.btn.btn-black:hover {
  background-color: black;
}
button.btn.btn-black.swipe-rtl:hover {
  border-color: var(--background-gray);
  color: #fff;
}
button.btn.btn-black.swipe-rtl:hover::after {
  transform: translateX(0);
  transition: transform 350ms ease;
}
button.btn.btn-black.swipe-rtl:hover::before {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
button.btn.btn-black.swipe-rtl::before, button.btn.btn-black.swipe-rtl::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--background-gray);
  z-index: -1;
}
button.btn.btn-black.swipe-rtl::after {
  transform: translateX(100%);
  z-index: 1;
}
button.btn.btn-black.swipe-rtl::before {
  z-index: 0;
  transform: translateX(-100%);
  transition: none;
  transition: transform 350ms ease;
}
button.btn.btn-dark-black {
  background-color: black;
  border-color: black;
  color: white;
  border-width: 0px;
}
button.btn.btn-dark-black span {
  z-index: 2;
  position: relative;
}
button.btn.btn-dark-black:hover {
  background-color: black;
}
button.btn.btn-dark-black.swipe-rtl:hover {
  color: var(--white);
}
button.btn.btn-dark-black.swipe-rtl:hover::after {
  transform: translateX(0);
  transition: transform 350ms ease;
}
button.btn.btn-dark-black.swipe-rtl:hover::before {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
button.btn.btn-dark-black.swipe-rtl::before, button.btn.btn-dark-black.swipe-rtl::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--button-color);
  z-index: -1;
}
button.btn.btn-dark-black.swipe-rtl::after {
  transform: translateX(100%);
  z-index: 1;
}
button.btn.btn-dark-black.swipe-rtl::before {
  z-index: 0;
  transform: translateX(-100%);
  transition: none;
  transition: transform 350ms ease;
}
button.btn.btn-red {
  background-color: var(--button-danger);
  border-color: var(--button-danger);
  color: var(--text-secondary);
}
button.btn.btn-red:hover {
  background-color: var(--input-color-background);
  border-color: var(--button-danger);
  color: var(--button-danger);
}
button.btn.btn-gray {
  background-color: #969696;
  border-color: #969696;
  color: white;
}
button.btn.btn-gray:hover {
  background-color: white;
  border-color: #969696;
  color: #969696;
}
button.btn.btn-brown {
  background-color: var(--text-menu-hover);
  border-color: var(--text-menu-hover);
  color: var(--text-secondary);
}
button.btn.btn-brown:hover {
  background-color: var(--input-color-background);
  border-color: var(--text-menu-hover);
  color: var(--text-menu-hover);
}
button.btn.btn-tan1 {
  background-color: var(--background-gray);
  border-color: var(--background-gray);
  color: var(--white);
  overflow: hidden;
  transition: all 0.3s ease;
  /* swipe right to left */
}
button.btn.btn-tan1 span {
  z-index: 2;
  position: relative;
}
button.btn.btn-tan1.active {
  background-color: var(--white);
  color: var(--black);
}
button.btn.btn-tan1.active:hover {
  color: var(--white);
  background-color: var(--background-gray);
}
button.btn.btn-tan1.swipe-rtl:hover {
  border-color: var(--background-gray);
  color: var(--black);
}
button.btn.btn-tan1.swipe-rtl:hover::after {
  transform: translateX(0);
  transition: transform 350ms ease;
}
button.btn.btn-tan1.swipe-rtl:hover::before {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
button.btn.btn-tan1.swipe-rtl::before, button.btn.btn-tan1.swipe-rtl::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--white);
  z-index: -1;
}
button.btn.btn-tan1.swipe-rtl::after {
  transform: translateX(100%);
  z-index: 1;
}
button.btn.btn-tan1.swipe-rtl::before {
  z-index: 0;
  transform: translateX(-100%);
  transition: none;
  transition: transform 350ms ease;
}
button.btn.btn-tan2 {
  background-color: var(--tan-color-two);
  border-color: var(--tan-color-two);
  color: var(--text-secondary);
}
button.btn.btn-tan3 {
  background-color: var(--tan-color-three);
  border-color: var(--tan-color-three);
  color: var(--text-black);
}
button.btn.btn-gray1 {
  background-color: var(--text-neutral);
  border-color: var(--text-neutral);
  color: var(--text-secondary);
}
button.btn.btn-gray2 {
  background-color: var(--gray-color-two);
  border-color: var(--gray-color-two);
  color: var(--text-secondary);
}
button.btn.btn-gray3 {
  background-color: var(--text-tertiary);
  border-color: var(--text-tertiary);
  color: var(--text-black);
}
button.btn.btn-copper {
  background-color: var(--btn-copper);
  border-color: var(--btn-copper);
  color: var(--text-secondary);
}
button.btn.btn-light-black {
  background-color: var(--button-background-color);
  border: 1px solid var(--button-color);
  color: var(--white);
}
button.btn.btn-light-black:hover {
  background-color: var(--input-color-background);
  border-color: var(--input-border-color);
  color: var(--text-neutral);
}
button[disabled=true],
button[disabled=true] button {
  filter: brightness(85%);
  cursor: not-allowed !important;
}

.wolf-btn {
  font-family: "Gotham Book";
  font-style: normal;
  max-height: 60px;
  min-height: 45px;
  height: 45px;
  max-width: 370px;
  min-width: 198px;
  width: 198px;
  text-transform: uppercase;
  position: relative;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  /* data table */
  /* btn size */
  /* font family */
  /* for anchor tag */
  /* colors */
}
.wolf-btn span {
  z-index: 2;
  position: relative;
}
.wolf-btn.dt {
  width: 225px;
}
.wolf-btn.lg {
  width: 100%;
  height: 60px;
}
.wolf-btn.sm {
  width: 198px;
  height: 45px;
}
.wolf-btn.ntb {
  font-family: "Neutraface Text Book", sans-serif;
}
.wolf-btn.gb {
  font-family: "Gotham Book", sans-serif;
}
.wolf-btn:hover {
  text-decoration: none;
}
.wolf-btn.white {
  color: var(--background-gray);
  border-color: var(--white);
  background-color: var(--white);
  /* swipe left animation */
  /* swipe right animation */
  /* swipe left to right */
  /* swipe right to left */
  /* active */
  /* hover */
}
.wolf-btn.white.swipe-left:hover {
  color: var(--white);
}
.wolf-btn.white.swipe-left:hover::before {
  transform: translateX(-50%);
  background-color: var(--background-gray);
}
.wolf-btn.white.swipe-left::before {
  content: "";
  transform: translateX(-101%);
  position: absolute;
  left: 0px;
  top: -5px;
  height: 140%;
  width: 240%;
  transition: all 0.5s ease-in-out 0s;
}
.wolf-btn.white.swipe-right:hover {
  color: var(--white);
}
.wolf-btn.white.swipe-right:hover::before {
  transform: translateX(0%);
  background-color: var(--background-gray);
}
.wolf-btn.white.swipe-right::before {
  content: "";
  transform: translateX(50%);
  position: absolute;
  left: 0px;
  top: 0px;
  height: 140%;
  width: 240%;
  transition: all 0.5s ease-in-out 0s;
}
.wolf-btn.white.swipe-ltr:hover {
  color: var(--white);
}
.wolf-btn.white.swipe-ltr:hover::before {
  transform: translateX(0);
  transition: transform 350ms ease;
}
.wolf-btn.white.swipe-ltr:hover::after {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
.wolf-btn.white.swipe-ltr::before, .wolf-btn.white.swipe-ltr::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--background-gray);
  z-index: -1;
}
.wolf-btn.white.swipe-ltr::before {
  transform: translateX(-100%);
  z-index: 1;
}
.wolf-btn.white.swipe-ltr::after {
  z-index: 0;
  transform: translateX(100%);
  transition: none;
  transition: transform 350ms ease;
}
.wolf-btn.white.swipe-rtl:hover {
  color: var(--white);
}
.wolf-btn.white.swipe-rtl:hover::after {
  transform: translateX(0);
  transition: transform 350ms ease;
}
.wolf-btn.white.swipe-rtl:hover::before {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
.wolf-btn.white.swipe-rtl::before, .wolf-btn.white.swipe-rtl::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--background-gray);
  z-index: -1;
}
.wolf-btn.white.swipe-rtl::after {
  transform: translateX(100%);
  z-index: 1;
}
.wolf-btn.white.swipe-rtl::before {
  z-index: 0;
  transform: translateX(-100%);
  transition: none;
  transition: transform 350ms ease;
}
.wolf-btn.white.active {
  background-color: var(--background-gray);
  color: #ffffff;
}
.wolf-btn.white.hover:hover {
  color: var(--white);
  background-color: var(--background-gray);
}
.wolf-btn.primary {
  color: #7f7873;
  border-color: #7f7873;
  /* swipe left animation */
  /* swipe right animation */
  /* swipe left to right */
  /* swipe right to left */
  /* active */
  /* hover */
}
.wolf-btn.primary.swipe-left:hover {
  color: #ffffff;
}
.wolf-btn.primary.swipe-left:hover::before {
  transform: translateX(-50%);
  background-color: #7f7873;
}
.wolf-btn.primary.swipe-left::before {
  content: "";
  transform: translateX(-101%);
  position: absolute;
  left: 0px;
  top: -5px;
  height: 140%;
  width: 240%;
  transition: all 0.5s ease-in-out 0s;
}
.wolf-btn.primary.swipe-right:hover {
  color: #ffffff;
}
.wolf-btn.primary.swipe-right:hover::before {
  transform: translateX(0%);
  background-color: #7f7873;
}
.wolf-btn.primary.swipe-right::before {
  content: "";
  transform: translateX(50%);
  position: absolute;
  left: 0px;
  top: 0px;
  height: 140%;
  width: 240%;
  transition: all 0.5s ease-in-out 0s;
}
.wolf-btn.primary.swipe-ltr:hover {
  color: #ffffff;
}
.wolf-btn.primary.swipe-ltr:hover::before {
  transform: translateX(0);
  transition: transform 350ms ease;
}
.wolf-btn.primary.swipe-ltr:hover::after {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
.wolf-btn.primary.swipe-ltr::before, .wolf-btn.primary.swipe-ltr::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: #7f7873;
  z-index: -1;
}
.wolf-btn.primary.swipe-ltr::before {
  transform: translateX(-100%);
  z-index: 1;
}
.wolf-btn.primary.swipe-ltr::after {
  z-index: 0;
  transform: translateX(100%);
  transition: none;
  transition: transform 350ms ease;
}
.wolf-btn.primary.swipe-rtl:hover {
  color: #ffffff;
}
.wolf-btn.primary.swipe-rtl:hover::after {
  transform: translateX(0);
  transition: transform 350ms ease;
}
.wolf-btn.primary.swipe-rtl:hover::before {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
.wolf-btn.primary.swipe-rtl::before, .wolf-btn.primary.swipe-rtl::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: #7f7873;
  z-index: -1;
}
.wolf-btn.primary.swipe-rtl::after {
  transform: translateX(100%);
  z-index: 1;
}
.wolf-btn.primary.swipe-rtl::before {
  z-index: 0;
  transform: translateX(-100%);
  transition: none;
  transition: transform 350ms ease;
}
.wolf-btn.primary.active {
  background-color: #7f7873;
  color: #ffffff;
}
.wolf-btn.primary.hover:hover {
  color: #ffffff;
  background-color: #7f7873;
}
.wolf-btn.clear {
  color: var(--gray);
  border-color: var(--gray);
  /* swipe left */
  /* swipe right */
  /* swipe left to right */
  /* swipe right to left */
  /* active */
  /* hover */
}
.wolf-btn.clear.swipe-left:hover {
  color: #ffffff;
}
.wolf-btn.clear.swipe-left:hover::before {
  transform: translateX(-50%);
  background-color: var(--gray);
}
.wolf-btn.clear.swipe-left::before {
  content: "";
  transform: translateX(-101%);
  position: absolute;
  left: 0px;
  top: -5px;
  height: 140%;
  width: 240%;
  transition: all 0.5s ease-in-out 0s;
}
.wolf-btn.clear.swipe-right:hover {
  color: #ffffff;
}
.wolf-btn.clear.swipe-right:hover::before {
  transform: translateX(0%);
  background-color: var(--gray);
}
.wolf-btn.clear.swipe-right::before {
  content: "";
  transform: translateX(50%);
  position: absolute;
  left: 0px;
  top: 0px;
  height: 140%;
  width: 240%;
  transition: all 0.5s ease-in-out 0s;
}
.wolf-btn.clear.swipe-ltr:hover {
  color: #ffffff;
}
.wolf-btn.clear.swipe-ltr:hover::before {
  transform: translateX(0);
  transition: transform 350ms ease;
}
.wolf-btn.clear.swipe-ltr:hover::after {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
.wolf-btn.clear.swipe-ltr::before, .wolf-btn.clear.swipe-ltr::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--gray);
  z-index: -1;
}
.wolf-btn.clear.swipe-ltr::before {
  transform: translateX(-100%);
  z-index: 1;
}
.wolf-btn.clear.swipe-ltr::after {
  z-index: 0;
  transform: translateX(100%);
  transition: none;
  transition: transform 350ms ease;
}
.wolf-btn.clear.swipe-rtl:hover {
  color: #ffffff;
}
.wolf-btn.clear.swipe-rtl:hover::after {
  transform: translateX(0);
  transition: transform 350ms ease;
}
.wolf-btn.clear.swipe-rtl:hover::before {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
.wolf-btn.clear.swipe-rtl::before, .wolf-btn.clear.swipe-rtl::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--gray);
  z-index: -1;
}
.wolf-btn.clear.swipe-rtl::after {
  transform: translateX(100%);
  z-index: 1;
}
.wolf-btn.clear.swipe-rtl::before {
  z-index: 0;
  transform: translateX(-100%);
  transition: none;
  transition: transform 350ms ease;
}
.wolf-btn.clear.active {
  background-color: var(--gray);
  color: #ffffff;
}
.wolf-btn.clear.hover:hover {
  color: #ffffff;
  background-color: var(--gray);
}
.wolf-btn.red {
  color: #981b1e;
  border-color: #981b1e;
  /* swipe left */
  /* swipe right */
  /* swipe left to right */
  /* swipe right to left */
  /* active */
  /* hover */
}
.wolf-btn.red.swipe-left:hover {
  color: #ffffff;
}
.wolf-btn.red.swipe-left:hover::before {
  transform: translateX(-50%);
  background-color: #981b1e;
}
.wolf-btn.red.swipe-left::before {
  content: "";
  transform: translateX(-101%);
  position: absolute;
  left: 0px;
  top: -5px;
  height: 140%;
  width: 240%;
  transition: all 0.5s ease-in-out 0s;
}
.wolf-btn.red.swipe-right:hover {
  color: #ffffff;
}
.wolf-btn.red.swipe-right:hover::before {
  transform: translateX(0%);
  background-color: #981b1e;
}
.wolf-btn.red.swipe-right::before {
  content: "";
  transform: translateX(50%);
  position: absolute;
  left: 0px;
  top: 0px;
  height: 140%;
  width: 240%;
  transition: all 0.5s ease-in-out 0s;
}
.wolf-btn.red.swipe-ltr:hover {
  color: #ffffff;
}
.wolf-btn.red.swipe-ltr:hover::before {
  transform: translateX(0);
  transition: transform 350ms ease;
}
.wolf-btn.red.swipe-ltr:hover::after {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
.wolf-btn.red.swipe-ltr::before, .wolf-btn.red.swipe-ltr::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: #981b1e;
  z-index: -1;
}
.wolf-btn.red.swipe-ltr::before {
  transform: translateX(-100%);
  z-index: 1;
}
.wolf-btn.red.swipe-ltr::after {
  z-index: 0;
  transform: translateX(100%);
  transition: none;
  transition: transform 350ms ease;
}
.wolf-btn.red.swipe-rtl:hover {
  color: #ffffff;
}
.wolf-btn.red.swipe-rtl:hover::after {
  transform: translateX(0);
  transition: transform 350ms ease;
}
.wolf-btn.red.swipe-rtl:hover::before {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
.wolf-btn.red.swipe-rtl::before, .wolf-btn.red.swipe-rtl::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: #981b1e;
  z-index: -1;
}
.wolf-btn.red.swipe-rtl::after {
  transform: translateX(100%);
  z-index: 1;
}
.wolf-btn.red.swipe-rtl::before {
  z-index: 0;
  transform: translateX(-100%);
  transition: none;
  transition: transform 350ms ease;
}
.wolf-btn.red.active {
  background-color: #981b1e;
  color: #ffffff;
}
.wolf-btn.red.hover:hover {
  color: #ffffff;
  background-color: #981b1e;
}
.wolf-btn.btn-tan {
  background-color: var(--background-gray);
  border-color: var(--background-gray);
  color: var(--text-secondary);
  overflow: hidden;
  transition: all 0.3s ease;
  border-width: 0;
  /* swipe right to left */
}
.wolf-btn.btn-tan span {
  z-index: 2;
  position: relative;
}
.wolf-btn.btn-tan.active {
  background-color: var(--white);
  color: var(--black);
}
.wolf-btn.btn-tan.active:hover {
  color: var(--white);
  background-color: var(--background-gray);
}
.wolf-btn.btn-tan.swipe-rtl:hover {
  border-color: var(--background-gray);
  color: var(--black);
}
.wolf-btn.btn-tan.swipe-rtl:hover::after {
  transform: translateX(0);
  transition: transform 350ms ease;
}
.wolf-btn.btn-tan.swipe-rtl:hover::before {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
.wolf-btn.btn-tan.swipe-rtl::before, .wolf-btn.btn-tan.swipe-rtl::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--white);
  z-index: -1;
}
.wolf-btn.btn-tan.swipe-rtl::after {
  transform: translateX(100%);
  z-index: 1;
}
.wolf-btn.btn-tan.swipe-rtl::before {
  z-index: 0;
  transform: translateX(-100%);
  transition: none;
  transition: transform 350ms ease;
}

.card {
  background-color: var(--text-secondary) !important;
  border: 1px solid var(--border-color-gray) !important;
  border-radius: 0 !important;
}
.card .card-header {
  padding: 11px 15px 11px;
  border: 1px solid var(--input-border-color);
  border-radius: 0 !important;
}

.card-bg {
  padding-block-start: 13px !important;
  padding-block-end: 14px !important;
  padding-inline: 15px;
}

form-select .form-control,
form-input .form-control {
  color: var(--input-border-color);
  background-color: var(--text-secondary);
  width: 100%;
  height: calc(var(--grid-breakpoint) * 3);
  border: 1px solid var(--input-border-color);
  border-radius: 0px;
  font-size: var(--font-size-base);
  margin: 0 auto;
  /**
   * @note: This was in `form-select` but we will move it here to keep font size consistent across form-input and form-select
   */
}
form-select .form-control:focus,
form-input .form-control:focus {
  color: var(--input-border-color);
}
@media (max-width: 576px) {
  form-select .form-control,
  form-input .form-control {
    font-size: 10px !important;
  }
}
form-select .form-label,
form-input .form-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
  * Set the max-width of the form-select and form-input based on the control-widths.
  */
form-select.from-control-w-90,
form-input.from-control-w-90 {
  max-width: 90px;
}
form-select.from-control-w-90 .form-control,
form-input.from-control-w-90 .form-control {
  max-width: 90px;
}
form-select.from-control-w-90 .form-label,
form-input.from-control-w-90 .form-label {
  width: 90px;
}

form-select.from-control-w-105,
form-input.from-control-w-105 {
  max-width: 105px;
}
form-select.from-control-w-105 .form-control,
form-input.from-control-w-105 .form-control {
  max-width: 105px;
}
form-select.from-control-w-105 .form-label,
form-input.from-control-w-105 .form-label {
  width: 105px;
}

form-select.from-control-w-120,
form-input.from-control-w-120 {
  max-width: 120px;
}
form-select.from-control-w-120 .form-control,
form-input.from-control-w-120 .form-control {
  max-width: 120px;
}
form-select.from-control-w-120 .form-label,
form-input.from-control-w-120 .form-label {
  width: 120px;
}

form-select.from-control-w-195,
form-input.from-control-w-195 {
  max-width: 195px;
}
form-select.from-control-w-195 .form-control,
form-input.from-control-w-195 .form-control {
  max-width: 195px;
}
form-select.from-control-w-195 .form-label,
form-input.from-control-w-195 .form-label {
  width: 195px;
}

form-select.from-control-w-225,
form-input.from-control-w-225 {
  max-width: 225px;
}
form-select.from-control-w-225 .form-control,
form-input.from-control-w-225 .form-control {
  max-width: 225px;
}
form-select.from-control-w-225 .form-label,
form-input.from-control-w-225 .form-label {
  width: 225px;
}

form-select.from-control-w-330,
form-input.from-control-w-330 {
  max-width: 330px;
}
form-select.from-control-w-330 .form-control,
form-input.from-control-w-330 .form-control {
  max-width: 330px;
}
form-select.from-control-w-330 .form-label,
form-input.from-control-w-330 .form-label {
  width: 330px;
}

form-select.from-control-w-360,
form-input.from-control-w-360 {
  max-width: 360px;
}
form-select.from-control-w-360 .form-control,
form-input.from-control-w-360 .form-control {
  max-width: 360px;
}
form-select.from-control-w-360 .form-label,
form-input.from-control-w-360 .form-label {
  width: 360px;
}

form-select.from-control-w-405,
form-input.from-control-w-405 {
  max-width: 405px;
}
form-select.from-control-w-405 .form-control,
form-input.from-control-w-405 .form-control {
  max-width: 405px;
}
form-select.from-control-w-405 .form-label,
form-input.from-control-w-405 .form-label {
  width: 405px;
}

form-select.from-control-w-435,
form-input.from-control-w-435 {
  max-width: 435px;
}
form-select.from-control-w-435 .form-control,
form-input.from-control-w-435 .form-control {
  max-width: 435px;
}
form-select.from-control-w-435 .form-label,
form-input.from-control-w-435 .form-label {
  width: 435px;
}

form-select.from-control-w-465,
form-input.from-control-w-465 {
  max-width: 465px;
}
form-select.from-control-w-465 .form-control,
form-input.from-control-w-465 .form-control {
  max-width: 465px;
}
form-select.from-control-w-465 .form-label,
form-input.from-control-w-465 .form-label {
  width: 465px;
}

form-select.from-control-w-495,
form-input.from-control-w-495 {
  max-width: 495px;
}
form-select.from-control-w-495 .form-control,
form-input.from-control-w-495 .form-control {
  max-width: 495px;
}
form-select.from-control-w-495 .form-label,
form-input.from-control-w-495 .form-label {
  width: 495px;
}

form-select.from-control-w-540,
form-input.from-control-w-540 {
  max-width: 540px;
}
form-select.from-control-w-540 .form-control,
form-input.from-control-w-540 .form-control {
  max-width: 540px;
}
form-select.from-control-w-540 .form-label,
form-input.from-control-w-540 .form-label {
  width: 540px;
}

form-select.from-control-w-570,
form-input.from-control-w-570 {
  max-width: 570px;
}
form-select.from-control-w-570 .form-control,
form-input.from-control-w-570 .form-control {
  max-width: 570px;
}
form-select.from-control-w-570 .form-label,
form-input.from-control-w-570 .form-label {
  width: 570px;
}

form-select.from-control-w-585,
form-input.from-control-w-585 {
  max-width: 585px;
}
form-select.from-control-w-585 .form-control,
form-input.from-control-w-585 .form-control {
  max-width: 585px;
}
form-select.from-control-w-585 .form-label,
form-input.from-control-w-585 .form-label {
  width: 585px;
}

form-select.from-control-w-600,
form-input.from-control-w-600 {
  max-width: 600px;
}
form-select.from-control-w-600 .form-control,
form-input.from-control-w-600 .form-control {
  max-width: 600px;
}
form-select.from-control-w-600 .form-label,
form-input.from-control-w-600 .form-label {
  width: 600px;
}

form-select.from-control-w-660,
form-input.from-control-w-660 {
  max-width: 660px;
}
form-select.from-control-w-660 .form-control,
form-input.from-control-w-660 .form-control {
  max-width: 660px;
}
form-select.from-control-w-660 .form-label,
form-input.from-control-w-660 .form-label {
  width: 660px;
}

form-select.from-control-w-675,
form-input.from-control-w-675 {
  max-width: 675px;
}
form-select.from-control-w-675 .form-control,
form-input.from-control-w-675 .form-control {
  max-width: 675px;
}
form-select.from-control-w-675 .form-label,
form-input.from-control-w-675 .form-label {
  width: 675px;
}

form-select.from-control-w-690,
form-input.from-control-w-690 {
  max-width: 690px;
}
form-select.from-control-w-690 .form-control,
form-input.from-control-w-690 .form-control {
  max-width: 690px;
}
form-select.from-control-w-690 .form-label,
form-input.from-control-w-690 .form-label {
  width: 690px;
}

form-select.from-control-w-705,
form-input.from-control-w-705 {
  max-width: 705px;
}
form-select.from-control-w-705 .form-control,
form-input.from-control-w-705 .form-control {
  max-width: 705px;
}
form-select.from-control-w-705 .form-label,
form-input.from-control-w-705 .form-label {
  width: 705px;
}

form-select.from-control-w-1170,
form-input.from-control-w-1170 {
  max-width: 1170px;
}
form-select.from-control-w-1170 .form-control,
form-input.from-control-w-1170 .form-control {
  max-width: 1170px;
}
form-select.from-control-w-1170 .form-label,
form-input.from-control-w-1170 .form-label {
  width: 1170px;
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1065;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #ffffff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: rgba(0, 0, 0, 0.2);
  --bs-offcanvas-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
}

@media (max-width: 767.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 480px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas-xxl {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
.offcanvas-xxl.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas-xxl.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas-xxl.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas-xxl.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
  transform: none;
}
.offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
  visibility: visible;
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.px-4 {
  padding-inline: 60px !important;
}
@media (max-width: 1280.5px) {
  .px-4 {
    padding-inline: 30px !important;
  }
}
@media (max-width: 575.5px) {
  .px-4 {
    padding-inline: 15px !important;
  }
}

.py-3 {
  padding-block: 30px !important;
}

app-table.table,
table {
  width: 100%;
  margin-block-end: 0px;
  margin-bottom: 0;
}
app-table.table thead tr > th.tcol-xl,
app-table.table tbody tr > td.tcol-xl,
table thead tr > th.tcol-xl,
table tbody tr > td.tcol-xl {
  width: 1125px;
}

.table-responsive {
  border: 1px solid var(--td-btn-background);
  max-height: 840px;
}
.table-responsive .table {
  border-collapse: collapse;
}
@media (min-width: 1920.5px) {
  .table-responsive .table {
    width: 100%;
  }
}
@media (max-width: 1619.5px) {
  .table-responsive .table {
    width: 1440px;
  }
}

ngb-modal-backdrop.modal-backdrop {
  z-index: 1049 !important;
}

ngb-popover-window .popover-header {
  background-color: var(--input-color-text);
  color: var(--white);
  border: 1px solid var(--input-color-text);
}
ngb-popover-window .popover-body {
  background-color: var(--black);
  color: var(--white);
}

ngb-toast {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1200;
  width: 100%;
  min-width: 300px;
}

.toast {
  background-color: var(--white);
}
.toast .toast-header .btn-close {
  display: none;
}
.toast .toast-body {
  background-color: var(--white);
}
.toast .close {
  position: absolute;
  top: 2px;
  right: 10px;
}
.toast .toast-content {
  width: 100%;
  min-width: 300px;
}

/**
 * A mixin to style the image picker
*/
ngp-image-picker .place-image .image-holder {
  border: 1px solid currentColor !important;
  background: repeating-conic-gradient(var(--light) 0% 25%, transparent 0% 50%) 50%/25px 25px !important;
  background-position: 0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px; /* Must be half of one side of the square */
}

ngp-image-picker .place-image .image-holder-loaded img {
  border: 1px solid currentColor !important;
  background: repeating-conic-gradient(var(--light) 0% 25%, transparent 0% 50%) 50%/25px 25px !important;
  background-position: 0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px; /* Must be half of one side of the square */
  padding: 3px;
}