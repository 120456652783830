@import '../../mixin';

@mixin form-control-base() {
  color: var(--input-border-color);
  background-color: var(--text-secondary);
  width: 100%;
  height: calc(var(--grid-breakpoint) * 3);
  border: 1px solid var(--input-border-color);
  border-radius: 0px;
}

form-select,
form-input {
  // @include form-control-base;

  .form-control {
    @include form-control-base;
    font-size: var(--font-size-base);
    margin: 0 auto;

    &:focus {
      color: var(--input-border-color);
    }

    /**
     * @note: This was in `form-select` but we will move it here to keep font size consistent across form-input and form-select
     */
    @include max(576px) {
      font-size: 10px !important;
    }
  }

  .form-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// The control-widths for various areas of the application
$control-widths: 90, 105, 120, 195, 225, 330, 360, 405, 435, 465, 495, 540, 570, 585, 600, 660, 675, 690, 705, 1170;

/**
  * Set the max-width of the form-select and form-input based on the control-widths.
  */
@each $width in $control-widths {
  form-select,
  form-input {
    &.from-control-w-#{$width} {
      max-width: #{$width}px;

      .form-control {
        max-width: #{$width}px;
      }

      .form-label {
        width: #{$width}px; // For ellipsis
      }
    }
  }
}
