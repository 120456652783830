$primary: #7f7873 !default;
$success: #000000 !default;
$info: #b3b3b3 !default;
$warning: #b87333 !default;
$text-color: #413e4a !default;
$white: #ffffff !default;
$red: #981b1e !default;
$gray: #969696 !default;
$gray1: #959595;
$gray2: $info;
$gray3: #f2f2f2;
$tan1: $primary;
$tan2: #3f3b39;
$tan3: #f2eeeb;
$light: #f4f4f4;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'text-color': $text-color,
    'button-danger': $red,
    'label-color': $white,
    'label-background-color': $gray1,
    'text-primary': #1a1a1a,
    'text-secondary': $white,
    'text-tertiary': $gray3,
    'text-black': $success,
    'text-black-background-color': #191919,
    'small-text-color': #191919,
    'td-dark-bg': #ffffff,
    'select-background-color': $white,
    'text-neutral': $gray1,
    'text-disabled': #d3d3d3,
    'text-active': #e5e1cf,
    'text-hover': #787878,
    'text-menu-hover': $tan2,
    'button-color': $primary,
    'button-background-color': #333333,
    'form-color-border': #7c746a,
    'input-border-color': $gray1,
    'input-color-background': $white,
    'chrome-picker-background': $white,
    'black': $success,
    'input-color-text': $primary,
    'background-gray': $primary,
    'background-light-black': $tan2,
    'input-color-svg': $success,
    'border-color-gray': $gray1,
    'light-background': $gray3,
    'input-img-background': $gray3,
    'td-btn-background': $gray1,
    'td-btn-disable-background': $gray1,
    'td-btn-icon': $white,
    'tr-active': #e5e4e3,
    'login-text-color': #00000080,
    'tan-color-two': $tan2,
    'tan-color-three': $tan3,
    'gray-color-two': $info,
    'btn-copper': $warning,
    'text-copper': $warning,
    'light-hover': $light,
  ),
  $theme-colors
);

// Grid breakpoints
$grid-breakpoint: 15px;

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
) !default;

// Fonts
$font-family-sans-serif: 'Gotham Book', 'Helvetica Neue', Helvetica, Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 1rem !default;

// Headings
$headings-font-family: 'Neutraface Text Book' !default;
$h1-font-size: 3.5rem !default;
$h2-font-size: $font-size-base * 2 !default;

// Buttons
$input-btn-font-family: $font-family-sans-serif !default;
$input-btn-focus-box-shadow: none !default;
$input-btn-border-width: 3px !default;
$btn-box-shadow: none !default;
$btn-border-radius: 0 !default;
$btn-border-radius-lg: 0 !default;
$btn-border-radius-sm: 0 !default;

// Forms
$input-border-color: black !default;
$input-border-width: 1px !default;
$input-box-shadow: none !default;
$input-border-radius: 0 !default;
$input-border-radius-lg: 0 !default;
$input-border-radius-sm: 0 !default;
$input-color: $text-color !default;
$input-font-family: 'Neutraface Text Book', sans-serif !default;

:root {
  --container-max-width: 1170px;
  --grid-breakpoint: #{$grid-breakpoint};

  // Navbar & Sidebar
  --navbar-height: 144px;
  --sidebar-width: 360px;
  --sidebar-arrow-width: 60px;
  --btn-grid-breakpoint: 15px;

  //Font-Size
  --font-size-sm: 12px;
  --font-size-base: 15px;
  --font-size-lg: 20px;
  --font-size-xl: 25px;
}
